import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IPlayersState, IPlayer, emptyPlayer, IPlayerRecord, actionCreators as playerActionCreators } from '../../store/player';
import { IAuthState } from '../../store/auth';
import { ILibraryRole, ILibraryTag } from '../../store/library';
import { bindActionCreators } from "redux";
import PlayerEdit from '../player/fnPlayerEdit';
import { ISelection } from '../../store/selection';
import { IResult } from '../../store/result';
import { IMatchesState } from '../../store/match';
import MatchWidget from '../widgets/fnMatchWidget';
import { IMatchWidgetMatch } from '../widgets/fnMatchRow';
import RecordBrowserSimple from '../controls/navigation/fnRecordBrowserSimple';

interface IPlayerDetailsProps {
	auth: IAuthState;
	isTeamCaptain: boolean;
	playerStore: IPlayersState;
	roles: ILibraryRole[];
	tags: ILibraryTag[];
	matches: IMatchesState;
	selection: ISelection[];
	results: IResult[];
	actions: IPlayerActions;
}

interface IPlayerActions {
	player: typeof playerActionCreators;
}

type TPlayerDetailsProps = IPlayerDetailsProps & RouteComponentProps<{ playerId: any }>; // ... plus incoming routing parameters

const PlayerDetails = (props: TPlayerDetailsProps) => {
	// initialise internal state
	let playerIdParam: number = isNaN(Number(props.match.params.playerId)) ? 0 : Number(props.match.params.playerId);
	let player: IPlayer = emptyPlayer;
	let playerIndex = props.playerStore.data.players.findIndex((pl: IPlayer) => { return pl.id === playerIdParam; });
	if (playerIndex >= 0) {
		player = props.playerStore.data.players[playerIndex];		
	}
	let incomingState: any = props.location.state;
	let canBrowse: boolean = typeof incomingState !== "undefined" && typeof incomingState.ids !== "undefined";

	const onPlayerSaved = (inPlayer: IPlayerRecord) => {
		props.actions.player.playerDetailsLoaded(inPlayer);
	};

	const matchesList = (): IMatchWidgetMatch[] => {
		var widgetData: IMatchWidgetMatch[] = [];
		props.selection.filter((se) => {
			return se.playerId === playerIdParam && se.locked;
		}).forEach((se) => {
			let teamPrefix: string = "";
			let matchIndex = props.matches.data.matches.findIndex((ma) => { return ma.id === se.matchId; });
			if (matchIndex >= 0) {
				let match = props.matches.data.matches[matchIndex];
				let team = props.matches.data.teams.find((te) => { return te.id === match.teamId; });
				let isMixed: boolean = false;
				if (team)	{
					let competitionId = team.competitionId;
					let competitionIndex = props.matches.data.competitions.findIndex((cp) => { return cp.id === competitionId; });
					if (competitionIndex >= 0) {
						let competition = props.matches.data.competitions[competitionIndex];
						isMixed = competition.sex === "X";
						let leagueIndex = props.matches.data.leagues.findIndex((lg) => { return lg.id === competition.leagueId; });
						if (leagueIndex >= 0) {
							let league = props.matches.data.leagues[leagueIndex];
							if (league.prefix !== "") {
								teamPrefix = league.prefix + " ";
							}
						}
					}
				}
				let rubbersFor: number = 0;
				let rubbersAgainst: number = 0;
				let resultIndex = props.results.findIndex((rs) => { return rs.matchId === se.matchId && rs.playerId === se.playerId; });
				if (resultIndex >= 0) {
					let result: IResult = props.results[resultIndex];
					rubbersFor = result.rubbersFor;
					rubbersAgainst = result.rubbersAgainst;
				}
				if (team) {
					widgetData.push({
						id: match.id,
						date: match.date,
						team: teamPrefix + team.name,
						isMixed: isMixed,
						venue: match.venue,
						opposition: match.opposition,
						rubbersFor: rubbersFor,
						rubbersAgainst: rubbersAgainst
					});
				}
			}
		});
		return widgetData;
	};

	const onRecordChange = (id: number) => {
		// replace history entry - will trigger a change of props and refresh the page for the new match id
		props.history.replace("/player/details/" + id.toString(), props.location.state);
	};

	let call;
	let text;
	let email;
	if (player.mobile !== "") {
		call = <a href={"tel:" + player.mobile} style={{ marginLeft: 15, marginRight: 15 }}><i className="fa fa-phone" style={{ fontSize: "40px", color: "navy" }} /></a>;
		text = <a href={"sms:" + player.mobile} style={{ marginLeft: 15, marginRight: 15 }}><i className="fa fa-comment" style={{ fontSize: "40px", color: "green" }} /></a>;
	}
	if (player.email !== "") {
		email = <a href={"mailto:" + player.email} style={{ marginLeft: 15, marginRight: 15 }}><i className="fa fa-envelope" style={{ fontSize: "40px", color: "gold" }} /></a>;
	}

	let btm;
	if (player.ltaNumber !== "") {
		btm = "BTM: " + player.ltaNumber;
		// if (player.itfSinglesRating !== "") {
		// 	btm = btm + " (" + player.itfSinglesRating + ")";
		// }
		if (player.profileRef.length === 36) {
			btm = <p><a href={"https://competitions.lta.org.uk/player-profile/" + player.profileRef} target="_blank" rel="noopener noreferrer">{btm}</a></p>
		} else {
			btm = <p>{btm}</p>;
		}
	}

	let browser;
	if (canBrowse) {
		browser = <RecordBrowserSimple id={player.id} ids={incomingState.ids} onRecordChange={onRecordChange} />;
	}

	let playerEditButton;
	if (props.isTeamCaptain) {
		playerEditButton = (
			<PlayerEdit
				title="Player Edit"
				playerId={playerIdParam}
				clubId={props.playerStore.myClubId}
				auth={props.auth}
				roles={props.roles}
				tags={props.tags}
				onPlayerSaved={onPlayerSaved}
				online={props.auth.deviceOnLine}
			/>
		);
	}

	return (
		<div>
			<div className="container body-content">
				<div className="mb-3">
					{browser}
					<div className="text-center">
						<h4>{player.firstName + " " + player.surname}</h4>
						<h5>{btm}</h5>
						<div className="mb-2">
							{call}
							{text}
							{email}
						</div>
						{playerEditButton}
					</div>
				</div>
				<MatchWidget
					title={"Matches"}
					controlName="wgtPlayerMatches"
					matches={matchesList()}
					openByDefault={true}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	isTeamCaptain: state.players.isTeamCaptain,
	roles: state.library.data.roles,
	tags: state.library.data.tags,
	playerStore: state.players,
	matches: state.matches,
	selection: state.selection.data.selection,
	results: state.result.data.results
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: {
			player: bindActionCreators(playerActionCreators, dispatch)
		}
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(PlayerDetails) as any;