import React from 'react';
import { Button, Alert } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IMatchData, ICompetition, ILeague, ITeam, IMatch, actionCreators as matchActionCreators,
	emptyMatch, emptyTeam, emptyCompetition, emptyLeague } from '../../store/match';
import { ISelection, actionCreators as selectionActionCreators } from '../../store/selection';
import { IResult, IRubber, actionCreators as resultsActionCreators } from '../../store/result';
import { IPlayer, emptyPlayer } from '../../store/player';
import { IAuthState } from '../../store/auth';
import { bindActionCreators } from "redux";
import MatchEdit from '../match/fnMatchEdit';
import Loading from '../controls/spinners/fnLoading';
import { ELoadingSize } from '../controls/spinners/fnLoading';
import WrapperWidget from '../widgets/fnWrapperWidget';
import { IWrapperWidgetBadge } from '../widgets/fnWrapperWidgetBadge';
import RecordBrowserSimple from '../controls/navigation/fnRecordBrowserSimple';
import { IApiResult, EApiResultType } from '../api';
import { EDateFormat, formatDateString } from '../../utils/dates';
import { AppConfig, IAppConfig } from '../../config/appConfig';
import Captain from '../player/fnCaptain';
import { IMatchPlayer } from './fnMatchPlayerRow';
import MatchPlayerTable from './fnMatchPlayerTable';
import { IMatchRubberResult } from './fnMatchResultRow';
import MatchResultTable from './fnMatchResultTable';
let config: IAppConfig = AppConfig();

interface IMatchDetailsProps {
	auth: IAuthState;
	myPlayerId: number;
	isTeamCaptain: boolean;
	matches: IMatchData;
	selection: ISelection[];
	players: IPlayer[];
	rubbers: IRubber[];
	actions: IMatchActions;
}

interface IMatchActions {
	match: typeof matchActionCreators;
	results: typeof resultsActionCreators;
	selection: typeof selectionActionCreators;
}

interface IMatchResult {
	result: IApiResult;
	match: IMatch;
	rubbers: IRubber[];
	results: IResult[];
	selection: ISelection[];
}

type TMatchDetailsProps = IMatchDetailsProps & RouteComponentProps<{ matchId: any }>; // ... plus incoming routing parameters

const MatchDetails = (props: TMatchDetailsProps) => {
	const [fetchingResult, setFetchingResult] = React.useState(false);
	const [fetchingError, setFetchingError] = React.useState("");
	const currentId = React.useRef(0);

	// initialise internal state varibles
	let matchIdParam: number = isNaN(Number(props.match.params.matchId)) ? 0 : Number(props.match.params.matchId);
	let match: IMatch = emptyMatch;
	let captain: IPlayer = emptyPlayer;
	let team: ITeam = emptyTeam;
	let competition: ICompetition = emptyCompetition;
	let league: ILeague = emptyLeague;
	let players: IMatchPlayer[] = [];
	let rubbers: IMatchRubberResult[] = [];
	let incomingState: any = props.location.state;
	let canBrowse: boolean = typeof incomingState !== "undefined" && typeof incomingState.ids !== "undefined";
	if (matchIdParam !== currentId.current) {
		currentId.current = matchIdParam;
		if (fetchingError !== "") {
			setFetchingError("");
		}
		if (fetchingResult) {
			setFetchingResult(false);
		}
	}

	// extract match main details
	let matchIndex = props.matches.matches.findIndex((ma: IMatch) => { return ma.id === matchIdParam; });
	if (matchIndex >= 0) {
		match = props.matches.matches[matchIndex];
		let teamIndex = props.matches.teams.findIndex((tm) => { return tm.id === match.teamId; });
		if (teamIndex >= 0) {
			team = props.matches.teams[teamIndex];
			if (team.captainId > 0) {
				let captains: IPlayer[] = props.players.filter((pl) => { return pl.id === team.captainId; });
				if (captains.length === 1) {
					captain = captains[0];
				}
			}
		}
		if (team.id > 0) {
			let competitionIndex = props.matches.competitions.findIndex((cp: ICompetition) => { return cp.id === team.competitionId; });
			if (competitionIndex >= 0) {
				competition = props.matches.competitions[competitionIndex];
				let leagueIndex = props.matches.leagues.findIndex((lg: ILeague) => { return lg.id === competition.leagueId; });
				if (leagueIndex >= 0) {
					league = props.matches.leagues[leagueIndex];
				}
			}
		}
	}

	// extract players
	props.selection.filter((selection: ISelection) => {
		return selection.matchId === matchIdParam && selection.locked;
	}).forEach((selection: ISelection) => {
		let player = props.players.find((pl) => { return pl.id === selection.playerId; });
		if (player) {
			players.push({
				id: player.id,
				name: player.firstName + " " + player.surname,
				position: selection.index,
				mobile: player.mobile,
				email: player.email,
				profileRef: player.profileRef,
				confirmed: selection.confirmed
			});
		}
		});
	players = players.sort((a: IMatchPlayer, b: IMatchPlayer) => {
		return a.position - b.position;
	});

	// extract rubbers
	props.rubbers.filter(rb => { return rb.matchId === matchIdParam; }).forEach((rubber: IRubber) => {
		let player1: IPlayer = emptyPlayer;
		if (rubber.player1 > 0) {
			let player1Index: number = props.players.findIndex(pl => { return pl.id === rubber.player1; });
			if (player1Index >= 0) {
				player1 = props.players[player1Index];
			}
		}
		let player2: IPlayer = emptyPlayer;
		if (rubber.player2 > 0) {
			let player2Index: number = props.players.findIndex(pl => { return pl.id === rubber.player2; });
			if (player2Index >= 0) {
				player2 = props.players[player2Index];
			}
		}
		let home1: string = (player1.firstName + ' ' + player1.surname).trim();
		let home2: string = (player2.firstName + ' ' + player2.surname).trim();
		let oppo1: string = rubber.oppo1;
		let oppo2: string = rubber.oppo2;
		if (home1 === "") {
			home1 = "no player";
		}
		if (oppo1 === "") {
			oppo1 = "no player";
		}
		if (!rubber.singles) {
			if (home2 === "") {
				home2 = "no player";
			}
			if (oppo2 === "") {
				oppo2 = "no player";
			}
		}
		let score: string = (rubber.set1 + ' ' + rubber.set2 + ' ' + rubber.set3).trim();
		if (match.venue === "A") {
			rubbers.push({
				rubber: rubber.rubber,
				singles: rubber.singles,
				home1: oppo1,
				home2: oppo2,
				away1: home1,
				away2: home2,
				score: score
			});
		} else {
			rubbers.push({
				rubber: rubber.rubber,
				singles: rubber.singles,
				home1: home1,
				home2: home2,
				away1: oppo1,
				away2: oppo2,
				score: score
			});
		}
	});

	const onMatchSaved = (inMatch: IMatch) => {
		props.actions.match.matchSet(inMatch);
	};

	const onFetchResult = () => {
		if (props.auth.user && match.id > 0) {
			fetch(`${config.apiUrl}/match/result?matchId=${match.id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + props.auth.user.accessToken,
					"Pragma": "no-cache"
				},
			})
			.then(response => response.json() as Promise<IMatchResult>)
			.then(onResultFetched)
			.catch((error) => { 
				setFetchingError(error.message);
				setFetchingResult(false);
			});
			setFetchingResult(true);
		}
	};

	const onResultFetched = (result: IMatchResult) => {
		setFetchingError(result.result.errorMessage);
		setFetchingResult(false);
		if (result.result.resultType === EApiResultType.OK) {
			props.actions.match.matchSet(result.match);
			props.actions.selection.playerMatchSelectionSet(result.match.id, result.selection);
			props.actions.results.playerMatchResultSet(result.match.id, result.results);
			props.actions.results.matchRubberResultSet(result.match.id, result.rubbers);
		}
	};

	const onRequestTeamSheet = () => {
		if (props.auth.user && match.id > 0) {
			fetch(`${config.apiUrl}/match/teamsheet?matchId=${match.id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + props.auth.user.accessToken,
					"Pragma": "no-cache"
				},
			})
			.then(response => response.blob())
			.then(blob => {
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = url;
					a.download = "teamsheet.docx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();    
					a.remove();  //afterwards we remove the element again         
			});
		}
	};

	const onConfirmSelection = (selection: ISelection) => {
		if (props.auth.user) {
			props.actions.selection.setSelection(props.auth.user.accessToken, selection);
		}
	};

	const onRecordChange = (id: number) => {
		// replace history entry - will trigger a change of props and refresh the page for the new match id
		props.history.replace("/match/details/" + id.toString(), props.location.state);
	};

	const onPlayerDrillDown = (focusId: number) => {
		let playerIds: number[] = players.map((pl) => { return pl.id; });
		props.history.push("/player/details/" + focusId.toString(), { ids: playerIds });
	};

	let dateStr = formatDateString(match.date, EDateFormat.fullDayMonthWithTime24H);
	let dateLink;
	if (league.websiteRef !== "" && team.divisionWebsiteRef !== 0) {
		let matchUrl = "https://lta.tournamentsoftware.com/sport/teammatch.aspx?id=" + league.websiteRef + "&match=" + match.websiteRef;
		dateLink = <a href={matchUrl} target="_blank" rel="noopener noreferrer">{dateStr}</a>;
	} else {
		dateLink = dateStr;
	}

	let iconName: string = "fa fa-home";
	let venue: string = "home";
	if (match.venue === "A") {
		iconName = "fa fa-plane";
		venue = "away";
	}

	let fetchingErrorCtrl;
	if (fetchingError !== "") {
		fetchingErrorCtrl = (
			<Alert color="warning" isOpen={fetchingError !== ""} toggle={() => { setFetchingError(""); }}>
				<strong>{fetchingError}</strong>
			</Alert>
		);
	}

	let resultDisplay = "none";
	let resultBadges: IWrapperWidgetBadge[] = [];
	let resultsClass = "secondary";
	if (match.pointsFor || match.pointsAgainst) {
		resultDisplay = "block";
		resultBadges.push({ name: "R ", value: match.rubbersFor.toString() + " - " + match.rubbersAgainst.toString() });
		resultBadges.push({ name: " S ", value: match.setsFor.toString() + " - " + match.setsAgainst.toString() });
		resultBadges.push({ name: " G ", value: match.gamesFor.toString() + " - " + match.gamesAgainst.toString() });
		resultBadges.push({ name: " P ", value: match.pointsFor.toString() });
		if (match.pointsFor > match.pointsAgainst) {
			if (match.pointsAgainst === 0) {
				resultsClass = "success";
			} else {
				resultsClass = "warning";
			}
		} else if (match.pointsFor < match.pointsAgainst) {
			if (match.pointsFor === 0) {
				resultsClass = "danger";
			}
		}
	}

	let browser;
	if (canBrowse) {
		browser = <RecordBrowserSimple id={matchIdParam} ids={incomingState.ids} onRecordChange={onRecordChange} />;
	}

	let matchEditButton;
	if (props.isTeamCaptain) {
		matchEditButton = (
			<span className="ml-2 mr-2">
				<MatchEdit title="Edit Match" matchId={match.id} auth={props.auth} onMatchSaved={onMatchSaved} online={props.auth.deviceOnLine}/>
			</span>
		);
	}

	let matchResultButton;
	if (props.isTeamCaptain && !match.completed) {
		matchResultButton = (
			<Button className="ml-2 mr 2" color="secondary" onClick={onFetchResult} disabled={fetchingResult || !props.auth.deviceOnLine} >
				<i className="fa fa-download" /> Result
			</Button>
		);
	}

	let teamSheetButton;
	if (!match.completed) {
		teamSheetButton = (
			<Button color="secondary" onClick={onRequestTeamSheet} >
				<i className="fa fa-download" /> Team Sheet
			</Button>
		);
	}

	let matchNotes;
	if (match.notes !== "") {
		matchNotes = (
			<Alert color="warning">
				<strong><u>{"Match notes from your captain"}</u></strong><br />
				{match.notes.split('\n').map((item, key) => {
					return (<span key={key}>{item}<br/></span>);
				})}							
				<Captain captain={captain} marginTop={5} marginBottom={0} />
			</Alert>
		);
	} else if (captain.surname !== "") {
		matchNotes = (
			<Alert color="warning">
				<Captain captain={captain} marginTop={0} marginBottom={0} />
			</Alert>
		);
	}

	let teamCtrl;
	if (!match.completed) {
		teamCtrl = (
			<div>
				<WrapperWidget title="Team" controlName="wgtTeam" badges={[]} panelClass={"primary"} openByDefault={!match.completed}>
					<MatchPlayerTable players={players} captain={captain} location={match.location} onDrillDown={onPlayerDrillDown} />
				</WrapperWidget>
			</div>
		);
	}

	let myConfirmation;
	if (!match.completed) {
		let mySelection: ISelection = {
			matchId: 0,
			playerId: 0,
			index: 0,
			locked: false,
			confirmed: false,
			notes: ""
		};
		players.forEach((pl) => {
			if (pl.id === props.myPlayerId && !pl.confirmed) {
				mySelection = {
					matchId: matchIdParam,
					playerId: pl.id,
					index: pl.position,
					locked: true,
					confirmed: true,
					notes: ""
				};
			}
		});
		if (mySelection.confirmed) {
			myConfirmation = (
				<Alert color="danger">
					<strong><u>{"Please confirm your selection"}</u></strong><br />
					<div
						style={{
							float: "right"
						}}
					>
						<Button color="danger" onClick={() => onConfirmSelection(mySelection)}>
							<i className="fa fa-thumbs-o-up" /> Confirm
						</Button>
					</div>
					<p>
						{"Click this button to confirm that you are still available to play in this match. "
						+ "If you are no longer available you must contact the team captain ASAP."}
					</p>
				</Alert>
			);
		}
	}

	let mapButton;
	if (!match.completed && match.location !== "") {
		let mapLink: string = "http://maps.google.com/?q=" + match.location;
		mapButton = (
			<div
				style={{
					marginTop: 5,
					marginBottom: 10
				}}
			>
				<a
					href={mapLink}
					target="_blank"
					rel="noopener noreferrer"
					style={{
						cursor: "pointer",
						fontSize: 16,
					}}
				>
					<i className="fa fa-globe" /> {"Location"}
				</a>
			</div>
		);
	}

	let fetchingCtrl;
	if (fetchingResult) {
		fetchingCtrl = (
			<Loading message="Extracting result from LTA website" size={ELoadingSize.medium} delayed={true} delayInMs={300} />
		);
	}

	return (
		<div>
			<div className="container body-content">
				<div className="mb-3">
					{browser}
					<div className="text-center">
						<h4>
							{team.name}<br />
						</h4>
						<h5>
							{dateLink}
						</h5>
						<h6>
							<span className={iconName} />{" " + venue + " to"}<br />
						</h6>
						<h5>
							{match.opposition}
						</h5>
							{matchEditButton}
							{matchResultButton}
					</div>
				</div>

				{fetchingCtrl}

				{fetchingErrorCtrl}

				{myConfirmation}

				{teamCtrl}

				{mapButton}

				<div style={{ display: resultDisplay }}>
					<WrapperWidget title="Result" controlName="wgtReslt" badges={resultBadges} panelClass={resultsClass} openByDefault={true}>
						<MatchResultTable rubbers={rubbers} />
					</WrapperWidget>
				</div>

				{matchNotes}

				{teamSheetButton}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	myPlayerId: state.players.myPlayerId,
	isTeamCaptain: state.players.isTeamCaptain,
	matches: state.matches.data,
	selection: state.selection.data.selection,
	players: state.players.data.players,
	rubbers: state.result.data.rubbers
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: {
			match: bindActionCreators(matchActionCreators, dispatch),
			results: bindActionCreators(resultsActionCreators, dispatch),
			selection: bindActionCreators(selectionActionCreators, dispatch)
		}
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(MatchDetails) as any;
