import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IActivity, ICompetition, ITeam, IMatch, IActivityMatch, IActivityRecord,
	emptyCompetition, emptyActivity, actionCreators as matchActionCreators } from '../../store/match';
import { IAvailability, EAvailabilityStatus, actionCreators as availabilityActionCreators, IAvailabilityUpdate } from '../../store/availability';
import { ISelection, actionCreators as selectionActionCreators } from '../../store/selection';
import { IResult } from '../../store/result';
import { IPlayersState, IPlayer } from '../../store/player';
import { IAuthState } from '../../store/auth';
import { bindActionCreators } from "redux";
import { IMatchWidgetMatch } from '../widgets/fnMatchRow';
import NotifyWidget from '../widgets/fnNotifyWidget';
import { INotifyWidgetPlayer } from '../widgets/fnNotifyPlayerRow';
import AvailabilityWidget from '../widgets/fnAvailabilityWidget';
import { IAvailabilityWidgetPlayer } from '../widgets/fnAvailabilityPlayerRow';
import { IWrapperWidgetBadge } from '../widgets/fnWrapperWidgetBadge';
import { parseDateString, formatDateString, EDateFormat } from '../../utils/dates';
import RecordBrowserSimple from '../controls/navigation/fnRecordBrowserSimple';
import SelectionMatches from './fnSelectionMatches';
import WrapperWidget from '../widgets/fnWrapperWidget';
import ActivityEdit from './fnActivityEdit';

interface IActivityDetailsProps {
	auth: IAuthState;
	activities: IActivity[];
	matches: IMatch[];
	competitions: ICompetition[];
	teams: ITeam[];
	availability: IAvailability[];
	playerStore: IPlayersState;
	selection: ISelection[];
	results: IResult[];
	actions: IActivityActions;
	dispatch: any;
}

interface IActivityActions {
	availability: typeof availabilityActionCreators;
	selection: typeof selectionActionCreators;
	match: typeof matchActionCreators;
}

type TActivityDetailsProps = IActivityDetailsProps & RouteComponentProps<{ activityId: string }>; // ... plus incoming routing parameters

const ActivityDetails = (props: TActivityDetailsProps) => {
	// initialise internal state variables
	let activityIdParam: number = isNaN(Number(props.match.params.activityId)) ? 0 : Number(props.match.params.activityId);
	let activity: IActivity = emptyActivity;
	let competition: ICompetition = emptyCompetition;
	let sex = "X";
	let availability: IAvailability[] = [];
	let matches: IMatch[] = [];
	let selection: ISelection[] = [];
	let incomingState: any = props.location.state;
	let canBrowse: boolean = typeof incomingState !== "undefined" && typeof incomingState.ids !== "undefined";

	// set state variables from props
	let activityIndex = props.activities.findIndex((ac: IActivity) => { return ac.id === activityIdParam; });
	if (activityIndex >= 0) {
		activity = props.activities[activityIndex];
		let competitionIndex = props.competitions.findIndex((cp) => { return cp.id === activity.competitionId; });
		if (competitionIndex >= 0) {
			competition = props.competitions[competitionIndex];
			sex = competition.sex;
		}
		availability = props.availability.filter((av) => {
			return av.activityId === activityIdParam;
		});
		matches = props.matches.filter((ma) => {
			return ma.activityId === activityIdParam;
		}).sort((a: IMatch, b: IMatch) => {
			let teamAindex: number = 0;
			let teamAposn = props.teams.findIndex(ta => { return ta.id === a.teamId; });
			if (teamAposn >= 0) {
				teamAindex = props.teams[teamAposn].teamNo;
			}
			let teamBindex: number = 0;
			let teamBposn = props.teams.findIndex(tb => { return tb.id === b.teamId; });
			if (teamBposn >= 0) {
				teamBindex = props.teams[teamBposn].teamNo;
			}
			return teamAindex - teamBindex;
		});
		matches.forEach((ma) => {
			props.selection.filter((se) => { return se.matchId === ma.id; }).forEach((se) => { selection.push(se); });
		});
	}

	const matchesList = (): IMatchWidgetMatch[] => {
		var activityMatches: IMatch[] = [];
		props.teams.forEach((team) => {
			var index = matches.findIndex((match) => { return match.teamId === team.id; });
			if (index >= 0) {
				activityMatches.push(matches[index]);
			}
		});
		var widgetData: IMatchWidgetMatch[] = [];
		activityMatches.forEach((match) => {
			let team = props.teams.find((tm) => { return (tm.id === match.teamId); });
			let teamName = "";
			let isMixed: boolean = false;
			if (team) {
				teamName = team.name;
				let checkCompetition: ICompetition = emptyCompetition;				
				let competitionId = team.competitionId;
				let competitionIndex = props.competitions.findIndex((cp) => { return cp.id === competitionId; });
				if (competitionIndex >= 0) {
					checkCompetition = props.competitions[competitionIndex];
				}
				isMixed = checkCompetition.sex === "X";
			}
			var wdMatch: IMatchWidgetMatch = {
				id: match.id,
				date: match.date,
				team: teamName,
				isMixed: isMixed,
				venue: match.venue,
				opposition: match.opposition
			};
			widgetData.push(wdMatch);
		});
		widgetData.sort((a: IMatchWidgetMatch, b: IMatchWidgetMatch) => {
			let teamA = a.team;
			let teamB = b.team;
			if (teamA < teamB) {
				return -1;
			} else if (teamA > teamB) {
				return 1;
			} else {
				return 0;
			}
		});
		return widgetData;
	};

	const playersNotSent = (): INotifyWidgetPlayer[] => {
		var widgetData: INotifyWidgetPlayer[] = [];
		// get array of players already notified for this activity
		let playersNotified = availability.map((av) => { return av.playerId; });
		// get list of players not notified
		props.playerStore.data.players.forEach((player) => {
			if (!player.archived && (sex === "X" || player.sex === sex) && playersNotified.indexOf(player.id) < 0) {
				widgetData.push({ id: player.id, firstName: player.firstName, surname: player.surname, checked: false });
			}
		});
		return widgetData.sort((a: INotifyWidgetPlayer, b: INotifyWidgetPlayer) => {
			if (a.surname < b.surname) {
				return -1;
			} else if (a.surname > b.surname) {
				return 1;
			} else if (a.firstName < b.firstName) {
				return -1;
			} else if (a.firstName > b.firstName) {
				return 1;
			} else {
				return 0;
			}
		});
	};

	const availabilityCallbackNotify = (av: IAvailability[], email: boolean) => {
		av.forEach((item) => {
			if (props.auth.user) {
				let avu: IAvailabilityUpdate = { availability: item, sendEmail: email }
				props.actions.availability.setAvailability(props.auth.user.accessToken, avu);
			}
		});
	};

	const availabilityCallback = (av: IAvailability) => {
		if (props.auth.user) {
			let avu: IAvailabilityUpdate = { availability: av, sendEmail: false }
			props.actions.availability.setAvailability(props.auth.user.accessToken, avu);
		}
	};

	const selectionCallback = (remove: ISelection, add: ISelection) => {
		if (props.auth.user) {
			if (remove.playerId > 0) {
				props.actions.selection.clearSelection(props.auth.user.accessToken, remove);
			}
			if (add.playerId > 0) {
				props.actions.selection.setSelection(props.auth.user.accessToken, add);
			}
			let newSelection: ISelection[] = [];
			selection.forEach((se) => {
				if (se.matchId !== remove.matchId || se.playerId !== remove.playerId || se.index !== remove.index) {
					newSelection.push(se);
				}
			});
			if (add.playerId > 0) {
				newSelection.push(add);
			}
			//setState({ selection: selection });
			selection = newSelection;
		}
	};

	const teamTiedCheck = (player: IPlayer): number => {
		let tied: number = 0;
		let teamTiedMatches: number = competition.teamTied;
		if (teamTiedMatches > 0) {
			let matchesPlayed: number = 0;
			let playerTeams: number[] = [];
			props.results.filter((rs) => {
				return rs.playerId === player.id;
			}).forEach((rs) => {
				let matchIndex: number = props.matches.findIndex((m) => { return m.id === rs.matchId; });
				if (matchIndex >= 0) {
					let match: IMatch = props.matches[matchIndex];
					let teamIndex: number = props.teams.findIndex((t) => { return t.id === match.teamId; });
					if (teamIndex >= 0) {
						let team: ITeam = props.teams[teamIndex];
						if (team.competitionId === competition.id) {
							playerTeams.push(team.teamNo);
							matchesPlayed = matchesPlayed + 1;
						}
					}
				}
			});
			if (matchesPlayed >= teamTiedMatches) {
				playerTeams.sort((a: number, b: number) => {
					return a - b;
				});
				tied = playerTeams[teamTiedMatches - 1];
			}
		}
		return tied;
	};

	const playersList = (status: EAvailabilityStatus): IAvailabilityWidgetPlayer[] => {
		let players = availability.filter((av) => {
			return av.status === status;
		}).map((av) => {
			let player = props.playerStore.data.players.find((p) => { return p.id === av.playerId; });
			if (player) {
				let readOnly = (selection.findIndex((se) => { return se.playerId === av.playerId; })) >= 0;
				let minTeam: number = teamTiedCheck(player);
				return ({
					id: av.playerId,
					firstName: player.firstName,
					surname: player.surname, 
					sex: player.sex,
					status: av.status,
					readOnly: readOnly,
					registered: player.registered,
					email: player.email,
					mobile: player.mobile,
					minTeam: minTeam
				});
			} else {
				return ({
					id: av.playerId,
					firstName: "?",
					surname: "?",
					sex: "X",
					status: av.status,
					readOnly: true,
					registered: false,
					email: "",
					mobile: "",
					minTeam: 0
				});
			}
		});
		return players.sort((a: IAvailabilityWidgetPlayer, b: IAvailabilityWidgetPlayer) => {
			if (a.surname < b.surname) {
				return -1;
			} else if (a.surname > b.surname) {
				return 1;
			} else if (a.firstName < b.firstName) {
				return -1;
			} else if (a.firstName > b.firstName) {
				return 1;
			} else {
				return 0;
			}
		});
	};

	const matchListCallback = (competitionId: number, date: Date, selected: number[]): IActivityMatch[] => {
		let retMatches: IActivityMatch[] = [];
		if (props.matches) {
			props.matches.forEach((match) => {
				let matchDate = parseDateString(match.date);
				let alreadySelected: boolean = selected.indexOf(match.id) >= 0;
				if (alreadySelected || (date.getFullYear() === matchDate.getFullYear() && date.getMonth() === matchDate.getMonth() && date.getDate() === matchDate.getDate())) {
					var team = props.teams.find((tm) => { return tm.id === match.teamId; });
					if (team && (alreadySelected || team.competitionId === competitionId)) {
						let title = team.name + " (" + match.venue + ") vs " + match.opposition;
						if (date.getFullYear() === matchDate.getFullYear() && date.getMonth() === matchDate.getMonth() && date.getDate() === matchDate.getDate()) {
							title = title + " : " + formatDateString(match.date, EDateFormat.tim24H);
						} else {
							title = title + " : **" + formatDateString(match.date, EDateFormat.dayMonthWithTime24H) + "**";
						}
						let selectedPlayers = props.selection.filter((se) => { return se.matchId === match.id; }).length;
						retMatches.push({ id: match.id, title: title, date: match.date, selectedPlayers: selectedPlayers });
					}
				}
			});
		}
		return retMatches;
	};

	const onActivitySaved = (activityRecord: IActivityRecord) => {
		props.actions.match.activitySet(activityRecord);
	};

	const onDeleteActivity = () => {
		if (props.auth && props.auth.user) {
			props.actions.match.activityDelete(props.auth.user.accessToken, activityIdParam);
			//props.dispatch(push("/activity/list"));
			props.history.push("/activity/list");
		}
	};

	const onRecordChange = (id: number) => {
		// replace history entry - will trigger a change of props and refresh the page for the new match id
		props.history.replace("/activity/details/" + id.toString(), props.location.state);
	};

	let wgtAvailable = playersList(EAvailabilityStatus.Available);
	let wgtNotAvailable = playersList(EAvailabilityStatus.Unavailable);
	let wgtMaybe = playersList(EAvailabilityStatus.Maybe);
	let wgtPending = playersList(EAvailabilityStatus.Pending);
	let wgtNotSent = playersNotSent();
	let wgtMatches = matchesList();
	let playersNeeded = competition.teamSize * matches.length;
	var badges: IWrapperWidgetBadge[] = [
		{ name: "", value: wgtAvailable.length.toString() },
		{ name: " of ", value: playersNeeded.toString() },
		{ name: " ? ", value: (wgtPending.length + wgtMaybe.length).toString() },
		{ name: " TBS ", value: wgtNotSent.length.toString() },
	];

	let availabilityClass = "warning";
	if (playersNeeded > wgtAvailable.length) {
		availabilityClass = "danger";
	} else if (playersNeeded < wgtAvailable.length) {
		availabilityClass = "success";
	}

	// only show not sent widget if not all sent
	const notSentWidget = (
		wgtNotSent.length > 0 ? (
			<NotifyWidget
				title={"Not sent"}
				controlName="wgtNotSent"
				activityId={activity.id}
				players={wgtNotSent}
				callback={availabilityCallbackNotify}
			/>
		)	: null
	);

	// only show available widget when at least 1 player is available
	const availableWidget = (
		wgtAvailable.length > 0 ? (
			<AvailabilityWidget
				title={"Available"}
				controlName="wgtAvailable"
				activityId={activity.id}
				players={wgtAvailable}
				callback={availabilityCallback}
				singleCount={true}
				panelClass={"success"}
				glyph={"fa fa-check"}
			/>
		) : null
	);

	// only show unavailable widget when at least 1 player is unavailable
	const notAvailableWidget = (
		wgtNotAvailable.length > 0 ? (
			<AvailabilityWidget
				title={"Not Available"}
				controlName="wgtNotAvailable"
				activityId={activity.id}
				players={wgtNotAvailable}
				callback={availabilityCallback}
				singleCount={true}
				panelClass={"secondary"}
				glyph={"fa fa-remove"}
			/>
		) : null
	);

	// only show maybe widget when at least 1 player is maybe
	const maybeWidget = (
		wgtMaybe.length > 0 ? (
			<AvailabilityWidget
				title={"Maybe"}
				controlName="wgtMaybe"
				activityId={activity.id}
				players={wgtMaybe}
				callback={availabilityCallback}
				singleCount={true}
				panelClass={"warning"}
				glyph={"fa fa-pencil"}
			/>
		) : null
	);

	// only show no response widget when at least 1 player has not responded
	const noResponseWidget = (
		wgtPending.length > 0 ? (
			<AvailabilityWidget
				title={"No Response"}
				controlName="wgtNoResponse"
				activityId={activity.id}
				players={wgtPending}
				callback={availabilityCallback}
				singleCount={true}
				panelClass={"danger"}
				glyph={"fa fa-hourglass-o"}
			/>
		) : null
	);

	// only show matches if there are some (i.e. not team practice or proposed re-schedule)
	let matchesBlock;
	if (matches.length > 0) {
		matchesBlock = (
			<div className="col-sm-6">
				<SelectionMatches matches={wgtMatches} available={wgtAvailable} selection={selection} callback={selectionCallback} minPlayers={competition.teamSize} maxPlayers={competition.maxTeamSize} />
			</div>
		);
	}

	let deleteButton;
	if (matches.length === 0) {
		deleteButton = (
			<Button color="secondary" className="ml-2" onClick={onDeleteActivity}>
				<i className="fa fa-times" /> Delete
			</Button>
		);
	}

	let dateStr = formatDateString(activity.date, EDateFormat.fullDayMonthWithTime24H);

	let browser;
	if (canBrowse) {
		browser = <RecordBrowserSimple id={activity.id} ids={incomingState.ids} onRecordChange={onRecordChange} />;
	}

	return (
		<div className="container body-content">
			<div className="mb-3">
				{browser}
				<div className="text-center">
					<h4>{dateStr}</h4>
					<h5>
						{competition.name}<br />
						{activity.title}
					</h5>
					<h6>
						{activity.notes}
					</h6>
					<ActivityEdit title="Edit Activity" activityId={activity.id} auth={props.auth} clubId={props.playerStore.myClubId} competitions={props.competitions} matchListCallback={matchListCallback} onActivitySaved={onActivitySaved} online={props.auth.deviceOnLine} />
					{deleteButton}
				</div>
			</div>

			<div className="row">
				<div className="col-sm-6">
					<WrapperWidget title={"Availability"} controlName={"WgtAvailability"} badges={badges} panelClass={availabilityClass} openByDefault={true} glyph="fa fa-pencil">
						<div className="pl-2 pr-2">
							{notSentWidget}
							{availableWidget}
							{notAvailableWidget}
							{maybeWidget}
							{noResponseWidget}
						</div>
					</WrapperWidget>
				</div>

				{matchesBlock}

			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	activities: state.matches.data.activities,
	matches: state.matches.data.matches,
	competitions: state.matches.data.competitions,
	teams: state.matches.data.teams,
	availability: state.availability.data.availability,
	playerStore: state.players,
	selection: state.selection.data.selection,
	results: state.result.data.results
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: {
			availability: bindActionCreators(availabilityActionCreators, dispatch),
			selection: bindActionCreators(selectionActionCreators, dispatch),
			match: bindActionCreators(matchActionCreators, dispatch)
		},
		dispatch: dispatch
	};
}

export default withRouter(connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(ActivityDetails) as any);
