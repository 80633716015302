import * as React from 'react';
import NavMenu from './fnNavMenu';
import Offline from './fnOffline';
import ServiceWorkerWrapper from '../fnServiceWorkerWrapper';

interface ILayoutProps {
	children?: any;
}

const Layout = (props: ILayoutProps) => {
	return (
		<div>
			<ServiceWorkerWrapper />
			<NavMenu />
			<div>
				{props.children}
			</div>
			<Offline />
		</div>
	);
};

export default Layout;