import * as Auth from './auth';
import * as Library from './library';
import * as Match from './match';
import * as Player from './player';
import * as Availability from './availability';
import * as Selection from './selection';
import * as Result from './result';
import * as Storage from './storage';

// The top-level state object
export interface IApplicationState {
	auth: Auth.IAuthState;
	library: Library.ILibraryState;
	matches: Match.IMatchesState;
	players: Player.IPlayersState;
	availability: Availability.IAvailabilityState;
	selection: Selection.ISelectionState;
	result: Result.IResultsState;
	storage: Storage.IStorageState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	auth: Auth.reducer,
	library: Library.reducer,
	matches: Match.reducer,
	players: Player.reducer,
	availability: Availability.reducer,
	selection: Selection.reducer,
	result: Result.reducer,
	storage: Storage.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface IAppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => IApplicationState): void;
}