import React from 'react';
import NotifyPlayerRow, { INotifyWidgetPlayer } from './fnNotifyPlayerRow';

interface INotifyTableProps {
	activityId: number;
	players: INotifyWidgetPlayer[];
	callback: (playerId: number, checked: boolean) => void;
}

const NotifyPlayerTable = (props: INotifyTableProps) => {
	var rows: any[] = [];
	if (props.players && props.players.length > 0) {
		props.players.forEach((player) => {
			rows.push(
				<NotifyPlayerRow data={player} key={player.id} activityId={props.activityId} callback={props.callback} />
			);
		});
	}
	return (
		<table className="table table-striped table-sm">
			<tbody>{rows}</tbody>
		</table>
	);
};

export default NotifyPlayerTable;
