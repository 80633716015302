import React from 'react';
import { IMatchWidgetMatch } from '../widgets/fnMatchRow';
import { IAvailabilityWidgetPlayer } from '../widgets/fnAvailabilityPlayerRow';
import { ISelection } from '../../store/selection';
import { ISelectionWidgetPlayer } from '../widgets/fnSelectionPlayerRow';
import { IWrapperWidgetBadge } from '../widgets/fnWrapperWidgetBadge';
import SelectionMatch from './fnSelectionMatch';
import WrapperWidget from '../widgets/fnWrapperWidget';

interface ISelectiionMatchesProps {
	matches: IMatchWidgetMatch[];
	available: IAvailabilityWidgetPlayer[];
	selection: ISelection[];
	minPlayers: number;
	maxPlayers: number;
	callback: (remove: ISelection, add: ISelection) => void;
}

const SelectionMatches = (props: ISelectiionMatchesProps) => {
	let matchCount = 0;
	let homeCount = 0;
	var rows: any[] = [];
	props.matches.forEach((match) => {
		matchCount += 1;
		if (match.venue === "H") {
			homeCount += 1;
		}
		let available: ISelectionWidgetPlayer[] = [];
		props.available.forEach((av) => {
			let player = props.selection.find((se) => {
				return av.id === se.playerId;
			});
			if (player) {
				if (player.matchId === match.id) {
					available.push({ id: av.id, name: av.firstName + ' ' + av.surname, sex: av.sex, index: player.index, locked: player.locked, confirmed: player.confirmed, minTeam: av.minTeam });
				}
			} else {
				available.push({ id: av.id, name: av.firstName + ' ' + av.surname, sex: av.sex, index: 0, locked: false, confirmed: false, minTeam: av.minTeam });
			}
		});
		rows.push(<SelectionMatch match={match} available={available} key={match.id} callback={props.callback} minPlayers={props.minPlayers} maxPlayers={props.maxPlayers} />);
	});
	let badges: IWrapperWidgetBadge[] = [
		{ name: "Total", value: matchCount.toString() },
		{ name: " Home", value: homeCount.toString() },
		{ name: " Away", value: (matchCount - homeCount).toString() }
	];
	return (
		<WrapperWidget title={"Matches"} controlName={"WgtMatches"} badges={badges} panelClass={"primary"} openByDefault={true} glyph="fa fa-calendar" >
		<div className="pl-2 pr-2" >
			{rows}
		</div>
		</WrapperWidget>
		);
};

export default SelectionMatches;
