import React from 'react';
import { IMatchWidgetMatch } from '../widgets/fnMatchRow';
import { ISelectionWidgetPlayer } from '../widgets/fnSelectionPlayerRow';
import { ISelection } from '../../store/selection';
import SelectionWidget from '../widgets/fnSelectionWidget';

interface ISelectionMatchProps {
	match: IMatchWidgetMatch;
	available: ISelectionWidgetPlayer[];
	minPlayers: number;
	maxPlayers: number;
	callback: (remove: ISelection, add: ISelection) => void;
}

const SelectionMatch = React.memo(
(props: ISelectionMatchProps) => {
	let venueIconClass = "fa fa-home";
	if (props.match.venue === "A") {
		venueIconClass = "fa fa-plane";
	}
	let title = <span> <span className={venueIconClass} /> {props.match.team} < br />vs {props.match.opposition}</span>;
	let players: ISelectionWidgetPlayer[] = [];
	props.available.forEach((av) => {
		if (av.index > 0) {
			players.push(av);
		}
	});
	return (
		<SelectionWidget
			title={title}
			controlName={"WgtMatch" + props.match.id.toString()}
			matchId={props.match.id}
			isMixed={props.match.isMixed}
			required={props.minPlayers}
			max={props.maxPlayers}
			players={players}
			available={props.available}
			callback={props.callback}
		/>
	);
},
(prevProps: ISelectionMatchProps, nextProps: ISelectionMatchProps) => {
	let same: boolean = JSON.stringify(prevProps.available) === JSON.stringify(nextProps.available)
		&& JSON.stringify(prevProps.match) === JSON.stringify(nextProps.match);
	// temporary work around because the selection is not working correctly due to the above blocker
	// we can probably take this out of a memo component
	same = false;
	return same;
});

export default SelectionMatch;