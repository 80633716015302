import React from 'react';
import { ICompetition } from '../../store/match';
import { IStatsFilter } from '../../store/player';
import { ChecklistBoxSelectionAsString } from '../controls/modal/fnChecklistBox';
import { IChecklistItem } from '../controls/modal/fnChecklistBoxItem';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button } from 'reactstrap';

interface IStatsFilterEditProps {
	title: string;
	filter: IStatsFilter;
	competitions: ICompetition[];
	onFilterSaved: (filter: IStatsFilter) => void;
}

interface IStatsFilterEditState {
	filter: IStatsFilter;
	selectedCompetitions: IChecklistItem[];
	changesMade: boolean;
}

const StatsFilterEdit = (props: IStatsFilterEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const initialDataState: IStatsFilterEditState = {
		filter: props.filter,
		selectedCompetitions: props.competitions.map((cp) => {
			return {
				text: cp.name,
				value: cp.id.toString(),
				checked: props.filter.competitions.indexOf(cp.id) >= 0
			};
		}),
		changesMade: false
	};
	const [data, setData] = React.useState(initialDataState);

	const revertStatusToProps = () => {
		if (data.changesMade) {
			setData(initialDataState);
		}
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		revertStatusToProps();
		setShowing(!showing);
	};

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				filter: {
					...currData.filter,
					[name]: value
				},
				changesMade: true 
			};
		});
	};

	const onFilterControlChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "filtering") {
			value = e.currentTarget.checked;
		}
		setRecordValue(name, value);
	};

	const onCompetitionsUpdated = (items: IChecklistItem[]) => {
		let compIds: number[] = [];
		items.forEach((cp) => {
			if (cp.checked) {
				compIds.push(Number(cp.value));
			}
		});
		setData(currData => {
			return {
				...currData,
				filter: {
					...currData.filter,
					competitions: compIds
				},
				selectedCompetitions: items,
				changesMade: true 
			};
		});
	};

	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		switch (control) {
			default:
				break;
		}
		return errorMsg;
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			props.onFilterSaved(data.filter);
			hideModal();
		}
	};

	const cancelChanges = () => {
		revertStatusToProps();
		hideModal();
	};

	let controls: IEditControl[] = [];
	// filtering
	let filteringControl: any[] = [];
	filteringControl.push(
		<label className="switch" key="1">
			<input type="checkbox" id="filtering" checked={data.filter.filtering} onChange={e => onFilterControlChange(e)} />
			<span className="slider round" />
		</label>
	);
	controls.push({
		id: "filering",
		label: "Filtering:",
		isCustom: true,
		inputType: "",
		maxLength: 0,
		value: data.filter.filtering,
		onChange: onFilterControlChange,
		onValidate: onValidate,
		children: filteringControl
	});
	// competitions
	controls.push({
		id: "competitions",
		label: "Competitions:",
		title: "Competitions",
		isCustom: true,
		inputType: "ChecklistBox",
		value: ChecklistBoxSelectionAsString(data.selectedCompetitions),
		children: data.selectedCompetitions,
		callback: onCompetitionsUpdated
	});

	return (
		<div>
			<Button color={props.filter.filtering ? "warning" : "secondary"} onClick={toggle}>
				<i className="fa fa-filter" />{props.filter.filtering ? "Filtering..." : "Filter"}
			</Button>
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"}>
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody>
					<EditControlSet controls={controls} validate={true} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={cancelChanges}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default StatsFilterEdit;