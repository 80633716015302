import React, { useState } from 'react';
import * as SelectionStore from '../../store/selection';
import { CustomInput } from 'reactstrap';

export interface ISelectionWidgetPlayer {
	id: number;
	name: string;
	sex: string;
	index: number;
	locked: boolean;
	confirmed: boolean;
	minTeam: number;
}

interface IPlayerRowProps {
	matchId: number;
	isMixed: boolean;
	player: ISelectionWidgetPlayer;
	available: ISelectionWidgetPlayer[];
	callback: (remove: SelectionStore.ISelection, add: SelectionStore.ISelection) => void;
}

const SelectionPlayerRow = (props: IPlayerRowProps) => {
	const [playerId, setPlayerId] = useState(props.player.id);
	const [locked, setLocked] = useState(props.player.locked);

	const onSelectionChange = (e: any) => {
		let oldSelection: SelectionStore.ISelection = { matchId: props.matchId, playerId: playerId, index: props.player.index, locked: locked, confirmed: props.player.confirmed, notes: "" };
		let selectedPlayer = Number(e.target.value);
		let newSelection: SelectionStore.ISelection = { matchId: props.matchId, playerId: selectedPlayer, index: props.player.index, locked: locked, confirmed: props.player.confirmed, notes: "" };
		props.callback(oldSelection, newSelection);
		setPlayerId(selectedPlayer);
	};

	const onLockedChange = (e: any) => {
		let oldSelection: SelectionStore.ISelection = { matchId: 0, playerId: 0, index: 0, locked: false, confirmed: false, notes: "" };
		let newSelection: SelectionStore.ISelection = { matchId: props.matchId, playerId: playerId, index: props.player.index, locked: e.currentTarget.checked, confirmed: props.player.confirmed, notes: "" };
		props.callback(oldSelection, newSelection);
		setLocked(e.currentTarget.checked);
	};

	var availablePlayers: any[] = [];
	availablePlayers.push(<option value={0} key={0}>{""}</option>);
	props.available.forEach((player) => {
		if (player.index === 0 || player.index === props.player.index) {
			let displayName: string = player.name;
			if (player.minTeam > 0) {
				displayName = displayName + " (min team: " + player.minTeam.toString() + ")";
			}
			let option = <option value={player.id} key={player.id} > {displayName}</option>;
			// for mixed matches the odd positions are female and even are male
			let sexType: string = "F";
			if (props.player.index % 2 === 0) {
				sexType = "M";
			}
			if (!props.isMixed || player.sex === sexType) {
				availablePlayers.push(option);
			}
		}
	});
	let rowClass = "";
	return (
		<tr className={rowClass} >
			<td className="text-center" style={{ paddingTop: 10 }}>
				{props.player.index}
			</td>	
			<td>
				<CustomInput id="pldd" type="select" value={playerId} onChange={onSelectionChange} disabled={locked} >
					{availablePlayers}
				</CustomInput>
			</td>
			<td>
				<label className="switch">
					<input type="checkbox" checked={locked} onChange={onLockedChange} disabled={!playerId} />
					<span className="slider round" />
				</label>
			</td>
		</tr>
	);
};

export default SelectionPlayerRow;
