import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IAvailability } from '../../store/availability';
import { IActivity, IActivityFilter, IActivityMatch, ICompetition, ITeam, IMatch,
	actionCreators as matchActionCreators, emptyCompetition, IActivityRecord } from '../../store/match';
import { ISelection } from '../../store/selection';
import { IAuthState } from '../../store/auth';
import { bindActionCreators } from "redux";
import { formatDateString, parseDateString, EDateFormat, daysDiff } from '../../utils/dates';
import ActivityFilterEdit from '../activity/fnActivityFilterEdit';
import ActivityEdit from '../activity/fnActivityEdit';
import ActivityListTable from './fnActivityListTable';
import { IActivityListItem } from './fnActivityListRow'; 

interface IActivityListProps {
	auth: IAuthState;
	isTeamCaptain: boolean;
	myClubId: number;
	activities: IActivity[];
	competitions: ICompetition[];
	teams: ITeam[];
	matches: IMatch[];
	availability: IAvailability[];
	selection: ISelection[];
	filter: IActivityFilter;
	actions: typeof matchActionCreators;
}

type TActivityListProps = IActivityListProps & RouteComponentProps<{}>;

const ActivityList = (props: TActivityListProps) => {

	let items: IActivityListItem[] = [];

	// if not filtering get default position;
	let activityFilter: IActivityFilter = {
		filtering: props.filter.filtering,
		competitions: props.filter.competitions,
		status: props.filter.status
	};
	if (!activityFilter.filtering) {
		activityFilter.status = "X";
		activityFilter.competitions = [];
	}
	let today: Date = new Date();
	props.activities.forEach((activity) => {
		let competition: ICompetition = emptyCompetition;
		let competitionIdx: number = props.competitions.findIndex((cp) => { return cp.id === activity.competitionId; });
		if (competitionIdx >= 0) {
			competition = props.competitions[competitionIdx];
		}
		// get match date
		let activityDate: Date = parseDateString(activity.date);
		let expired: boolean = false;
		if (daysDiff(activityDate, today) > 0) {
			expired = true;
		}
		// check status
		if (activityFilter.status === "I" || (activityFilter.status === "O" && expired) || (activityFilter.status === "X" && !expired)) {
			if (activityFilter.competitions.length === 0 || activityFilter.competitions.indexOf(activity.competitionId) >= 0) {
				items.push({ activity: activity, competition: competition });
			}
		}
	});
	items.sort((a: IActivityListItem, b: IActivityListItem) => {
		if (a.activity.date < b.activity.date) {
			return -1;
		} else if (a.activity.date > b.activity.date) {
			return 1;
		} else if (a.activity.title < b.activity.title) {
			return -1;
		} else if (a.activity.title > b.activity.title) {
			return 1;
		} else {
			return 0;
		}
	});

	const matchListCallback = (competitionId: number, date: Date, selected: number[]): IActivityMatch[] => {
		let matches: IActivityMatch[] = [];
		if (props.matches) {
			props.matches.forEach((match) => {
				let matchDate = parseDateString(match.date);
				let alreadySelected: boolean = selected.indexOf(match.id) >= 0;
				if (alreadySelected || (date.getFullYear() === matchDate.getFullYear() && date.getMonth() === matchDate.getMonth() && date.getDate() === matchDate.getDate())) {
					var team = props.teams.find((tm) => { return tm.id === match.teamId; });
					if (team && (alreadySelected || team.competitionId === competitionId)) {
						let title = team.name + " (" + match.venue + ") vs " + match.opposition;
						if (date.getFullYear() === matchDate.getFullYear() && date.getMonth() === matchDate.getMonth() && date.getDate() === matchDate.getDate()) {
							title = title + " : " + formatDateString(match.date, EDateFormat.tim24H);
						} else {
							title = title + " : **" + formatDateString(match.date, EDateFormat.dayMonthWithTime24H) + "**";
						}
						let selectedPlayers = props.selection.filter((se) => { return se.matchId === match.id; }).length;
						matches.push({ id: match.id, title: title, date: match.date, selectedPlayers: selectedPlayers });
					}
				}
			});
		}
		return matches;
	};

	const onFilterSaved = (filter: IActivityFilter) => {
		props.actions.activityFilterSet(filter);
	};

	const onActivitySaved = (activityRecord: IActivityRecord) => {
		props.actions.activitySet(activityRecord);
	};

	const onDrillDown = (focusId: number) => {
		let activityIds: number[] = items.map((item) => { return item.activity.id; });
		props.history.push("/activity/details/" + focusId.toString(), { "ids": activityIds });
	};

	let addActivity;
	if (props.isTeamCaptain) {
		addActivity = (
			<ActivityEdit
				activityId={0}
				title="Add Activity"
				clubId={props.myClubId}
				auth={props.auth}
				competitions={props.competitions}
				matchListCallback={matchListCallback}
				onActivitySaved={onActivitySaved}
				online={props.auth.deviceOnLine}
			/>
		);
	}

	return (
		<div className="container body-content">
			<ActivityFilterEdit title="Availability Filter" filter={props.filter} competitions={props.competitions} onFilterSaved={onFilterSaved} />
			<ActivityListTable items={items} onDrillDown={onDrillDown}/>
			{addActivity}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	isTeamCaptain: state.players.isTeamCaptain,
	myClubId: state.players.myClubId,
	activities: state.matches.data.activities,
	competitions: state.matches.data.competitions,
	matches: state.matches.data.matches,
	teams: state.matches.data.teams,
	availability: state.availability.data.availability,
	selection: state.selection.data.selection,
	filter: state.matches.activityFilter
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(matchActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps,
)(ActivityList) as any;
