export interface IDateParts {
	year: number;
	month: number;
	day: number;
	hour: number;
	minute: number;
	second: number;
	ms: number;
}

export enum EDateFormat {
	dateShort,
	dateShortWithTime24H,
	dayMonthWithTime24H,
	webApi,
	tim24H,
	fullDayMonthWithTime24H,
	JSON
}

export enum EDateParseFormat {
	YMD,
	DMY
}

export function padNumeric(element: number, width: number) {
	let str = element.toString();
	while (str.length < width) {
		str = "0" + str;
	}
	return str;
}

export function dayName(dayNo: number) {
	switch (dayNo) {
		case 1:
			return "Sunday";
		case 2:
			return "Monday";
		case 3:
			return "Tuesday";
		case 4:
			return "Wednesday";
		case 5:
			return "Thursday";
		case 6:
			return "Friday";
		case 7:
			return "Saturday";
		default:
			return "?";
	}
}

export function monthName(monthNo: number) {
	switch (monthNo) {
		case 1:
			return "January";
		case 2:
			return "February";
		case 3:
			return "March";
		case 4:
			return "April";
		case 5:
			return "May";
		case 6:
			return "June";
		case 7:
			return "July";
		case 8:
			return "August";
		case 9:
			return "September";
		case 10:
			return "October";
		case 11:
			return "November";
		case 12:
			return "December";
		default:
			return "?";
	}
}

export function daySuffix(day: number) {
	let suffix = "th";
	if (day === 1 || day === 21 || day === 31) {
		suffix = "st";
	} else if (day === 2 || day === 22) {
		suffix = "nd";
	} else if (day === 3 || day === 23) {
		suffix = "rd";
	}
	return suffix;
}

export function getDateParts(date: Date): IDateParts {
	const parts: IDateParts = {
		day: date.getDate(),
		month: date.getMonth() + 1,
		year: date.getFullYear(),
		hour: date.getHours(),
		minute: date.getMinutes(),
		second: date.getSeconds(),
		ms: date.getMilliseconds()
	};
	return parts;
}

export function dateToString(date: Date, format: EDateFormat): string {
	let parts: IDateParts = getDateParts(date);
	return datePartsToString(parts, format);
}

export function formatDateString(dateStr: string, format: EDateFormat): string {
	let parts: IDateParts = parseDateStringToParts(dateStr);
	return datePartsToString(parts, format);
}

function datePartsToString(parts: IDateParts, format: EDateFormat): string {
	let dateStr: string = "";
	if (format === EDateFormat.webApi || format === EDateFormat.JSON) {
		dateStr = parts.year.toString() + "-" + padNumeric(parts.month, 2) + "-" + padNumeric(parts.day, 2);
	} else if (format === EDateFormat.dayMonthWithTime24H) {
		dateStr = padNumeric(parts.day, 2) + "/" + padNumeric(parts.month, 2);
	} else {
		dateStr = padNumeric(parts.day, 2) + "/" + padNumeric(parts.month, 2) + "/" + parts.year.toString();
	}
	switch (format) {
		case EDateFormat.dateShortWithTime24H:
			dateStr = dateStr + " " + padNumeric(parts.hour, 2) + ":" + padNumeric(parts.minute, 2);
			break;
		case EDateFormat.dayMonthWithTime24H:
			dateStr = dateStr + " " + padNumeric(parts.hour, 2) + ":" + padNumeric(parts.minute, 2);
			break;
		case EDateFormat.webApi:
			dateStr = dateStr + " " + padNumeric(parts.hour, 2) + ":" + padNumeric(parts.minute, 2) + ":" + padNumeric(parts.second, 2) + "." + padNumeric(parts.ms, 4);
			break;
		case EDateFormat.JSON:
			dateStr = dateStr + "T" + padNumeric(parts.hour, 2) + ":" + padNumeric(parts.minute, 2) + ":" + padNumeric(parts.second, 2) + "." + padNumeric(parts.ms, 4) + "Z";
			break;
		case EDateFormat.tim24H:
			dateStr = padNumeric(parts.hour, 2) + ":" + padNumeric(parts.minute, 2);
			break;
		case EDateFormat.fullDayMonthWithTime24H:
			let weekDay: number = new Date(parts.year, parts.month - 1, parts.day).getDay() + 1;
			dateStr = dayName(weekDay) + " " + parts.day.toString() + daySuffix(parts.day) + " " + monthName(parts.month) + " " + padNumeric(parts.hour, 2) + ":" +  padNumeric(parts.minute, 2);
			break;
		default:
			break;
	}
	return dateStr;
}

export function parseDateString(dateStr: string, format?: EDateParseFormat): Date {
	let parts: IDateParts = parseDateStringToParts(dateStr, format);
	return new Date(parts.year, parts.month - 1, parts.day, parts.hour, parts.minute, parts.second, parts.ms);
}

export function parseDateStringToParts(dateStr: string, format?: EDateParseFormat): IDateParts {
	let date: Date = new Date();
	let day: number = date.getDate();
	let month: number = date.getMonth() + 1;
	let year: number = date.getFullYear();
	let hour: number = 0;
	let minute: number = 0;
	let second: number = 0;
	let ms: number = 0;
	// splits on any character not numeric
	let parts = dateStr.split(/\D+/);
	if (format && format === EDateParseFormat.DMY) {
		if (parts.length > 0) { day = parseInt(parts[0]); }
		if (parts.length > 1) { month = parseInt(parts[1]); }
		if (parts.length > 2) { year = parseInt(parts[2]); }
	} else {
		if (parts.length > 0) { year = parseInt(parts[0]); }
		if (parts.length > 1) { month = parseInt(parts[1]); }
		if (parts.length > 2) { day = parseInt(parts[2]); }
	}
	if (parts.length > 3) { hour = parseInt(parts[3]); }
	if (parts.length > 4) { minute = parseInt(parts[4]); }
	if (parts.length > 5) { second = parseInt(parts[5]); }
	if (parts.length > 6) { ms = parseInt(parts[6]); }

	// sanity check date
	if (year) {
		if (year < 1000) {
			year = year + (2000);
		}
	} else {
		year = date.getFullYear();
	}
	if (!month || month < 1 || month > 12) {
		month = date.getMonth() + 1;
	}
	if (!day || day < 1 || day > 31) {
		day = date.getDate();
	}
	while (day > 28 && (new Date(year, month - 1, day)).getMonth() + 1 !== month) {
		day = day - 1;
	}

	// sanity check time
	if (!hour || hour < 0 || hour > 23) {
		hour = 0;
	}
	if (!minute || minute < 0 || minute > 59) {
		minute = 0;
	}
	if (!second || second < 0 || second > 59) {
		second = 0;
	}
	if (!ms || ms < 0 || ms > 999) {
		ms = 0;
	}

	let dateParts: IDateParts = {
		day: day,
		month: month,
		year: year,
		hour: hour,
		minute: minute,
		second: second,
		ms: ms
	};
	return dateParts;
}

export function today() {
	var now = new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function nowAsString() {
	return dateToString(new Date(), EDateFormat.webApi);
}

export function daysDiff(date1: Date, date2: Date): number {
	let t2: number = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()).getTime();
	let t1: number = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()).getTime();
	return (t2 - t1) / (24 * 3600 * 1000);	
}

export function JSONstringifyDateReplacer(key: string, value: any) {
	if (typeof (value) === 'object') {
		for (var k in value) {
			if (value[k] instanceof Date) {
				value[k] = dateToString(value[k], EDateFormat.webApi);
			}
		}
	} else if (value instanceof Date) {
		return dateToString(value, EDateFormat.webApi);
	}
	return value;
}

export function getNewDate(year: number, month: number, day: number, hour: number, minute: number, second: number): Date {
	return new Date(year, month - 1, day, hour, minute, second);
}