import { IAppThunkAction } from './';
import { Action, Reducer } from 'redux';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

export interface IAvailabilityState {
	isLoading: boolean;
	fetched?: Date;
	data: IAvailabilityData;
}

export interface IAvailabilityData {
	version: number;
	availability: IAvailability[];
}

export enum EAvailabilityStatus {
	Available,
	Unavailable,
	Maybe,
	Pending
}

export interface IAvailability {
	activityId: number;
	playerId: number;
	status: EAvailabilityStatus;
	notes: string;
}

export interface IAvailabilityUpdate {
	availability: IAvailability;
	sendEmail: boolean;
}

export interface IAvailabilitySummary {
	sent: number;
	accepted: number;
	rejected: number;
	maybe: number;
	pending: number;
}

interface IAvailabilityFetchResult {
	result: api.IApiResult;
	data: IAvailabilityData;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestAvailabilityData {
	type: 'REQUEST_AVAILABILITY_DATA';
}

interface IAvailabilityDataLoaded {
	type: 'AVAILABILITY_DATA_LOADED';
	data: IAvailabilityData;
}

interface IAvailabilitySet {
	type: 'AVAILABILITY_SET';
	availability: IAvailability;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestAvailabilityData | IAvailabilityDataLoaded | IAvailabilitySet;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestAvailabilityData: (accessToken: string, clubId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/availability/list?clubId=${clubId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<IAvailabilityFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'AVAILABILITY_DATA_LOADED', data: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Availability fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("Availability data not found");
						break;
				}
			});
		dispatch({ type: 'REQUEST_AVAILABILITY_DATA' });
	},
	setAvailability: ((accessToken: string, availability: IAvailabilityUpdate): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/availability`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken,
				"Pragma": "no-cache"
			},
			body: JSON.stringify(availability),
		})
			.then(response => response.json() as Promise<IAvailability>)
			.then((data) => { dispatch({ type: 'AVAILABILITY_SET', availability: data });	});
	})
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IAvailabilityState = { isLoading: false, data: { version: 0, availability: [] } };

export const reducer: Reducer<IAvailabilityState> = (state: IAvailabilityState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_AVAILABILITY_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'AVAILABILITY_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.data, isLoading: false });
		case 'AVAILABILITY_SET':
			let avRecordFound = false;
			let availability = state.data.availability.map((av) => {
				if (av.activityId === action.availability.activityId && av.playerId === action.availability.playerId) {
					avRecordFound = true;
					return action.availability;
				} else {
					return av;
				}
			});
			if (!avRecordFound) {
				availability.push(action.availability);
			}
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { availability: availability }) });
		default:
			return state;
	}
};

export function availabilitySummary(avList: IAvailability[]): IAvailabilitySummary {
	let sent: number = 0;
	let accepted: number = 0;
	let rejected: number = 0;
	let maybe: number = 0;
	let pending: number = 0;
	let itemCnt: number = avList.length;
	let itemNo: number = 0;
	while (itemNo < itemCnt) {
		let av: IAvailability = avList[itemNo];
		switch (av.status) {
			case EAvailabilityStatus.Available:
				accepted += 1;
				break;
			case EAvailabilityStatus.Unavailable:
				rejected += 1;
				break;
			case EAvailabilityStatus.Maybe:
				maybe += 1;
				break;
			default:
				pending += 1;
				break;
		}
		itemNo += 1;
	}
	sent = accepted + rejected + pending;
	return {
		sent: sent,
		accepted: accepted,
		rejected: rejected,
		maybe: maybe,
		pending: pending
	};
}