import { IAppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { IAvailabilitySummary } from './availability';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

export interface ISelectionState {
	isLoading: boolean;
	fetched?: Date;
	data: ISelectionData;
}

export interface ISelectionData {
	version: number;
	selection: ISelection[];
}

export interface ISelection {
	matchId: number;
	playerId: number;
	index: number;
	locked: boolean;
	confirmed: boolean;
	notes: string;
}

interface ISelectionFetchResult {
	result: api.IApiResult;
	data: ISelectionData;
}

interface IPlayerMatchSelection {
	matchId: number;
	selection: ISelection[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestSelectionData {
	type: 'REQUEST_SELECTION_DATA';
}

interface ISelectionDataLoaded {
	type: 'SELECTION_DATA_LOADED';
	data: ISelectionData;
}

interface ISelectionSet {
	type: 'SELECTION_SET';
	selection: ISelection;
}

interface ISelectionCleared {
	type: 'SELECTION_CLEARED';
	selection: ISelection;
}

interface IPlayerMatchSelectionSet {
	type: 'PLAYER_MATCH_SELECTION_SET';
	selection: IPlayerMatchSelection;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestSelectionData | ISelectionDataLoaded | ISelectionSet | ISelectionCleared | IPlayerMatchSelectionSet;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestSelectionData: (accessToken: string, clubId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/selection/list?clubId=${clubId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<ISelectionFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'SELECTION_DATA_LOADED', data: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Selection data fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("Selection data not found");
						break;
				}
			});
		dispatch({ type: 'REQUEST_SELECTION_DATA' });
	},
	setSelection: ((accessToken: string, selection: ISelection): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/selection`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken,
				"Pragma": "no-cache"
			},
			body: JSON.stringify(selection),
		})
			.then(response => response.json() as Promise<ISelection>)
			.then((data) => { dispatch({ type: 'SELECTION_SET', selection: data }); });
	}),
	clearSelection: ((accessToken: string, selection: ISelection): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/selection/clear`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken,
				"Pragma": "no-cache"
			},
			body: JSON.stringify(selection),
		})
			.then(response => response.json() as Promise<ISelection>)
			.then((data) => { dispatch({ type: 'SELECTION_CLEARED', selection: data }); });
	}),
	playerMatchSelectionSet: (matchId: number, selection: ISelection[]): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		let ms: IPlayerMatchSelection = { matchId: matchId, selection: selection };
		dispatch({ type: 'PLAYER_MATCH_SELECTION_SET', selection: ms });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ISelectionState = { isLoading: false, data: { version: 0, selection: [] } };

export const reducer: Reducer<ISelectionState> = (state: ISelectionState |undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_SELECTION_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'SELECTION_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.data, isLoading: false });
		case 'SELECTION_SET':
			let ss: ISelection[] = state.data.selection.filter((se) => {
				return (se.matchId !== action.selection.matchId || se.playerId !== action.selection.playerId);
			});
			ss.push(action.selection);
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { selection: ss }) });
		case 'SELECTION_CLEARED':
			let sc: ISelection[] = state.data.selection.filter((se) => {
				return (se.matchId !== action.selection.matchId || se.playerId !== action.selection.playerId);
			});
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { selection: sc }) });
		case 'PLAYER_MATCH_SELECTION_SET':
			let selection: ISelection[] = state.data.selection.filter((se) => { return se.matchId !== action.selection.matchId; });
			selection = selection.concat(action.selection.selection);
			return Object.assign({}, { ...state }, { isLoading: false, data: Object.assign({}, { ...state.data }, { selection: selection }) });
		default:
			return state;
	}

	// For unrecognized actions (or in cases where actions have no effect), must return the existing state
	//  (or default initial state if none was supplied)
	//return state || unloadedState;
};

export function selectionSummary(seList: ISelection[]): IAvailabilitySummary {
	let sent: number = 0;
	let accepted: number = 0;
	let rejected: number = 0;
	let maybe: number = 0;
	let pending: number = 0;
	let itemCnt: number = seList.length;
	let itemNo: number = 0;
	while (itemNo < itemCnt) {
		let se: ISelection = seList[itemNo];
		if (se.locked) {
			accepted += 1;
		} else {
			pending += 1;
		}
		itemNo += 1;
	}
	sent = accepted + rejected + pending;
	return {
		sent: sent,
		accepted: accepted,
		rejected: rejected,
		maybe: maybe,
		pending: pending
	};
}