import { User, UserManager } from "oidc-client";
import { AnyAction, bindActionCreators, Store } from "redux";
import { IApplicationState } from "./store";
import { actions as authActions, IUser } from "./store/auth";
import { actionCreators as playerActionCreators } from "./store/player";
import { createUserManager, mapIdentityServerUser } from "./utils/userManager";

export class UserControl {
  userManager: UserManager;
  store: Store<IApplicationState, AnyAction>

  constructor(store: Store<IApplicationState, AnyAction>) {
    this.userManager = createUserManager();
    this.userManager.events.addUserLoaded(this.onUserLoaded);
    this.userManager.events.addUserUnloaded(this.onUserUnloaded);
    this.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
    this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
    this.userManager.events.addSilentRenewError(this.onSilentRenewError);
    this.userManager.events.addUserSignedIn(this.onUserSignedIn);
    this.userManager.events.addUserSignedOut(this.onUserSignedOut);
    this.userManager.events.addUserSessionChanged(this.onUserSessionChanged);
    this.store = store;
  }

  private onUserLoaded = (user: Oidc.User) => {
    if (user && !user.expired) {
      this.userLoaded(user);
    }
    return user;
  };
  
  private onUserUnloaded = () => {
  };

  private onUserSignedIn = () => {
  }
  
  private onUserSignedOut = () => {
    this.userManager.signoutRedirect();
  };
  
  private onAccessTokenExpiring = () => {
    this.store.dispatch(authActions.accessTokenExpiring());
  };
  
  private onAccessTokenExpired = () => {
    this.store.dispatch(authActions.accessTokenExpiring());
  };
  
  private onSilentRenewError = (error: any) => {
    this.store.dispatch(authActions.silentRenewError(error));
    // silent renew failed so request a login
    // -> don't check if online because that status is possibly affected by the remew request being blocked
    this.userManager.signinRedirect();
  };
  
  private onUserSessionChanged = () => {
  }
  
  // repond to user manager user fetched event
  private onGetUserResult = (user: Oidc.User | null) => {
    if (user) {
      this.userLoaded(user);
      if (!user.expires_in || user.expires_in <= 60) {
          // user access token expired so try a silent renew
        if (this.store.getState().auth.deviceOnLine) {
          this.userManager.signinSilent().catch((reason) => {
            // if auth cookie has expired or other issue then force login
            this.userManager.signinRedirect();
          });
        }
      } else {
        let emailVerified: boolean = (user.profile && (user.profile.email_verified != null) && user.profile.email_verified);
        if (!emailVerified) {
          this.userManager.signinSilent()
            .then((newUser) => this.onGetUserResult(newUser));
        }
      }
    } else {
      // user is not logged in
      if (this.store.getState().auth.deviceOnLine) {
        // request a login
        this.userManager.signinRedirect();
      }
    }
    return user;
  };

  private getUser = () => {
    this.userManager.getUser()
    .then((user) => this.onGetUserResult(user))
    .catch(() => { this.userManager.signinRedirect(); });
  }

  private userLoaded = (user: User) => {
    if (user && !user.expired) {
      let mappedUser: IUser = mapIdentityServerUser(user);
      this.store.dispatch(authActions.userLoaded(mappedUser));
      this.fetchApplicationUser(mappedUser, user.profile.sub);
    }
  }

  private fetchApplicationUser = (mappedUser: IUser, userId: string) => {
    if (this.store.getState().auth.deviceOnLine) {
      bindActionCreators(playerActionCreators, this.store.dispatch).requestPlayerDetails(mappedUser.accessToken, userId, true);
    }
  }

  public handleCallback = () => {
    //this.userManager.signinRedirectCallback().catch(() => { this.userManager.signinRedirect(); });
    this.userManager.signinRedirectCallback().catch(reason => { console.log(JSON.stringify(reason)); });
  }

  public handleRenew = () => {
    //this.userManager.signinSilentCallback().catch(() => { this.userManager.signoutRedirect(); });
    this.userManager.signinSilentCallback().catch(reason => { console.log(JSON.stringify(reason)); });
  }

  public silentRenew = () => {
    if (this.store.getState().auth.deviceOnLine) {
      this.userManager.signinSilent()
      .then((user) => {
        this.onGetUserResult(user);
      })
      .catch((reason) => {
        // if auth cookie has expired or other issue then force login
        this.userManager.signinRedirect();
      });
    }
  }

  public loadUser = () => {
    this.getUser();
  }
}