import React from 'react';
import * as AvailabilityStore from '../../store/availability';
import * as dateUtils from '../../utils/dates';
import { CustomInput } from 'reactstrap';

export interface IAvailabilityWidgetActivity {
	id: number;
	date: Date;
	title: string;
	status: AvailabilityStore.EAvailabilityStatus;
	readOnly: boolean;
}

interface IActivityRowProps {
	playerId: number;
	data: IAvailabilityWidgetActivity;
	callback: (av: AvailabilityStore.IAvailability) => void;
}

const ActivityRow = (props: IActivityRowProps) => {
	const [status, setStatus] = React.useState(props.data.status);

	const onAvailabilityChange = (e: any) => {
		var availability: AvailabilityStore.IAvailability = {
			activityId: props.data.id,
			playerId: props.playerId,
			status: e.currentTarget.selectedIndex,
			notes: ""
		};
		props.callback(availability);
		setStatus(e.currentTarget.selectedIndex);
	};

	let rowClass = "";
	if (status !== AvailabilityStore.EAvailabilityStatus.Pending) {
		rowClass = "danger";
	}
	let dateParts = dateUtils.getDateParts(props.data.date);
	let dateStr = dateUtils.padNumeric(dateParts.day, 2) + "/" + dateUtils.padNumeric(dateParts.month, 2) + " " + dateUtils.padNumeric(dateParts.hour, 2) + ":" + dateUtils.padNumeric(dateParts.minute, 2);
	return (
		<tr className={rowClass} >
			<td>{dateStr}</td>
			<td>{props.data.title}</td>
			<td>
				<CustomInput id="stdd" type="select" value={status} onChange={onAvailabilityChange} disabled={props.data.readOnly} >
					<option value={AvailabilityStore.EAvailabilityStatus.Available.toString()}>Yes</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Unavailable.toString()}>No</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Maybe.toString()}>Maybe</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Pending.toString()}>?</option>
				</CustomInput>
			</td>
		</tr>
	);
};

export default ActivityRow;
