import React from 'react';
import { EAvailabilityStatus } from '../../store/availability';
import AvailabilityPlayers from '../activity/fnAvailabilityPlayers';

interface IRequestStatusesProps {
	activityId?: number;
	matchId?: number;
	requestsAccepted: number;
	requestsRejected: number;
	requestsMaybe: number;
	requestsPending: number;
	positiveColour?: string;
}

const RequestStatuses = (props: IRequestStatusesProps) => {
	const [popupShowing, setPopupShowing] = React.useState(false);
	const [popupStatus, setPopupStatus] = React.useState();

	const showPlayers = (status?: any) => {
		setPopupShowing(true);
		setPopupStatus(status);
	};

	const playersListClosed = () => {
		setPopupShowing(false);
	};

	const onAcceptedList = () => {
		if (props.requestsAccepted) {
			showPlayers(EAvailabilityStatus.Available);
		}
	};

	const onRejectedList = () => {
		if (props.requestsRejected) {
			showPlayers(EAvailabilityStatus.Unavailable);
		}
	};

	const onMaybeList = () => {
		if (props.requestsMaybe) {
			showPlayers(EAvailabilityStatus.Maybe);
		}
	};

	const onPendingList = () => {
		if (props.requestsPending) {
			showPlayers(EAvailabilityStatus.Pending);
		}
	};

	let acceptedColour: string = "green";
	if (props.positiveColour) {
		acceptedColour = props.positiveColour;
	}

	return (
		<div
			style={{
				fontSize: 16
			}}
		>
			{/* accepted */}
			<span
				style={{
					marginLeft: 3,
					marginRight: 15,
					paddingTop: 2,
					color: acceptedColour,
					cursor: "pointer"
				}}
				onClick={() => onAcceptedList()}
			>
				<i className="fa fa-thumbs-o-up" /> {props.requestsAccepted}
			</span>
			{/* rejected */}
			<span
				style={{
					marginLeft: 3,
					marginRight: 15,
					paddingTop: 2,
					color: "dimgray",
					cursor: "pointer"
				}}
				onClick={() => onRejectedList()}
			>
				<i className="fa fa-thumbs-o-down" /> {props.requestsRejected}
			</span>
			{/* maybe */}
			<span
				style={{
					marginLeft: 3,
					marginRight: 15,
					paddingTop: 2,
					color: "purple",
					cursor: "pointer"
				}}
				onClick={() => onMaybeList()}
			>
				<i className="fa fa-edit" /> {props.requestsMaybe}
			</span>
			{/* pending */}
			<span
				style={{
					marginLeft: 3,
					marginRight: 15,
					paddingTop: 2,
					color: "red",
					cursor: "pointer"
				}}
				onClick={() => onPendingList()}
			>
				<i className="fa fa-hourglass-o" /> {props.requestsPending}
			</span>
			<AvailabilityPlayers
				activityId={props.activityId}
				matchId={props.matchId}
				showing={popupShowing}
				status={popupStatus}
				onClose={() => playersListClosed()}
			/>
		</div>
	);
};

export default RequestStatuses;