import React from 'react';
import { IAuthState } from '../../store/auth';
import { ICompetition, IActivityRecord, emptyActivityRecord, IActivityFetchResult, IActivityMatch } from '../../store/match';
import { parseDateString, JSONstringifyDateReplacer, dateToString, EDateFormat } from '../../utils/dates';
import { ETimePickerMinuteSlots } from '../controls/dates/fnTimePicker';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button, Alert } from 'reactstrap';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import { ChecklistBoxSelectionAsString } from '../controls/modal/fnChecklistBox';
import { IChecklistItem } from '../controls/modal/fnChecklistBoxItem';
import Loading, { ELoadingSize } from '../controls/spinners/fnLoading';
import { EApiResultType, ApiCheckResponseForError } from '../api';
import { AppConfig, IAppConfig } from '../../config/appConfig';
let config: IAppConfig = AppConfig();

interface IActivityEditProps {
	activityId: number;
	title: string;
	auth: IAuthState;
	clubId: number;
	competitions: ICompetition[];
	matchListCallback: (competition: number, date: Date, selected: number[]) => IActivityMatch[];
	onActivitySaved: (activity: IActivityRecord) => void;
	online: boolean;
}

interface IActivityEditState {
	record: IActivityRecord;
	selectedMatches: IChecklistItem[];
	loading: boolean;
	changesMade: boolean;
}

const ActivityEdit = (props: IActivityEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const [validate, setValidate] = React.useState(false);
	const [serverError, setServerError] = React.useState("");
	const [data, setData] = React.useState({
		record: emptyActivityRecord,
		selectedMatches: [],
		loading: false,
		changesMade: false
	} as IActivityEditState);

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				record: {
					...currData.record,
					activity: {
						...currData.record.activity,
						[name]: value
					}
				},
				changesMade: true 
			};
		});
	};

	const onActivityChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "competitionId") {
			let newId: number = parseInt(value)
			setRecordValue(name, newId);
		} else {
			setRecordValue(name, value);
		}
	};

	const onDateChange = (inDate: Date) => {
		setRecordValue("date", dateToString(inDate, EDateFormat.webApi));
	};

	const onMatchesUpdated = (items: IChecklistItem[]) => {
		let matchIds: number[] = [];
		items.forEach((item) => {
			if (item.checked) {
				matchIds.push(parseInt(item.value));
			}
		});
		setData(currData => {
			return {
				...currData,
				selectedMatches: items,
				record: {
					...currData.record,
					matchIds: matchIds
				},
				changesMade: true 
			};
		});
	};

	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		switch (control.id) {
			case "type":
				if (data.record.activity.type === "") {
					errorMsg = "Please select an activity type";
				}
				break;
			case "competitionId":
				if (data.record.activity.competitionId === 0) {
					errorMsg = "Please select a competiton";
				}
				break;
			case "title":
				if (data.record.activity.title.trim() === "") {
					errorMsg = "Please enter a title";
				}
				break;
			default:
				break;
		}
		return errorMsg;
	};

	const fetchRecord = () => {
		if (props.activityId > 0) {
			if (props.auth.user) {
				// set loading flag
				setData(currData => { return {...currData, loading: true}; });
				// request data from server
				fetch(`${config.apiUrl}/activity/details?activityId=${props.activityId}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
				})
					.then(response => response.json() as Promise<IActivityFetchResult>)
					.then(recordFetched)
					.catch((error) => {
						setServerError(error.message);
					});
			} else {
				setServerError("not authorised");
			}
		}
	};

	const fetchSelectedMatches = (ar: IActivityRecord): IChecklistItem[] => {
		let retMatches: IChecklistItem[] = [];
		let matchDate = parseDateString(ar.activity.date);
		let possibleMatches = props.matchListCallback(ar.activity.competitionId, matchDate, ar.matchIds);
		possibleMatches.forEach((pm) => {
			retMatches.push({ text: pm.title, value: pm.id.toString(), checked: ar.matchIds.indexOf(pm.id) >= 0, readOnly: pm.selectedPlayers > 0 });
		});
		return retMatches;
	};

	const recordFetched = (result: IActivityFetchResult) => {
		switch (result.result.resultType) {
			case EApiResultType.OK:
				setData({
					record: result.data,
					selectedMatches: fetchSelectedMatches(result.data),
					loading: false,
					changesMade: false
				});
				break;
			case EApiResultType.Error:
				setServerError(result.result.errorMessage);
				break;
			default:
				setServerError("Activity record not found");
				break;
		}
	};

	const recordSaved = (result: IActivityFetchResult) => {
		if (result.result.resultType === EApiResultType.OK) {
			props.onActivitySaved(result.data);
			hideModal();
		} else {
			setServerError(result.result.errorMessage);
		}
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			if (props.auth.user && props.clubId) {
				fetch(`${config.apiUrl}/activity`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
					body: JSON.stringify({ activity: data.record.activity, matchIds: data.record.matchIds, clubId: props.clubId }, JSONstringifyDateReplacer)
				})
				.then(Response => {
					ApiCheckResponseForError(Response);
					return Response.json() as Promise<IActivityFetchResult>;
				})
				.then(recordSaved)
				.catch((error) => {
					setServerError(error.message);
				});
			}
		} else {
			setValidate(true);
		}
	};

	const showModal = () => {
		fetchRecord();
		setShowing(true);
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		setShowing(!showing);
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	let controls: IEditControl[] = [];
	// date/time
	controls.push({
		id: "date",
		label: "Date/Time:",
		isCustom: true,
		inputType: "DateTimePicker",
		subType: ETimePickerMinuteSlots.five,
		value: parseDateString(data.record.activity.date),
		onChange: onDateChange,
		onValidate: onValidate
	});
	// type
	let typeOptions: any[] = [];
	typeOptions.push(<option value="Match" key="1">Match</option>);
	typeOptions.push(<option value="Practice" key="2">Practice</option>);
	controls.push({
		id: "type",
		label: "Type:",
		isCustom: true,
		inputType: "select",
		value: data.record.activity.type,
		onChange: onActivityChange,
		onValidate: onValidate,
		children: typeOptions
	});
	// competition
	let competitionOptions: any[] = [];
	competitionOptions.push(<option value="0" key={0} />);
	props.competitions.forEach((cp) => {
		competitionOptions.push(<option value={cp.id} key={cp.id} > {cp.name}</option>);
	});
	controls.push({
		id: "competitionId",
		label: "Competition:",
		isCustom: true,
		inputType: "select",
		value: data.record.activity.competitionId.toString(),
		onChange: onActivityChange,
		onValidate: onValidate,
		children: competitionOptions
	});
	// title
	controls.push({
		id: "title",
		label: "Title:",
		isCustom: false,
		inputType: "text",
		maxLength: 100,
		value: data.record.activity.title,
		onChange: onActivityChange,
		onValidate: onValidate
	});
	// notes
	controls.push({
		id: "notes",
		label: "Notes:",
		isCustom: false,
		inputType: "textarea",
		maxLength: 1000,
		rows: 8,
		value: data.record.activity.notes,
		onChange: onActivityChange,
		onValidate: onValidate
	});
	// matches
	controls.push({
		id: "matches",
		label: "Matches:",
		title: "Linked Matches",
		isCustom: true,
		inputType: "ChecklistBox",
		value: ChecklistBoxSelectionAsString(data.selectedMatches),
		children: data.selectedMatches,
		callback: onMatchesUpdated
	});

	let serverErrorCtrl;
	if (serverError !== "") {
		serverErrorCtrl = (
			<Alert color="danger">
				{serverError}
			</Alert>
		);
	}

	let loadingCtrl;
	if (data.loading) {
		if (serverErrorCtrl) {
			loadingCtrl = serverErrorCtrl;
		} else {
			// loading spinner and message will be displayed if loading takes more than 300 milliseconds
			loadingCtrl = <Loading message="fetching data from server" size={ELoadingSize.medium} delayed={true} delayInMs={300} />;
		}
	}

	let modalButton;
	if (props.activityId > 0) {
		modalButton = (
			<span>
				<Button color="secondary" onClick={showModal} disabled={!props.online}>
					<i className="fa fa-edit" /> Edit
				</Button>
			</span>
		);
	} else {
		modalButton = (
			<Button color="secondary" onClick={showModal} disabled={!props.online}>
				<i className="fa fa-plus" /> Add Activity
			</Button>
		);
	}

	return (
		<span>
			{modalButton}
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"} size="lg">
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody style={{ overflowY: data.loading ? 'hidden' : 'auto'}}>
					{/*  use overlay to hide edit controls until data is fetched from api */}
					<div className="modal-body-overlay" style={{ display: data.loading ? 'block' : 'none' }}>
						<div className="m-3">
							{loadingCtrl}
						</div>
					</div>

					{/*  show any errors returned from api save */}
					<div style={{ display: data.loading ? 'none' : 'block' }}>
						{serverErrorCtrl}
					</div>

					<EditControlSet controls={controls} validate={validate} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={hideModal}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</span>
	);
};

export default ActivityEdit;
