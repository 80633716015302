import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { ICompetition, IMatch, ITeam, ILeague, IMatchFilter, actionCreators as matchActionCreators,
	emptyLeague, emptyCompetition, emptyTeam } from '../../store/match';
import * as AuthStore from '../../store/auth';
import { bindActionCreators } from "redux";
import { parseDateString, daysDiff } from '../../utils/dates';
import MatchFilterEdit from '../match/fnMatchFilterEdit';
import { IMatchListItem } from './fnMatchListRow';
import MatchListTable from './fnMatchListTable';

interface IMatchListProps {
	auth: AuthStore.IAuthState;
	matches: IMatch[];
	teams: ITeam[];
	competitions: ICompetition[];
	leagues: ILeague[];
	filter: IMatchFilter;
	actions: typeof matchActionCreators;
}

type TMatchListProps =
	IMatchListProps
	& RouteComponentProps<{}>;

const MatchList = (props: TMatchListProps) => {
	// if not filtering get default position;
	let matchFilter: IMatchFilter = {
		filtering: props.filter.filtering,
		status: props.filter.status,
		dated: props.filter.dated,
		competitions: props.filter.competitions
	};
	if (!matchFilter.filtering) {
		matchFilter.status = "I";
		matchFilter.dated = "F";
		matchFilter.competitions = [];
	}
	let today: Date = new Date();
	let matches: IMatchListItem[] = [];
	props.matches.forEach((match) => {
		let team: ITeam = emptyTeam;
		let teamIdx: number = props.teams.findIndex((tm) => { return tm.id === match.teamId; });
		if (teamIdx >= 0) {
			team = props.teams[teamIdx];
		}
		let competition: ICompetition = emptyCompetition;
		let competitionIdx: number = props.competitions.findIndex((cp) => { return cp.id === team.competitionId; });
		if (competitionIdx >= 0) {
			competition = props.competitions[competitionIdx];
		}
		let league: ILeague = emptyLeague;
		let leagueIndex: number = props.leagues.findIndex((lg) => { return lg.id === competition.leagueId; });
		if (leagueIndex >= 0) {
			league = props.leagues[leagueIndex];
		}
		// get match date
		let matchDate: Date = parseDateString(match.date);
		let past: boolean = false;
		if (daysDiff(matchDate, today) > 0) {
			past = true;
		}
		// check status
		if (matchFilter.status === "I" || (matchFilter.status === "O" && match.completed) || (matchFilter.status === "X" && !match.completed)) {
			if (matchFilter.competitions.length === 0 || matchFilter.competitions.indexOf(team.competitionId) >= 0) {
				if (matchFilter.dated === "A" || (matchFilter.dated === "P" && past) || (matchFilter.dated === "F" && !past)) {
					matches.push({ match: match, team: team, competition: competition, league: league });
				}
			}
		}
	});
	matches.sort((a: IMatchListItem, b: IMatchListItem) => {
		let dateA = parseDateString(a.match.date);
		let teamA = a.team.name;
		let dateB = parseDateString(b.match.date);
		let teamB = b.team.name
		if (dateA < dateB) {
			return -1;
		} else if (dateA > dateB) {
			return 1;
		} else if (teamA < teamB) {
			return -1;
		} else if (teamA > teamB) {
			return 1;
		} else {
			return 0;
		}
	});

	const onFilterSaved = (filter: IMatchFilter) => {
		props.actions.matchFilterSet(filter);
	};

	const onDrillDown = (focusId: number) => {
		let matchIds: number[] = matches.map((item) => { return item.match.id; });
		props.history.push("/match/details/" + focusId.toString(), { "ids": matchIds });
	};

	return (
		<div className="container body-content">
			<MatchFilterEdit title="Match Filter" filter={props.filter} competitions={props.competitions} onFilterSaved={onFilterSaved} />
			<MatchListTable items={matches} onDrillDown={onDrillDown} />
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	matches: state.matches.data.matches,
	teams: state.matches.data.teams,
	competitions: state.matches.data.competitions,
	leagues: state.matches.data.leagues,
	filter: state.matches.matchFilter
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(matchActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps,
)(MatchList) as any;
