import React from 'react';
import { Button } from 'reactstrap';

export interface ITeamPlayer {
	id: number;
	name: string;
	matches: number;
	rubbersFor: number;
	rubbersPlayed: number;
}

interface ITeamPlayerRowProps {
	player: ITeamPlayer;
	onDrillDown: (focusId: number) => void;
}

const TeamPlayerRow = (props: ITeamPlayerRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.player.id);
	};

	let rubbers;
	if (props.player.rubbersPlayed > 0) {
		rubbers = props.player.rubbersFor.toString() + " / " + props.player.rubbersPlayed.toString();
	} else {
		rubbers = "-";
	}

	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{props.player.name}</Button></td>
			<td className="text-center">{props.player.matches}</td>
			<td className="text-center">{rubbers}</td>
		</tr>
	);
};

export default TeamPlayerRow;
