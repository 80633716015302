import React from 'react';
import { IAuthState } from '../../store/auth';
import { ITeam, ITeamFetchResult, emptyTeam } from '../../store/match';
import { IPlayer } from '../../store/player';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button, Alert } from 'reactstrap';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import Loading, { ELoadingSize } from '../controls/spinners/fnLoading';
import { EApiResultType, ApiCheckResponseForError } from '../api';
import { AppConfig, IAppConfig } from '../../config/appConfig';
let config: IAppConfig = AppConfig();

interface ITeamEditProps {
	teamId: number;
	title: string;
	auth: IAuthState;
	captains: IPlayer[];
	onTeamSaved: (team: ITeam) => void;
	online: boolean;
}

interface ITeamEditState {
	team: ITeam;
	loading: boolean;
	changesMade: boolean;
}

const TeamEdit = (props: ITeamEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const [validate, setValidate] = React.useState(false);
	const [serverError, setServerError] = React.useState("");
	const [data, setData] = React.useState({
		team: emptyTeam,
		loading: false,
		changesMade: false
	} as ITeamEditState);

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				team: {
					...currData.team,
					[name]: value
				},
				changesMade: true 
			};
		});
	};

	const onTeamChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "captainId") {
			let newId = parseInt(value)
			setRecordValue(name, newId);
		}	else {
			setRecordValue(name, value);
		}
	};

	const fetchRecord = () => {
		if (props.auth.user && props.teamId > 0) {
			// set loading flag
			setData(currData => { return {...currData, loading: true}; });
			// request data from server
			fetch(`${config.apiUrl}/team/details?teamId=${props.teamId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + props.auth.user.accessToken,
					"Pragma": "no-cache"
				},
			})
				.then(response => response.json() as Promise<ITeamFetchResult>)
				.then(recordFetched)
				.catch((error) => {
					setServerError(error.message);
				});			
		} else {
			setServerError("not authorised");
		}
	};

	const recordFetched = (result: ITeamFetchResult) => {
		switch (result.result.resultType) {
			case EApiResultType.OK:
				setData({ team: result.data, loading: false, changesMade: false});
				break;
			case EApiResultType.Error:
				setServerError(result.result.errorMessage);
				break;
			default:
				setServerError("Team record not found");
				break;
		}
	};

	const recordSaved = (result: ITeamFetchResult) => {
		if (result.result.resultType === EApiResultType.OK) {
			props.onTeamSaved(result.data);
			hideModal();
		} else {
			setServerError(result.result.errorMessage);
		}
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			if (props.auth.user) {
				fetch(`${config.apiUrl}/team`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
					body: JSON.stringify(data.team)
				})
				.then(Response => {
					ApiCheckResponseForError(Response);
					return Response.json() as Promise<ITeamFetchResult>;
				})
				.then(recordSaved)
				.catch((error) => {
					setServerError(error.message);
				});
			}
		} else {
			setValidate(true);
		}
	};

	const showModal = () => {
		fetchRecord();
		setShowing(true);
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		setShowing(!showing);
	};

	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		switch (control.icon) {
			case "captainId":
				if (data.team.captainId === 0) {
					errorMsg = "Please select a team captain";
				}
				break;
			default:
				break;
		}
		return errorMsg;
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	let controls: IEditControl[] = [];
	// captain
	let captainOptions: any[] = [];
	captainOptions.push(<option value="0" key={0} />);
	props.captains.forEach((pl) => {
		captainOptions.push(<option value={pl.id} key={pl.id} > {pl.firstName + " " + pl.surname}</option>);
	});
	controls.push({
		id: "captainId",
		label: "Captain:",
		isCustom: true,
		inputType: "select",
		value: data.team.captainId,
		onChange: onTeamChange,
		onValidate: onValidate,
		children: captainOptions
	});

	let serverErrorCtrl;
	if (serverError !== "") {
		serverErrorCtrl = (
			<Alert color="danger">
				{serverError}
			</Alert>
		);
	}

	let loadingCtrl;
	if (data.loading) {
		if (serverErrorCtrl) {
			loadingCtrl = serverErrorCtrl;
		} else {
			// loading spinner and message will be displayed if loading takes more than 300 milliseconds
			loadingCtrl = <Loading message="fetching data from server" size={ELoadingSize.medium} delayed={true} delayInMs={300} />;
		}
	}

	return (
		<div>
			<span>
				<Button color="secondary" onClick={showModal} disabled={!props.online}>
					<i className="fa fa-edit" /> Edit
				</Button>
			</span>
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"} size="lg">
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody style={{ overflowY: data.loading ? 'hidden' : 'auto'}}>

					{/*  use overlay to hide edit controls until data is fetched from api */}
					<div className="modal-body-overlay" style={{ display: data.loading ? 'block' : 'none' }}>
						<div className="m-3">
							{loadingCtrl}
						</div>
					</div>

					{/*  show any errors returned from api save */}
					<div style={{ display: data.loading ? 'none' : 'block' }}>
						{serverErrorCtrl}
					</div>

					<EditControlSet controls={controls} validate={validate} />

				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={hideModal}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default TeamEdit;