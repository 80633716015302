import React from "react";
import { getDateParts, getNewDate } from '../../../utils/dates';
import { CustomInput } from 'reactstrap';

export enum ETimePickerMinuteSlots {
	fifteen,
	ten,
	five,
	one
}

interface ITimePickerProps {
	date: Date;
	onTimeChange: (date: Date) => void;
	minuteSlots?: ETimePickerMinuteSlots;
}

export default React.memo(
(props: ITimePickerProps) => {
	let date: Date = props.date;
	let hour: number = getDateParts(props.date).hour;
	let minutes: number = 0;
	let increment: number = 1;
	if (props.minuteSlots) {
		switch (props.minuteSlots.valueOf()) {
			case ETimePickerMinuteSlots.fifteen:
				increment = 15;
				break;
			case ETimePickerMinuteSlots.ten:
				increment = 10;
				break;
			case ETimePickerMinuteSlots.five:
				increment = 5;
				break;
			default:
				increment = 1;
				break;
		}
		minutes = Math.floor(date.getMinutes() / increment) * increment;
	}

	let minuteOptions: any[] = [];
	let minuteOption: number = 0;
	while (minuteOption < 60) {
		let minuteStr: string = minuteOption.toString();
		if (minuteOption < 10) {
			minuteStr = "0" + minuteStr;
		}
		minuteOptions.push(<option value={minuteOption} key={minuteOption}>{minuteStr}</option>);
		switch (props.minuteSlots) {
			case ETimePickerMinuteSlots.fifteen:
				minuteOption += 15;
				break;
			case ETimePickerMinuteSlots.ten:
				minuteOption += 10;
				break;
			case ETimePickerMinuteSlots.five:
				minuteOption += 5;
				break;
			default:
				minuteOption += 1;
				break;
		}
	}

	const onHourChange = (e: any) => {
		hour = e.currentTarget.value;
		date = getNewDate(props.date.getFullYear(), props.date.getMonth() + 1, props.date.getDate(), hour, minutes, 0);
		//setState({ hour: value, minute: state.minute, date: date });
		props.onTimeChange(date);
	};

	const onMinuteChange = (e: any) => {
		minutes = e.currentTarget.value;
		date = getNewDate(props.date.getFullYear(), props.date.getMonth() + 1, props.date.getDate(), hour, minutes, 0);
		//setState({ hour: state.hour, minute: value, date: date });
		props.onTimeChange(date);
	};

	return (
		<table>
			<tbody>
				<tr>
					<td
						style={{
							minWidth: 65
						}}
					>
						<CustomInput
							id="hourdd"
							type="select"
							value={hour}
							onChange={onHourChange}
						>
							<option value="0">00</option>
							<option value="1">01</option>
							<option value="2">02</option>
							<option value="3">03</option>
							<option value="4">04</option>
							<option value="5">05</option>
							<option value="6">06</option>
							<option value="7">07</option>
							<option value="8">08</option>
							<option value="9">09</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
						</CustomInput>
					</td>
					<td style={{ width: 10, textAlign: "center" }}>
						{" : "}
					</td>
					<td
						style={{
							minWidth: 65
						}}
					>
						<CustomInput id="minutedd" type="select" value={minutes} onChange={onMinuteChange} >
							{minuteOptions}
						</CustomInput>
					</td>
				</tr>
			</tbody>
		</table>
	);
},
(prevProps: ITimePickerProps, nextProps: ITimePickerProps) => {
	let same: boolean = false;
	same = prevProps.date.getTime() === nextProps.date.getTime();
	return same;
});