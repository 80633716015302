import React from 'react';
import MatchRow, { IMatchWidgetMatch } from './fnMatchRow';

interface IMatchTableProps {
	matches: IMatchWidgetMatch[];
	onDrillDown: (focusId: number) => void;
	hideRubbers?: boolean;
	emptyMessage?: string;
	sortDescending?: boolean;
}

const MatchTable = (props: IMatchTableProps) => {
	let rubbersFor: number = 0;
	let rubbersPlayed: number = 0;
	let matches = props.matches;
	matches.sort((a: IMatchWidgetMatch, b: IMatchWidgetMatch) => {
		if (a.date < b.date) {
			if (props.sortDescending) {
				return 1;
			} else {
				return -1;
			}
		} else if (a.date > b.date) {
			if (props.sortDescending) {
				return -1;
			} else {
				return 1;
			}
		} else if (a.team < b.team) {
			return -1;
		} else if (a.team > b.team) {
			return 1;
		} else {
			return 0;
		}
	});
	var rows: any[] = [];
	if (matches && matches.length > 0) {
		matches.forEach((match) => {
			rows.push(
				<MatchRow match={match} key={match.id} onDrillDown={props.onDrillDown} hideRubbers={props.hideRubbers}/>
			);
			if (match.rubbersFor) {
				rubbersFor = rubbersFor + match.rubbersFor;
				rubbersPlayed = rubbersPlayed + match.rubbersFor;
			}
			if (match.rubbersAgainst) {
				rubbersPlayed = rubbersPlayed + match.rubbersAgainst;
			}
		});
	}
	let footer;
	if (!props.hideRubbers && rubbersPlayed) {
		footer = (
			<tfoot>
				<tr>
					<td />
					<td />
					<td />
					<td />
					<td className="text-center">
						{rubbersFor + " / " + rubbersPlayed}
					</td>
				</tr>
			</tfoot>
		);
	}

	if (rows.length > 0) {
		let rubbersHeader;
		if (!props.hideRubbers) {
			rubbersHeader = <th className="text-center">Rubbers</th>;
		}

		return (
			<table className="table table-striped table-sm">
				<thead>
					<tr>
						<th>Date</th>
						<th/>
						<th>Team</th>
						<th>Opposition</th>
						{rubbersHeader}
					</tr>
				</thead>
				{footer}
				<tbody>{rows}</tbody>
			</table>
		);
	} else if (props.emptyMessage) {
		return (
			<div>
				<p>
					{props.emptyMessage}
				</p>
			</div>
		);
	} else {
		return null;
	}
};

export default MatchTable;