import { applyMiddleware, combineReducers, compose, createStore, StoreEnhancer, Store, StoreEnhancerStoreCreator, ReducersMapObject } from 'redux';
import thunk from 'redux-thunk';
import { IApplicationState, reducers } from './';
import { createLogger } from 'redux-logger';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import * as storage from 'redux-storage';
import { reducer as storageMergeReducer } from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import debounce from 'redux-storage-decorator-debounce';
import filter from 'redux-storage-decorator-filter';

//export default function configureStore(history: History, initialState?: IApplicationState) {
export default function configureStore(initialState?: IApplicationState) {
	const windowIfDefined = typeof window === 'undefined' ? null : window as any;
	// In development, use the browser's Redux dev tools extension if installed
	//const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension as () => GenericStoreEnhancer;
	const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension as () => StoreEnhancer<any>;
	// Setup
	const middleWare: any = [];
	// Immutability Check
	if (process.env.NODE_ENV === 'development') {
		middleWare.push(immutableCheckMiddleWare());
	}
	// local storage
	let engine = createEngine('TeamPlayerCacheV2');
	engine = debounce(engine, 1000);
	// define which parts of our store will be cached locally
	engine = filter(engine, ['library', 'matches', 'players', 'availability', 'selection', 'result'], ['auth', 'storage']);
	const reduxStorageMiddleware = storage.createMiddleware(engine);
	middleWare.push(reduxStorageMiddleware);
	const loadStore = storage.createLoader(engine);
	// Logger Middleware. This always has to be last
	const loggerMiddleware = createLogger({
		predicate: () => process.env.NODE_ENV === 'development',
	});
	middleWare.push(loggerMiddleware);

	const createStoreWithMiddleware = compose(
		applyMiddleware(thunk, ...middleWare),
		devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
	)(createStore);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = storageMergeReducer(buildRootReducer(reducers));
	let appState = (createStoreWithMiddleware as any)(allReducers, initialState) as Store<IApplicationState>;
	loadStore(appState);
	return appState;
}

function buildRootReducer(allReducers: ReducersMapObject) {
	return combineReducers<IApplicationState>(Object.assign({}, allReducers ));
}
