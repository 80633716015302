import * as React from 'react';
import * as AvailabilityStore from '../../store/availability';
import { CustomInput } from 'reactstrap';

export interface IAvailabilityWidgetPlayer {
	id: number;
	firstName: string;
	surname: string;
	sex: string;
	status: AvailabilityStore.EAvailabilityStatus;
	readOnly: boolean;
	registered: boolean;
	email: string;
	mobile: string;
	minTeam: number;
}

interface IAvailabiityPlayerRowProps {
	activityId: number;
	data: IAvailabilityWidgetPlayer;
	callback: (av: AvailabilityStore.IAvailability) => void;
}

const AvailabilityPlayerRow = (props: IAvailabiityPlayerRowProps) => {
	const [status, setStatus] = React.useState(props.data.status);

	//const onAvailabilityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
	const onAvailabilityChange = (e: any) => {
		var availability: AvailabilityStore.IAvailability = {
			activityId: props.activityId,
			playerId: props.data.id,
			status: e.currentTarget.selectedIndex,
			notes: ""
		};
		props.callback(availability);
		setStatus(e.currentTarget.selectedIndex);
	};

	let rowClass = "";
	if (status === AvailabilityStore.EAvailabilityStatus.Pending) {
		rowClass = "text-danger";
	}
	let playerName: string = props.data.firstName + ' ' + props.data.surname;
	if (!props.data.registered) {
		playerName = playerName + " (N/R)";
	}
	if (props.data.minTeam > 0) {
		playerName = playerName + " (min team: " + props.data.minTeam.toString() + ")";
	}
	return (
		<tr>
			<td className={rowClass} style={{ paddingTop: 10 }}>{playerName}</td>
			<td>
				<CustomInput id="stdd" type="select" value={status} onChange={onAvailabilityChange} disabled={props.data.readOnly}>
					<option value={AvailabilityStore.EAvailabilityStatus.Available.toString()}>Yes</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Unavailable.toString()}>No</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Maybe.toString()}>Maybe</option>
					<option value={AvailabilityStore.EAvailabilityStatus.Pending.toString()}>?</option>
				</CustomInput>
			</td>
		</tr>
	);
};

export default AvailabilityPlayerRow;
