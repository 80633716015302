import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button } from 'reactstrap';
import { IMatchData, ITeam, emptyTeam, ICompetition, emptyCompetition, ILeague, emptyLeague } from '../../store/match';
import { formatDateString, EDateFormat } from '../../utils/dates';
import ActivityMatchRow, { IActivityMatch } from './fnActivityMatchRow';

interface IActivityMatchesProps {
	matchData: IMatchData;
	activityId?: number;
	matchId?: number;
	showing: boolean;
	onClose: () => void;
}

const ActivityMatches = (props: IActivityMatchesProps) => {
	const [showing, setShowing] = React.useState(props.showing);

	React.useEffect(
		() => {
			setShowing(props.showing);
		},
		[props.showing]
	);

	let matches: IActivityMatch[] = [];
	props.matchData.matches.filter((ma) => {
		let includeMatch: boolean = false;
		if (props.activityId) {
			includeMatch = ma.activityId === props.activityId;
		} else if (props.matchId) {
			includeMatch = ma.id === props.matchId;
		}
		return includeMatch;
	}).forEach((ma) => {
		// get team
		let team: ITeam = emptyTeam;
		let teamIndex: number = props.matchData.teams.findIndex((tm) => { return tm.id === ma.teamId; });
		if (teamIndex >= 0) {
			team = props.matchData.teams[teamIndex];
		}
		// get competition
		let competition: ICompetition = emptyCompetition;
		let competitionIndex: number = props.matchData.competitions.findIndex((cp) => { return cp.id === team.competitionId; });
		if (competitionIndex >= 0) {
			competition = props.matchData.competitions[competitionIndex];
		}
		// get league
		let league: ILeague = emptyLeague;
		let leagueIndex: number = props.matchData.leagues.findIndex((lg) => { return lg.id === competition.leagueId; });
		if (leagueIndex >= 0) {
			league = props.matchData.leagues[leagueIndex];
		}
		// add item to list
		matches.push ({
			id: ma.id,
			when: ma.date,
			venue: ma.venue,
			league: league.name,
			competition: competition.name,
			division: team.division,
			teamNo: team.teamNo,
			team: team.name,
			opposition: ma.opposition
		});
	});

	matches.sort((a: IActivityMatch, b: IActivityMatch) => {
		if (a.teamNo < b.teamNo) {
			return -1;
		} else if (a.teamNo > b.teamNo) {
			return 1;
		} else if (a.team < b.team) {
			return -1;
		} else if (a.team > b.team) {
			return 1;
		} else {
			return 0;
		}
	});

	const hideModal = () => {
		setShowing(false);
		props.onClose();
	};

	const toggle = () => {
		if (showing) {
			props.onClose();
		}
		setShowing(!showing);
	};

	let rows: any[] = [];
	matches.forEach((item) => {
		rows.push (
			<ActivityMatchRow match={item} key={item.id} />
		);
	});
	if (rows.length > 0) {
		// assume all same league and competition
		let title: string = "Matches";
		let league: string = "";
		let competition: string = "";
		if (matches.length > 0) {
			title = formatDateString(matches[0].when, EDateFormat.dateShort);
			league = matches[0].league;
			competition = matches[0].competition;
		}

		const modal = (
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"} size="lg">
				<ModalHeader toggle={toggle}>
					{title}
				</ModalHeader>
				<ModalBody>
					<div className="form-group">
						<div
							style={{
								fontSize: 16,
								marginBottom: 4
							}}
						>
							{league}
						</div>
						<div
							style={{
								fontSize: 16,
								marginBottom: 4
							}}
						>
							{competition}
						</div>
						{rows}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={hideModal}>Close</Button>					
				</ModalFooter>
			</Modal>
		);
		return <div>{modal}</div>;
	} else {
		return null;
	}
};

const mapStateToProps = (state: IApplicationState) => ({
	matchData: state.matches.data
});

export default connect(mapStateToProps)(ActivityMatches);