import React from 'react';
import { IPlayer } from '../../store/player';
import PlayerListRow from './fnPlayerListRow';

interface IPlayerListTableProps {
	players: IPlayer[];
	isTeamCaptain: boolean;
	onDrillDown: (focusId: number) => void;
}

const PlayerListTable = (props: IPlayerListTableProps) => {
	var rows: any[] = [];
	let playerEmails: string = "";
	if (props.players !== null && props.players.length > 0) {
		props.players.forEach((player) => {
			rows.push(
				<PlayerListRow player={player} key={player.id} onDrillDown={props.onDrillDown} />
			);
			// update emails list	
			if (player.email !== "" && player.email !== "n/a") {
				if (playerEmails !== "") {
					playerEmails = playerEmails + ',';
				}
				playerEmails = playerEmails + player.email;
			}
		});
	}
	let email;
	if (props.isTeamCaptain && playerEmails !== "") {
		email = <a href={"mailto:" + playerEmails} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-envelope" style={{ fontSize: "30px", color: "gold" }} /></a>;
	}
	return (
		<div className="table-responsive" style={{ borderWidth: 0 }}>
			<table className="table table-striped table-sm mt-2">
				<thead>
					<tr>
						<th>Name</th>
						<th className="text-center">Profile</th>
						<th>Email</th>
						<th>Mobile</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
				<tfoot>
					<tr>
						<td>
							{email}
						</td>
						<td />
						<td />
						<td />
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default PlayerListTable;