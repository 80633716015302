// redirects unknown or callback urls to the profile page
import { withRouter, RouteComponentProps } from 'react-router-dom';

type TCallbackProps = RouteComponentProps<{}>; // ... plus incoming routing parameters

const Callback = (props: TCallbackProps) => {
	let urlEnd = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
	if (!urlEnd.startsWith("renew")) {
		props.history.push("/profile");
	}
	return false;
};

export default withRouter((Callback) as any);