import React from 'react';
import { IPlayer } from '../../store/player';
import { Button } from 'reactstrap';

interface IPlayerListRowProps {
	player: IPlayer;
	onDrillDown: (focusId: number) => void;
}

const PlayerListRow = (props: IPlayerListRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		let playerId: number = props.player.id;
		props.onDrillDown(playerId);
	};

	const player = props.player;
	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{player.firstName + " " + player.surname}</Button></td>
			{/* <td className="text-center">{player.itfSinglesRating}</td> */}
			<td className="text-center">
				<a
					href={"https://competitions.lta.org.uk/player-profile/" + props.player.profileRef}
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fa fa-id-badge fa-lg" style={{ marginRight: "5px" }} />
				</a>
			</td>
			<td>{player.email}</td>
			<td>{player.mobile}</td>
		</tr>
	);
};

export default PlayerListRow;
