import React, { useEffect, useState } from 'react';
import * as SelectionStore from '../../store/selection';
import WrapperWidget from './fnWrapperWidget';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';
import SelectionPlayerTable from './fnSelectionPlayerTable';
import { ISelectionWidgetPlayer } from './fnSelectionPlayerRow';

interface ISelectionWidgetProps {
	title: any;
	controlName: string;
	matchId: number;
	isMixed: boolean;
	required: number;
	max: number;
	players: ISelectionWidgetPlayer[];
	available: ISelectionWidgetPlayer[];
	callback: (remove: SelectionStore.ISelection, add: SelectionStore.ISelection) => void;
}

const SelectionWidget = (props: ISelectionWidgetProps) => {
	const [players, setPlayers] = useState(props.players);

	useEffect(
		() => {
			setPlayers(props.players);
		},
		[props.players]
	);

	const selectionCallback = (remove: SelectionStore.ISelection, add: SelectionStore.ISelection) => {
		let convPlayers: ISelectionWidgetPlayer[] = players.filter((pl: ISelectionWidgetPlayer) => { return pl.id !== remove.playerId; });
		if (add.playerId > 0) {
			let playerIndex = props.available.findIndex((av) => {
				return av.id === add.playerId;
			});
			if (playerIndex >= 0) {
				convPlayers.push({
					id: add.playerId,
					name: props.available[playerIndex].name,
					sex: props.available[playerIndex].sex,
					index: add.index,
					locked: add.locked,
					confirmed: add.confirmed,
					minTeam: props.available[playerIndex].minTeam
				});
			}
		}
		setPlayers(convPlayers);
		props.callback(remove, add);
	};

	const checkLockedCount = (inPlayers: ISelectionWidgetPlayer[]): number => {
		let cnt: number = 0;
		players.forEach((pl: ISelectionWidgetPlayer) => {
			if (pl.locked) {
				cnt += 1;
			}
		});
		return cnt;
	};

	let panelClass = "danger";
	if (players.length >= props.required) {
		let lockedCnt: number = checkLockedCount(players);
		if (lockedCnt >= props.required) {
			panelClass = "success";
		}
	}

	var badges: IWrapperWidgetBadge[] = [
		{ name: "", value: players.length.toString() },
		{ name: " of", value: props.required.toString() },
	];

	return (
		<WrapperWidget title={props.title} controlName={props.controlName} badges={badges} panelClass={panelClass} >
			<SelectionPlayerTable
				matchId={props.matchId}
				isMixed={props.isMixed}
				required={props.required}
				max={props.max}
				players={players}
				available={props.available}
				callback={selectionCallback}
			/>
		</WrapperWidget>
	);
};

export default SelectionWidget;