import React from 'react';
import { IAuthState } from '../../store/auth';
import { IPlayerRecord, emptyPlayer, IPlayerFetchResult } from '../../store/player';
import { ILibraryTag, ILibraryRole } from '../../store/library';
import { IChecklistItem } from '../controls/modal/fnChecklistBoxItem';
import { ChecklistBoxSelectionAsString } from '../controls/modal/fnChecklistBox';
import Loading, {ELoadingSize } from '../controls/spinners/fnLoading';
import { EApiResultType, ApiCheckResponseForError } from '../api';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button, Alert } from 'reactstrap';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import { AppConfig, IAppConfig } from '../../config/appConfig';
let config: IAppConfig = AppConfig();

interface IPlayerEditProps {
	playerId: number;
	clubId: number;
	title: string;
	auth: IAuthState;
	tags: ILibraryTag[];
	roles: ILibraryRole[];
	onPlayerSaved: (player: IPlayerRecord) => void;
	online: boolean;
}

interface IPlayerEditState {
	record: IPlayerRecord;
	selectedRoles: IChecklistItem[];
	selectedTags: IChecklistItem[];
	loading: boolean;
	changesMade: boolean;
}

const PlayerEdit = (props: IPlayerEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const [validate, setValidate] = React.useState(false);
	const [serverError, setServerError] = React.useState("");
	const [data, setData] = React.useState({
		record: { player: emptyPlayer, roles: [], tags: [], thisIsMe: false },
		selectedRoles: props.roles.map((role) => { return { text: role.name, value: role.id.toString(), checked: false }; }),
		selectedTags: props.tags.map((tag) => { return { text: tag.tag, value: tag.id.toString(), checked: false }; }),
		loading: false,
		changesMade: false
	} as IPlayerEditState);

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				record: {
					...currData.record,
					player: {
						...currData.record.player,
						clubId: props.clubId,
						[name]: value
					}
				},
				changesMade: true 
			};
		});
	};

	const onPlayerChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "archived" || name === "isJunior") {
			value = e.currentTarget.checked;
			setRecordValue(name, e.currentTarget.checked);
		} else {
			setRecordValue(name, value);
		}
	};

	const onRolesUpdated = (items: IChecklistItem[]) => {
		let roleIds: number[] = [];
		items.forEach((item) => {
			if (item.checked) {
				roleIds.push(parseInt(item.value));
			}
		});
		setData(currData => {
			return {
				...currData,
				selectedRoles: items,
				record: {
					...currData.record,
					roles: roleIds
				},
				changesMade: true 
			};
		});
	};

	const onTagsUpdated = (items: IChecklistItem[]) => {
		let tagIds: number[] = [];
		items.forEach((item) => {
			if (item.checked) {
				tagIds.push(parseInt(item.value));
			}
		});
		setData(currData => {
			return {
				...currData,
				selectedTags: items,
				record: {
					...currData.record,
					tags: tagIds
				},
				changesMade: true 
			};
		});
	};

	const fetchRecord = () => {
		if (props.auth.user) {
			if (props.playerId > 0) {
				// set loading flag
				setData(currData => { return {...currData, loading: true}; });
				// request data from server
				fetch(`${config.apiUrl}/player/details?playerId=${props.playerId }`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
				})
					.then(response => response.json() as Promise<IPlayerFetchResult>)
					.then(recordFetched)
					.catch((error) => {
						setServerError(error.message);
					});
			}
		} else {
			setServerError("not authorised");
		}
	};

	const recordFetched = (result: IPlayerFetchResult) => {
		switch (result.result.resultType) {
			case EApiResultType.OK:
				// format tags for editing
				var tags: IChecklistItem[] = [];
				props.tags.forEach((tag) => {
					var cli: IChecklistItem = {
						text: tag.tag, value: tag.id.toString(), checked: result.data.tags.findIndex((pt) => { return pt.toString() === tag.id.toString(); }) >= 0
					};
					tags.push(cli);
				});
				// format roles for editing
				var roles: IChecklistItem[] = [];
				props.roles.forEach((role) => {
					var cli: IChecklistItem = {
						text: role.name, value: role.id.toString(), checked: result.data.roles.findIndex((pr) => { return pr === role.id; }) >= 0
					};
					roles.push(cli);
				});
				setData({
					record: result.data,
					selectedRoles: roles,
					selectedTags: tags,
					loading: false,
					changesMade: false
				});
				//setLoading(false);
				break;
			case EApiResultType.Error:
				setServerError(result.result.errorMessage);
				break;
			default:
				setServerError("Player record not found");
				break;
		}
	};

	const recordSaved = (result: IPlayerFetchResult) => {
		if (result.result.resultType === EApiResultType.OK) {
			props.onPlayerSaved(result.data);
			hideModal();
		} else {
			setServerError(result.result.errorMessage);
		}
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			if (props.auth.user) {
				fetch(`${config.apiUrl}/player`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
					body: JSON.stringify({ player: data.record.player, roles: data.record.roles, tags: data.record.tags }),
				})
				.then(Response => {
					ApiCheckResponseForError(Response);
					return Response.json() as Promise<IPlayerFetchResult>;
				})
				.then(recordSaved)
				.catch((error) => {
					setServerError(error.message);
				});
			}
		} else {
			setValidate(true);
		}
	};

	const showModal = () => {
		fetchRecord();
		setShowing(true);
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		setShowing(!showing);
	};

	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		switch (control.id) {
			case "firstName":
				if (data.record.player.firstName === "") {
					errorMsg = "Player first name cannot be left blank";
				}
				break;
			case "surname":
				if (data.record.player.surname === "") {
					errorMsg = "Player surname cannot be left blank";
				}
				break;
			case "email":
				if (data.record.player.email === "") {
					errorMsg = "Player email cannot be left blank";
				}
				break;
			case "roles":
				if (data.selectedRoles.findIndex((role) => { return role.checked === true; }) < 0) {
					errorMsg = "Player must have at least 1 role";
				}
				break;
			case "guardianEmail":
				if (data.record.player.isJunior && data.record.player.guardianEmail.indexOf("@") < 0) {
					errorMsg = "A guardian email address must br provied for juniors";
				}
				break;
			default:
				break;
		}
		return errorMsg;
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	let controls: IEditControl[] = [];
	// first name
	controls.push({
		id: "firstName",
		label: "First Name:",
		isCustom: false,
		inputType: "text",
		maxLength: 100,
		value: data.record.player.firstName,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// surname
	controls.push({
		id: "surname",
		label: "Surname:",
		isCustom: false,
		inputType: "text",
		maxLength: 100,
		value: data.record.player.surname,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// sex
	let sexOptions: any[] = [];
	sexOptions.push(<option value="M" key="1">Male</option>);
	sexOptions.push(<option value="F" key="2">Female</option>);
	controls.push({
		id: "sex",
		label: "Gender:",
		isCustom: true,
		inputType: "select",
		maxLength: 0,
		value: data.record.player.sex,
		onChange: onPlayerChange,
		onValidate: onValidate,
		children: sexOptions
	});
	// email
	controls.push({
		id: "email",
		label: "Email:",
		isCustom: false,
		inputType: "text",
		maxLength: 100,
		value: data.record.player.email,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// mobile
	controls.push({
		id: "mobile",
		label: "Mobile:",
		isCustom: false,
		inputType: "text",
		maxLength: 40,
		value: data.record.player.mobile,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// btm
	controls.push({
		id: "ltaNumber",
		label: "BTM:",
		isCustom: false,
		inputType: "text",
		maxLength: 20,
		value: data.record.player.ltaNumber,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// profile ref
	controls.push({
		id: "profileRef",
		label: "Profile Ref:",
		isCustom: false,
		inputType: "text",
		maxLength: 50,
		value: data.record.player.profileRef,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// rating
	let ratingOptions: any[] = [];
	ratingOptions.push(<option key="1">10.2</option>);
	ratingOptions.push(<option key="2">10.1</option>);
	ratingOptions.push(<option key="3">9.2</option>);
	ratingOptions.push(<option key="4">9.1</option>);
	ratingOptions.push(<option key="5">8.2</option>);
	ratingOptions.push(<option key="6">8.1</option>);
	ratingOptions.push(<option key="7">7.2</option>);
	ratingOptions.push(<option key="8">7.1</option>);
	ratingOptions.push(<option key="9">6.2</option>);
	ratingOptions.push(<option key="10">6.1</option>);
	ratingOptions.push(<option key="11">5.2</option>);
	ratingOptions.push(<option key="12">5.1</option>);
	ratingOptions.push(<option key="13">4.2</option>);
	ratingOptions.push(<option key="14">4.1</option>);
	ratingOptions.push(<option key="15">3.2</option>);
	ratingOptions.push(<option key="16">3.1</option>);
	ratingOptions.push(<option key="17">2.2</option>);
	ratingOptions.push(<option key="18">2.1</option>);
	ratingOptions.push(<option key="19">1.2</option>);
	ratingOptions.push(<option key="20">1.1</option>);
	controls.push({
		id: "ltaRating",
		label: "Rating:",
		isCustom: true,
		inputType: "select",
		maxLength: 0,
		value: data.record.player.ltaRating,
		onChange: onPlayerChange,
		onValidate: onValidate,
		children: ratingOptions
	});
	// singles rating
	controls.push({
		id: "itfSinglesRating",
		label: "ITF Singles:",
		isCustom: false,
		inputType: "text",
		maxLength: 10,
		value: data.record.player.itfSinglesRating,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// doubles rating
	controls.push({
		id: "itfDoublesRating",
		label: "ITF Doubles:",
		isCustom: false,
		inputType: "text",
		maxLength: 10,
		value: data.record.player.itfDoublesRating,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// junior
	let juniorControl: any[] = [];
	juniorControl.push(
		<label className="switch" key="1">
			<input type="checkbox" id="isJunior" checked={data.record.player.isJunior} onChange={e => onPlayerChange(e)} />
			<span className="slider round" />
		</label>
	);
	controls.push({
		id: "isJunior",
		label: "Is Junior:",
		isCustom: true,
		inputType: "",
		maxLength: 0,
		value: data.record.player.isJunior,
		onValidate: onValidate,
		children: juniorControl
	});
	// guardian email
	controls.push({
		id: "guardianEmail",
		label: "Guardian Email:",
		isCustom: false,
		inputType: "text",
		maxLength: 200,
		value: data.record.player.guardianEmail,
		onChange: onPlayerChange,
		onValidate: onValidate
	});
	// roles
	controls.push({
		id: "roles",
		label: "Roles:",
		title: "Player Roles",
		isCustom: true,
		inputType: "ChecklistBox",
		children: data.selectedRoles,
		value: ChecklistBoxSelectionAsString(data.selectedRoles),
		onValidate: onValidate,
		callback: onRolesUpdated
	});
	// tags
	controls.push({
		id: "tags",
		label: "Tags:",
		title: "Player Tags",
		isCustom: true,
		inputType: "ChecklistBox",
		children: data.selectedTags,
		value: ChecklistBoxSelectionAsString(data.selectedTags),
		callback: onTagsUpdated
	});
	// archived
	let archivedControl: any[] = [];
	archivedControl.push(
		<label className="switch" key="1">
			<input type="checkbox" id="archived" checked={data.record.player.archived} onChange={e => onPlayerChange(e)} />
			<span className="slider round" />
		</label>
	);
	controls.push({
		id: "archived",
		label: "Archived:",
		isCustom: true,
		inputType: "",
		maxLength: 0,
		value: data.record.player.archived,
		onValidate: onValidate,
		children: archivedControl
	});

	let serverErrorCtrl;
	if (serverError !== "") {
		serverErrorCtrl = (
			<Alert color="danger">
				{serverError}
			</Alert>
		);
	}
	let loadingCtrl;
	if (data.loading) {
		if (serverErrorCtrl) {
			loadingCtrl = serverErrorCtrl;
		} else {
			// loading spinner and message will be displayed if loading takes more than 300 milliseconds
			loadingCtrl = <Loading message="fetching data from server" size={ELoadingSize.medium} delayed={true} delayInMs={300} />;
		}
	}

	let modalButton;
	if (props.playerId > 0) {
		modalButton = (
			<span>
				<Button color="secondary" onClick={showModal} disabled={!props.online}>
					<i className="fa fa-edit" /> Edit
				</Button>
			</span>
		);
	} else {
		modalButton = (
			<Button color="secondary" onClick={showModal} disabled={!props.online}>
				<i className="fa fa-plus" /> Add Player
			</Button>
		);
	}

	return (
		<span>
			{modalButton}
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"} size="lg">
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody style={{ overflowY: data.loading ? 'hidden' : 'auto'}}>

					{/*  use overlay to hide edit controls until data is fetched from api */}
					<div className="modal-body-overlay" style={{ display: data.loading ? 'block' : 'none' }}>
						<div className="m-3">
							{loadingCtrl}
						</div>
					</div>

					{/*  show any errors returned from api save */}
					<div style={{ display: data.loading ? 'none' : 'block' }}>
						{serverErrorCtrl}
					</div>

					<EditControlSet controls={controls} validate={validate} />

				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={hideModal}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</span>
	);
};

export default PlayerEdit;