import { Action, Reducer } from 'redux';
import { IAppThunkAction } from './';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
import { nowAsString } from '../utils/dates';
let config: IAppConfig = AppConfig();

export interface IMatchesState {
	isLoading: boolean;
	data: IMatchData;
	matchFilter: IMatchFilter;
	teamFilter: ITeamFilter;
	activityFilter: IActivityFilter;
}

export interface IMatchData {
	version: number;
	leagues: ILeague[];
	competitions: ICompetition[];
	teams: ITeam[];
	activities: IActivity[];
	matches: IMatch[];
}

interface IMatchDataFetchResult {
	result: api.IApiResult;
	data: IMatchData;
}

export interface ILeague {
	id: number;
	name: string;
	websiteRef: string;	
	prefix: string;
}

export interface ICompetition {
	id: number;
	name: string;
	sex: string;
	teamSize: number;
	maxTeamSize: number;
	teamTied: number;
	leagueId: number;
}

export interface ITeam {
	id: number;
	clubId: number;
	name: string;
	websiteRef: number;
	teamNo: number;
	competitionId: number;
	division: string;
	divisionWebsiteRef: number;
	captainId: number;
}

export interface ITeamFetchResult {
	result: api.IApiResult;
	data: ITeam;
}

export interface IActivity {
	id: number;
	type: string;
	competitionId: number;
	date: string;
	title: string;
	notes: string;
}

export interface IActivityRecord {
	activity: IActivity;
	matchIds: number[];
}

export interface IActivityFetchResult {
	result: api.IApiResult;
	data: IActivityRecord;
}

export interface IMatch {
	id: number;
	websiteRef: number;
	date: string;
	venue: string;
	teamId: number;
	opposition: string;
	location: string;
	completed: boolean;
	notes: string;
	activityId: number;
	locked: boolean;
	walkover: boolean;
	result: string;
	pointsFor: number;
	pointsAgainst: number;
	rubbersFor: number;
	rubbersAgainst: number;
	setsFor: number;
	setsAgainst: number;
	gamesFor: number;
	gamesAgainst: number;
}

export interface IMatchFetchResult {
	result: api.IApiResult;
	data: IMatch;
}

export interface IActivityMatch {
	id: number;
	date: string;
	title: string;
	selectedPlayers: number;
}

export interface IMatchFilter {
	filtering: boolean;
	competitions: number[];
	status: string;
	dated: string;
}

export interface IFilteredMatch {
	key: string;
	match: IMatch;
	team: ITeam;
	competition: ICompetition;
	league: ILeague;
	activityTitle?: string;
}

export interface ITeamFilter {
	filtering: boolean;
	competitions: number[];
}

export interface IActivityFilter {
	filtering: boolean;
	competitions: number[];
	status: string;
}

export const emptyLeague: ILeague = { id: 0, name: "", websiteRef: "", prefix: "" };
export const emptyCompetition: ICompetition = { id: 0, name: "", sex: "M", teamSize: 4, maxTeamSize: 4, teamTied: 0, leagueId: 0 };
export const emptyTeam: ITeam = { id: 0, clubId: 0, name: "", websiteRef: 0, teamNo: 0, competitionId: 0, division: "", divisionWebsiteRef: 0, captainId: 0 };
export const emptyMatch: IMatch = {
	id: 0,
	websiteRef: 0,
	date: nowAsString(),
	venue: "H",
	teamId: 0,
	opposition: "",
	location: "",
	completed: false,
	notes: "",
	activityId: 0,
	locked: false,
	walkover: false,
	result: "",
	pointsFor: 0,
	pointsAgainst: 0,
	rubbersFor: 0,
	rubbersAgainst: 0,
	setsFor: 0,
	setsAgainst: 0,
	gamesFor: 0,
	gamesAgainst: 0,
};
export const emptyActivity: IActivity = { id: 0, type: "Match", competitionId: 0, date: nowAsString(), title: "", notes: "" };
export const emptyActivityRecord: IActivityRecord = { activity: emptyActivity, matchIds: [] };
const emptyMatchFilter: IMatchFilter = { filtering: false, status: "X", dated: "F", competitions: [] };
const emptyTeamFilter: ITeamFilter = { filtering: false, competitions: [] };
const emptyActivityFilter: IActivityFilter = { filtering: false, status: "X", competitions: [] };

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestMatchData {
	type: 'REQUEST_MATCH_DATA';
}

interface IMatchDataLoaded {
	type: 'MATCH_DATA_LOADED';
	data: IMatchData;
}

interface IActivitySet {
	type: 'ACTIVITY_SET';
	activityRecord: IActivityRecord;
}

interface IActivityDeleted {
	type: 'ACTIVITY_DELETED';
	activityId: number;
}

interface ITeamSet {
	type: 'TEAM_SET';
	team: ITeam;
}

interface IMatchSet {
	type: 'MATCH_SET';
	match: IMatch;
}

interface IMatchFilterSet {
	type: 'MATCH_FILTER_SET';
	matchFilter: IMatchFilter;
}

interface ITeamFilterSet {
	type: 'TEAM_FILTER_SET';
	teamFilter: ITeamFilter;
}

interface IActivityFilterSet {
	type: 'ACTIVITY_FILTER_SET';
	activityFilter: IActivityFilter;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestMatchData | IMatchDataLoaded | IActivitySet | IMatchSet | IMatchFilterSet
	| ITeamFilterSet | IActivityFilterSet | IActivityDeleted | ITeamSet;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestMatchData: (accessToken: string, clubId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/match/list?clubId=${clubId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<IMatchDataFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'MATCH_DATA_LOADED', data: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Match data fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("Match data not found");
						break;
				}
			});
		dispatch({ type: 'REQUEST_MATCH_DATA' });
	},
	activityDelete: (accessToken: string, activityId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/activity?activityId=${activityId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<api.IApiResult>)
			.then(data => {
				switch (data.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'ACTIVITY_DELETED', activityId: activityId });
						break;
					case api.EApiResultType.Error:
						console.log("Failed to delete activity: " + data.errorMessage);
						break;
					default:
						console.log("Activity data not found");
						break;
				}
			});
	},
	activitySet: (activityRecord: IActivityRecord): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'ACTIVITY_SET', activityRecord: activityRecord });
	},
	matchSet: (match: IMatch): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'MATCH_SET', match: match });
	},
	teamSet: (team: ITeam): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'TEAM_SET', team: team });
	},
	matchFilterSet: (filter: IMatchFilter): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'MATCH_FILTER_SET', matchFilter: filter });
	},
	teamFilterSet: (filter: ITeamFilter): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'TEAM_FILTER_SET', teamFilter: filter });
	},
	activityFilterSet: (filter: IActivityFilter): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'ACTIVITY_FILTER_SET', activityFilter: filter });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IMatchesState = {
	isLoading: false, data: { version: 0, leagues: [], competitions: [], teams: [], activities: [], matches: [] }, matchFilter: emptyMatchFilter, teamFilter: emptyTeamFilter, activityFilter: emptyActivityFilter
};

export const reducer: Reducer<IMatchesState> = (state: IMatchesState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_MATCH_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'MATCH_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.data, isLoading: false });
		case 'ACTIVITY_SET':
			// activities
			let acRecordFound = false;
			let activities = state.data.activities.map((ac) => {
				if (ac.id === action.activityRecord.activity.id) {
					acRecordFound = true;
					return action.activityRecord.activity;
				} else {
					return ac;
				}
			});
			if (!acRecordFound) {
				activities.push(action.activityRecord.activity);
			}
			// linked matches
			let acMatches = state.data.matches.map((ma => {
				if (action.activityRecord.matchIds.indexOf(ma.id) >= 0 && ma.activityId !== action.activityRecord.activity.id) {
					return Object.assign({}, { ...ma }, { activityId: action.activityRecord.activity.id });
				} else if (ma.activityId === action.activityRecord.activity.id && action.activityRecord.matchIds.indexOf(ma.id) < 0) {
					return Object.assign({}, { ...ma }, { activityId: 0 });
				} else {
					return ma;
				}
			}));
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { activities: activities, matches: acMatches }) });
		case 'ACTIVITY_DELETED':
			let newacc = state.data.activities.filter((ac) => { return ac.id !== action.activityId; });
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { activities: newacc }) });
		case 'MATCH_SET':
			let maRecordFound = false;
			let matches = state.data.matches.map((ma) => {
				if (ma.id === action.match.id) {
					maRecordFound = true;
					return action.match;
				} else {
					return ma;
				}
			});
			if (!maRecordFound) {
				matches.push(action.match);
			}
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { matches: matches }) });
		case 'TEAM_SET':
			let tmRecordFound = false;
			let teams = state.data.teams.map((tm) => {
				if (tm.id === action.team.id) {
					tmRecordFound = true;
					return action.team;
				} else {
					return tm;
				}
			});
			if (!tmRecordFound) {
				teams.push(action.team);
			}
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { teams: teams }) });
		case 'MATCH_FILTER_SET':
			return Object.assign({}, { ...state }, { matchFilter: action.matchFilter });
		case 'TEAM_FILTER_SET':
			return Object.assign({}, { ...state }, { teamFilter: action.teamFilter });
		case 'ACTIVITY_FILTER_SET':
			return Object.assign({}, { ...state }, { activityFilter: action.activityFilter });
		default:
			return state;
	}

	// For unrecognized actions (or in cases where actions have no effect), must return the existing state
	//  (or default initial state if none was supplied)
	//return state || unloadedState;
};