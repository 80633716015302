import * as React from "react";
import DatePicker from './fnDatePicker';
import TimePicker, { ETimePickerMinuteSlots } from './fnTimePicker';

export interface IDateTimePickerProps {
	date: Date;
	onDateChange?: (date: Date) => void;
	minuteSlots?: ETimePickerMinuteSlots;
}

export default React.memo(
(props: IDateTimePickerProps) => {
	let date: Date = props.date;

	const onDateChange = (inDate: Date) => {
		date = inDate;
		if (props.onDateChange) {
			props.onDateChange(date);
		}
	};

	return (
		<table>
			<tbody>
				<tr>
					<td>
						<DatePicker date={date} onDateChange={onDateChange} maxWidth={160} />
					</td>
					<td style={{ width: 18, textAlign: "center" }}>
						{" / "}
					</td>
					<td>
						<TimePicker date={date} onTimeChange={onDateChange} minuteSlots={props.minuteSlots} />
					</td>
				</tr>
			</tbody>
		</table>
	);
},
(prevProps: IDateTimePickerProps, nextProps: IDateTimePickerProps) => {
	let same: boolean = false;
	same = prevProps.date.getTime() === nextProps.date.getTime();
	return same;
});