import { IAppThunkAction } from './';
import { Action, Reducer } from 'redux';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

export interface ILibraryState {
	isLoading: boolean;
	fetched?: Date;
	data: ILibraryData;
}

export interface ILibraryData {
	roles: ILibraryRole[];
	tags: ILibraryTag[];
}

export interface ILibraryRole {
	id: number;
	name: string;
}

export interface ILibraryTag {
	id: number;
	category: string;
	tag: string;
}

interface ILibraryDataFetchResult {
	result: api.IApiResult;
	data: ILibraryData;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestLibraryData {
	type: 'REQUEST_LIBRARY_DATA';
}

interface ILibraryDataLoaded {
	type: 'LIBRARY_DATA_LOADED';
	data: ILibraryData;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestLibraryData | ILibraryDataLoaded;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestLibraryData: (accessToken: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/library`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken,
				"Pragma": "no-cache"
			}
		})
			.then(response => response.json() as Promise<ILibraryDataFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'LIBRARY_DATA_LOADED', data: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Library data fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("library data not found");
						break;
				}
			});
		dispatch({ type: 'REQUEST_LIBRARY_DATA' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ILibraryState = { isLoading: false, data: { roles: [], tags: [] } };

export const reducer: Reducer<ILibraryState> = (state: ILibraryState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_LIBRARY_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'LIBRARY_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.data, isLoading: false });
		default:
			return state;
	}

	// For unrecognized actions (or in cases where actions have no effect), must return the existing state
	//  (or default initial state if none was supplied)
	//return state || unloadedState;
};