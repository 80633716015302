import React, { useState } from 'react';
import { Collapse, Card, CardHeader } from 'reactstrap';
import WrapperWidgetBadges from './fnWrapperWidgetBadges';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';

interface IWrapperWidgetProps {
	title: string;
	controlName: string;
	badges: IWrapperWidgetBadge[];
	panelClass: string;
	glyph?: string;
	openByDefault?: boolean;
	children?: any;
}

const WrapperWidget = React.memo(
(props: IWrapperWidgetProps) => {
	const [open, setOpen] = useState(props.openByDefault ? true : false);

	let badges;
	if (props.badges.length > 0) {
		badges = <WrapperWidgetBadges badges={props.badges} />;
	}

	let glyph;
	if (props.glyph) {
		glyph = <span className={props.glyph} />;
	}

	let cardColor: string = props.panelClass;
	let inverse: boolean = true;
	if (cardColor === "warning") {
		inverse = false;
	}

	return (
		<div>
			<Card inverse={inverse} color={cardColor} className="mb-3">
				<CardHeader style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
					<div>
						<div className="float-right">
							{badges}
						</div>
						<h5 style={{ display: 'inline' }}>
							{glyph} {props.title}
						</h5>
					</div>
				</CardHeader>
			</Card>
			<Collapse isOpen={open} className="mb-2">
				{props.children}
			</Collapse>
		</div>
	);
});

export default WrapperWidget;
