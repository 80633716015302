import React, { useState, useEffect } from 'react';
import SelectionPlayerRow, { ISelectionWidgetPlayer } from './fnSelectionPlayerRow';
import * as SelectionStore from '../../store/selection';

interface ISelectionPlayerTableProps {
	matchId: number;
	isMixed: boolean;
	required: number;
	max: number;
	players: ISelectionWidgetPlayer[];
	available: ISelectionWidgetPlayer[];
	callback: (remove: SelectionStore.ISelection, add: SelectionStore.ISelection) => void;
}

const SelectionPlayerTable = (props: ISelectionPlayerTableProps) => {
	const [players, setPlayers] = useState(props.players);

	useEffect(
		() => {
			setPlayers(props.players);
		},
		[props.players]
	);

	const findPlayerIndex = (indexNo: number): number => {
		return players.findIndex((pl) => { return pl.index === indexNo; });
	};

	var rows: any[] = [];
	let rowNo = 1;
	while (rowNo <= props.max) {
		let player: ISelectionWidgetPlayer;
		let playerIndex = findPlayerIndex(rowNo);
		if (playerIndex >= 0) {
			player = players[playerIndex];
		} else {
			player = { id: 0, name: "", sex: "X", index: rowNo, locked: false, confirmed: false, minTeam: 0 };
		}
		rows.push(
			<SelectionPlayerRow
				player={player}
				isMixed={props.isMixed}
				available={props.available}
				key={props.matchId * 100 + player.index}
				matchId={props.matchId}
				callback={props.callback}
			/>
		);
		rowNo += 1;
	}

	return (
		<table className="table table-striped table-sm">
			<thead>
				<tr>
					<th className="text-center" style={{ width: 25 }}>No.</th>
					<th>Player</th>
					<th className="text-center" style={{ width: 54 }}><i className="fa fa-lock" /></th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	);
};

export default SelectionPlayerTable;
