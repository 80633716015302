import React from 'react';
import PlayerStatsRow, { IPlayerStatsResults } from './fnPlayerStatsRow';

interface IPlayerStatsTableProps {
	stats: IPlayerStatsResults[];
	onDrillDown: (focusId: number) => void;
}

const PlayerStatsTable = (props: IPlayerStatsTableProps) => {
	var rows: any[] = [];
	if (props.stats !== null && props.stats.length > 0) {
		props.stats.forEach((results) => {
			rows.push(
				<PlayerStatsRow results={results} key={results.id} onDrillDown={props.onDrillDown} />
			);
		});
	}
	return (
		<div className="table-responsive" style={{ borderWidth: 0 }}>
			<table className="table table-striped table-sm mt-2">
				<thead>
					<tr>
						<th>Player</th>
						<th className="text-center">Rubbers</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
};

export default PlayerStatsTable;