import React from 'react';
import WrapperWidgetBadge, { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';

interface IWidgetWrapperBadgesProps {
	badges: IWrapperWidgetBadge[];
}

const WrapperWidgetBadges = (props: IWidgetWrapperBadgesProps) => {
	var badges: any[] = [];
	if (props.badges && props.badges.length > 0) {
		props.badges.forEach((badge) => {
			badges.push(
				<WrapperWidgetBadge badge={badge} key={badge.name} />
			);
		});
	}
	return (
		<div className="float-right">
			{badges}
		</div>
	);
};

export default WrapperWidgetBadges;