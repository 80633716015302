import React from 'react';

export interface IWrapperWidgetBadge {
	name: string;
	value: string;
}

interface IWrapperWidgetBadgeProps {
	badge: IWrapperWidgetBadge;
}

const WrapperWidgetBadge = (props: IWrapperWidgetBadgeProps) => {
	return (
		<span>
			{props.badge.name} <span className="badge badge-pill badge-light" > {props.badge.value}</span >
		</span>
	);
};

export default WrapperWidgetBadge;