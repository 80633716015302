import { IAppThunkAction } from './';
import { Action, Reducer } from 'redux';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

export interface IResultsState {
	isLoading: boolean;
	fetched?: Date;
	data: IResultData;
}

export interface IResultData {
	version: number;
	rubbers: IRubber[];
	results: IResult[];
}

export interface IResult {
	matchId: number;
	playerId: number;
	rubbersFor: number;
	rubbersAgainst: number;
	setsFor: number;
	setsAgainst: number;
	gamesFor: number;
	gamesAgainst: number;
}

export interface IRubber {
	matchId: number;
	rubber: number;
	singles: boolean;
	player1: number;
	player2: number;
	oppo1: string;
	oppo2: string;
	set1: string;
	set2: string;
	set3: string;
}

interface IResultsFetchResult {
	result: api.IApiResult;
	data: IResultData;
}

interface IPlayerMatchResults {
	matchId: number;
	results: IResult[];
}

interface IMatchRubberResults {
	matchId: number;
	rubbers: IRubber[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestResultsData {
	type: 'REQUEST_RESULTS_DATA';
}

interface IResultsDataLoaded {
	type: 'RESULTS_DATA_LOADED';
	results: IResultData;
}

interface IPlayerMatchResultsSet {
	type: 'PLAYER_MATCH_RESULTS_SET';
	results: IPlayerMatchResults;
}

interface IMatchRubberResultsSet {
	type: 'MATCH_RUBBER_RESULTS_SET';
	results: IMatchRubberResults;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestResultsData | IResultsDataLoaded | IPlayerMatchResultsSet | IMatchRubberResultsSet;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	requestResultData: (accessToken: string, clubId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/results/list?clubId=${clubId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<IResultsFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'RESULTS_DATA_LOADED', results: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Results data fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("Results data not found");
						break;
				}
			});
		dispatch({ type: 'REQUEST_RESULTS_DATA' });
	},
	playerMatchResultSet: (matchId: number, results: IResult[]): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		let mr: IPlayerMatchResults = { matchId: matchId, results: results };
		dispatch({ type: 'PLAYER_MATCH_RESULTS_SET', results: mr });
	},
	matchRubberResultSet: (matchId: number, rubbers: IRubber[]): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		let mr: IMatchRubberResults = { matchId: matchId, rubbers: rubbers };
		dispatch({ type: 'MATCH_RUBBER_RESULTS_SET', results: mr });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IResultsState = { isLoading: false, data: { version: 0, rubbers: [], results: [] } };

export const reducer: Reducer<IResultsState> = (state: IResultsState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_RESULTS_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'RESULTS_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.results, isLoading: false });
		case 'PLAYER_MATCH_RESULTS_SET':
			let results: IResult[] = state.data.results.filter((rs) => { return rs.matchId !== action.results.matchId; });
			results = results.concat(action.results.results);
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { results: results }), isLoading: false });
		case 'MATCH_RUBBER_RESULTS_SET':
			let rubbers: IRubber[] = state.data.rubbers.filter((rb) => { return rb.matchId !== action.results.matchId; });
			rubbers = rubbers.concat(action.results.rubbers);
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { rubbers: rubbers }), isLoading: false });
		default:
			return state;
	}

	// For unrecognized actions (or in cases where actions have no effect), must return the existing state
	//  (or default initial state if none was supplied)
	//return state || unloadedState;
};