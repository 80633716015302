import React from 'react';
import TeamListRow, { ITeamListItem } from './fnTeamListRow';

interface ITeamListTableProps {
	items: ITeamListItem[];
	onDrillDown: (focusId: number) => void;
}

const TeamListTable = (props: ITeamListTableProps) => {
	var rows: any[] = [];
	props.items.forEach((item) => {
		rows.push(
			<TeamListRow item={item} key={item.team.id} onDrillDown={props.onDrillDown} />
		);
	});

	return (
		<div className="table-responsive mt-2">
			<table className="table table-striped table-sm">
				<thead>
					<tr>
						<th>Name</th>
						<th>Division</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		</div>
	);
};

export default TeamListTable;