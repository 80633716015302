
import React from 'react';
import TeamMatchRow, { ITeamMatch } from './fnTeamMatchRow';

interface ITeamMatchTableProps {
	matches: ITeamMatch[];
	onDrillDown: (focusId: number) => void;
}

const TeamMatchTable = (props: ITeamMatchTableProps) => {
	var rows: any[] = [];
	let totalPoints: number = 0;
	props.matches.forEach((match) => {
		rows.push(<TeamMatchRow match={match} key={match.id} onDrillDown={props.onDrillDown} />);
		totalPoints = totalPoints + match.points;
	});
	return (
		<table className="table table-striped">
			<thead>
				<tr>
					<th>Date</th>
					<th>Opposition</th>
					<th className="text-center">Venue</th>
					<th className="text-center">Points</th>
				</tr>
			</thead>
			<tfoot>
				<tr>
					<td />
					<td />
					<td />
					<td className="text-center">{totalPoints}</td>
				</tr>
			</tfoot>
			<tbody>
				{rows}
			</tbody>
		</table>
	);
};

export default TeamMatchTable;
