import React from 'react';
import { connect } from 'react-redux';
import { EAvailabilityStatus, IAvailability } from '../../store/availability';
import { ISelection } from '../../store/selection';
import { IPlayer } from '../../store/player';
import { IApplicationState } from '../../store';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button } from 'reactstrap';
import AvailabilityPlayerRow, { IAvailabilityPlayerItem } from './fnAvailabilityPlayerRow';

export interface IAvailabilityPlayersProps {
	availability: IAvailability[];
	selection: ISelection[];
	players: IPlayer[];
	activityId?: number;
	matchId?: number;
	status?: EAvailabilityStatus;
	positiveColour?: string;
	showing: boolean;
	onClose: () => void;
}

const AvailabilityPlayers = (props: IAvailabilityPlayersProps) => {
	const [showing, setShowing] = React.useState(props.showing);

	React.useEffect(
		() => {
			setShowing(props.showing);
		},
		[props.showing]
	);

	let players: IAvailabilityPlayerItem[] = [];
	if (props.activityId) {
		let avList: IAvailability[] = props.availability.filter(av => {
			return av.activityId === props.activityId && (props.status === undefined || av.status === props.status);
		});
		let avCount: number = avList.length;
		let avNo: number = 0;
		while (avNo < avCount) {
			let av: IAvailability = avList[avNo];
			avNo += 1;
			let playerIndex = props.players.findIndex(pl => { return pl.id === av.playerId; });
			if (playerIndex >= 0) {
				let player: IPlayer = props.players[playerIndex];
				players.push({ key: player.id.toString(), firstName: player.firstName, surname: player.surname, status: av.status, registered: player.registered, sex: player.sex });
			}
		}
	} else if (props.matchId) {
		let seList: ISelection[] = props.selection.filter(se => {
			return se.matchId === props.matchId && (
				props.status === undefined
				|| (se.locked && props.status === EAvailabilityStatus.Available)
				|| (!se.locked && props.status === EAvailabilityStatus.Pending)
			);
		});
		let seCount: number = seList.length;
		let seNo: number = 0;
		while (seNo < seCount) {
			let se: ISelection = seList[seNo];
			seNo += 1;
			let playerIndex = props.players.findIndex(pl => { return pl.id === se.playerId; });
			if (playerIndex >= 0) {
				let player: IPlayer = props.players[playerIndex];
				let playerStatus: EAvailabilityStatus = EAvailabilityStatus.Pending;
				if (se.locked) {
					playerStatus = EAvailabilityStatus.Available;
				}
				players.push({ key: player.id.toString(), firstName: player.firstName, surname: player.surname, status: playerStatus, registered: player.registered, sex: player.sex });
			}
		}

		players.sort((a: IAvailabilityPlayerItem, b: IAvailabilityPlayerItem) => {
			if (a.sex < b.sex) {
				return -1;
			} else if (a.sex > b.sex) {
				return 1;
			} else	if (a.surname < b.surname) {
				return -1;
			} else if (a.surname > b.surname) {
				return 1;
			} else if (a.firstName < b.firstName) {
				return -1;
			} else if (a.firstName > b.firstName) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	const hideModal = () => {
		setShowing(false);
		props.onClose();
	};

	const toggle = () => {
		setShowing(!showing);
	};

	let title: string = "All Requested";
	let statusNo: number = -1;
	if (props.status !== undefined) {
		statusNo = props.status;
		switch (props.status) {
			case EAvailabilityStatus.Available:
				title = "Available";
				break;
			case EAvailabilityStatus.Unavailable:
				title = "Unavailable";
				break;
			case EAvailabilityStatus.Maybe:
				title = "Maybe";
				break;
			case EAvailabilityStatus.Pending:
				title = "Not Responded";
				break;
			default:
				title = "All Requested";
		}
	}

	let rows: any[] = [];
	let ladies: number = 0;
	let ladiesLabel: string = "ladies";
	let men: number = 0;
	let menLabel: string = "men";
	players.forEach((item) => {
		rows.push (
			<AvailabilityPlayerRow player={item} positiveColour={props.positiveColour} key={item.key} />
		);
		if (item.sex === "F") {
			ladies += 1;
		} else {
			men += 1;
		}
	});
	if (ladies > 0 && men > 0) {
		if (ladies === 1) {
			ladiesLabel = "lady";
		}
		if (men === 1) {
			menLabel = "man";
		}
		let sexTotal: IAvailabilityPlayerItem = {
			key: "Total",
			firstName: ladies.toString() + " " + ladiesLabel + ",",
			surname: men.toString() + " " + menLabel,
			status: statusNo,
			registered: true,
			sex: "X"
		};
		rows.push (
			<AvailabilityPlayerRow player={sexTotal} positiveColour={props.positiveColour} key={"Total"} />
		);
	}

	const modal = (
		<Modal isOpen={showing} toggle={toggle} backdrop={"static"}>
		<ModalHeader toggle={toggle}>
			{title}
		</ModalHeader>
		<ModalBody>
			<div className="form-group">
				{rows}
			</div>
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" onClick={hideModal}>Close</Button>					
		</ModalFooter>
	</Modal>
	);
	return <div>{modal}</div>;
};

const mapStateToProps = (state: IApplicationState) => ({
	availability: state.availability.data.availability,
	selection: state.selection.data.selection,
	players: state.players.data.players
});

export default connect(mapStateToProps)(AvailabilityPlayers);