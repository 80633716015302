import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IPlayer, IStatsFilter, actionCreators as playerActionCreators } from '../../store/player';
import { IMatch, ITeam, ICompetition, emptyTeam } from '../../store/match';
import { IResult } from '../../store/result';
import { IAuthState } from '../../store/auth';
import { ILibraryRole } from '../../store/library';
import { bindActionCreators } from "redux";
import StatsFilterEdit from '../player/fnStatsFilterEdit';
import { IPlayerStatsResults } from './fnPlayerStatsRow';
import PlayerStatsTable from './fnPlayerStatsTable';

interface IPlayerStatsProps {
	auth: IAuthState;
	results: IResult[];
	players: IPlayer[];
	matches: IMatch[];
	teams: ITeam[];
	competitions: ICompetition[];
	roles: ILibraryRole[];
	filter: IStatsFilter;
	actions: typeof playerActionCreators;
}

type TPlayerStatsProps =
	IPlayerStatsProps
	& RouteComponentProps<{}>;

const PlayerStats = (props: TPlayerStatsProps) => {
	let stats: IPlayerStatsResults[] = [];
	props.matches.filter((ma) => { return ma.completed === true; }).forEach((ma) => {
		let team: ITeam = emptyTeam;
		let teamIndex: number = props.teams.findIndex((tm) => { return tm.id === ma.teamId; });
		if (teamIndex >= 0) {
			team = props.teams[teamIndex];
		}
		if (team) {
			if (!props.filter.filtering || props.filter.competitions.indexOf(team.competitionId) >= 0) {
				props.results.filter((rs) => { return rs.matchId === ma.id; }).forEach((rs) => {
					let results: IPlayerStatsResults = { id: 0, firstName: "", surname: "", rubbersFor: 0, rubbersPlayed: 0};
					let resultsIndex = stats.findIndex((r: IPlayerStatsResults) => { return r.id === rs.playerId; });
					if (resultsIndex >= 0) {
						results = stats[resultsIndex];
						results.rubbersFor = results.rubbersFor + rs.rubbersFor;
						results.rubbersPlayed = results.rubbersPlayed + rs.rubbersFor + rs.rubbersAgainst;
					} else {
						let playerIndex = props.players.findIndex((pl) => { return pl.id === rs.playerId; });
						if (playerIndex >= 0) {
							let player: IPlayer = props.players[playerIndex];
							results.id = player.id;
							results.firstName = player.firstName;
							results.surname = player.surname;
							results.rubbersFor = rs.rubbersFor;
							results.rubbersPlayed = rs.rubbersFor + rs.rubbersAgainst;
							stats.push(results);
						}
					}
				});
			}
		}
	});
	stats = stats.sort((a: IPlayerStatsResults, b: IPlayerStatsResults) => {
		if (a.rubbersFor > b.rubbersFor) {
			return -1;
		} else if (a.rubbersFor < b.rubbersFor) {
			return 1;
		} else if (a.rubbersPlayed < b.rubbersPlayed) {
			return -1;
		} else if (a.rubbersPlayed > b.rubbersPlayed) {
			return 1;
		} else if (a.surname < b.surname) {
			return -1;
		} else if (a.surname > b.surname) {
			return 1;
		} else if (a.firstName < b.firstName) {
			return -1;
		} else if (a.firstName > b.firstName) {
			return 1;
		} else {
			return 0;
		}
	});

	const onFilterSaved = (filter: IStatsFilter) => {
		props.actions.statsFilterSet(filter);
	};

	const onDrillDown = (focusId: number) => {
		let playerIds: number[] = stats.map((pl) => { return pl.id; });
		props.history.push("/player/details/" + focusId.toString(), { "ids": playerIds });
	};

	return (
		<div>
			<div className="container body-content">
				<StatsFilterEdit title="Stats Filter" filter={props.filter} competitions={props.competitions} onFilterSaved={onFilterSaved} />
				<PlayerStatsTable stats={stats} onDrillDown={onDrillDown} />
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	results: state.result.data.results,
	players: state.players.data.players,
	matches: state.matches.data.matches,
	teams: state.matches.data.teams,
	competitions: state.matches.data.competitions,
	roles: state.library.data.roles,
	filter: state.players.statsFilter
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(playerActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(PlayerStats) as any;
