import React from 'react';
import { Form } from 'reactstrap';
import EditControl, { IEditControl } from './fnEditControl';

interface IEditControlSetProps {
	controls: IEditControl[];
	validate: boolean;
}

const EditControlSet = React.memo(
(props: IEditControlSetProps) => {
	var controls: any[] = [];
	props.controls.forEach((ctrl) => {
		controls.push(<EditControl control={ctrl} validate={props.validate} key={ctrl.id} />);
	});

	return (
		<Form>
			{controls}
		</Form>
	);
},
(prevProps: IEditControlSetProps, nextProps: IEditControlSetProps) => {
	let same: boolean = prevProps.validate === nextProps.validate && prevProps.controls.length === nextProps.controls.length;
	if (same) {
		let controlNo = 0;
		let controlCount = prevProps.controls.length;
		while (controlNo < controlCount && same) {
			same = prevProps.controls[controlNo].value === nextProps.controls[controlNo].value;
			controlNo += 1;
		}
	}
	return same;
});

export default EditControlSet;