import { Log, WebStorageStateStore, UserManager } from "oidc-client";
import { IUser } from '../store/auth';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

const userManagerConfig = {
	authority: config.identityServerUrl,
	client_id: config.clientId,
	redirect_uri: config.redirectUrl,
	response_type: 'code',
	// offline_access needed when using PKCE!
	scope: 'openid profile teamplayer email phone 2fa offline_access',
	silent_redirect_uri: config.silentRedirectUrl,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: true,
	post_logout_redirect_uri: config.logoutRedirectUrl,
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	includeIdTokenInSilentRenew: false,
	monitorSession: true
};

export function createUserManager() {
	if (process.env.NODE_ENV === 'production') {
		Log.level = Log.NONE;
	} else {
		Log.logger = console;
		Log.level = Log.DEBUG;
	}
	const userManager = new UserManager(userManagerConfig);
	return userManager;
}

export function mapIdentityServerUser(user: Oidc.User): IUser {
	let emailVerified: boolean = false;
	if (user.profile && user.profile.email_verified != null) {
		emailVerified = user.profile.email_verified;
	}
	let retUser: IUser = {
		idToken: user.id_token,
		accessToken: user.access_token,
		accessTokenType: user.token_type,
		accessTokenExpiry: user.expires_at,
		emailVerified: emailVerified
	};
	return retUser;
}