import React from 'react';
import { EAvailabilityStatus } from '../../store/availability';

export interface IAvailabilityPlayerItem {
	key: string;
	firstName: string;
	surname: string;
	status: number;
	registered: boolean;
	sex: string;
}

interface IAvailabilityPlayerRowProps {
	player: IAvailabilityPlayerItem;
	positiveColour?: string;
}

const AvailabilityPlayerRow = (props: IAvailabilityPlayerRowProps) => {
	let iconName = "fa fa-hourglass-o";
	let iconColour = "red";
	switch (props.player.status) {
		case EAvailabilityStatus.Available:
			iconName = "fa fa-thumbs-o-up";
			iconColour = "green";
			if (props.positiveColour) {
				iconColour = props.positiveColour;
			}
			break;
		case EAvailabilityStatus.Unavailable:
			iconName = "fa fa-thumbs-o-down";
			iconColour = "dimgray";
			break;
		case EAvailabilityStatus.Maybe:
			iconName = "fa fa-pencil";
			iconColour = "purple";
			break;
		default:
			iconName = "fa fa-hourglass-o";
			iconColour = "red";
			break;
	}

	let playerName: string = props.player.firstName + ' ' + props.player.surname;
	if (!props.player.registered) {
		playerName = playerName + " (N/R)";
	}

	let icon;
	if (props.player.key !== "Total") {
		icon = (<span className={iconName} style={{ marginTop: 2, marginRight: 2 }} />);
	}

	return (
		<div
			style={{
				color: iconColour
			}}
		>
			{icon} {playerName}
		</div>
	);
};

export default AvailabilityPlayerRow;
