import React, { useState, useEffect } from 'react';
import { CustomInput } from 'reactstrap';

export interface INotifyWidgetPlayer {
	id: number;
	firstName: string;
	surname: string;
	checked: boolean;
}

interface IPlayerRowProps {
	activityId: number;
	data: INotifyWidgetPlayer;
	callback: (playerId: number, checked: boolean) => void;
}

const NotifyPlayerRow = (props: IPlayerRowProps) => {
	const [checked, setChecked] = useState(props.data.checked);

	// align state to props when props change
	useEffect(
		() => {
			setChecked(props.data.checked);
		},
		[props.data.checked]
	);

	const onChange = (e: any) => {
		let plChecked = !checked;
		setChecked(plChecked);
		props.callback(props.data.id, plChecked);
	};

	let rowClass = "";
	return (
		<tr className={rowClass} >
			<td>
				<CustomInput
					type="checkbox"
					//className="mb1"
					label={props.data.firstName + ' ' + props.data.surname}
					checked={checked}
					onChange={onChange}
					id={props.data.id.toString()}
					style={{ marginLeft: 0 }}
				/>
				{/* <input type="checkbox" checked={checked} onChange={onChange} style={{ marginLeft: 0 }} /> */}
			</td>
			{/* <td>{props.data.firstName + ' ' + props.data.surname}</td> */}
		</tr>
	);
};

export default NotifyPlayerRow;
