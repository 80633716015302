import React from 'react';
import { Button } from 'reactstrap';

export interface IMatchPlayer {
	id: number;
	name: string;
	position: number;
	mobile: string;
	email: string;
	profileRef: string;
	confirmed: boolean;
}

interface IMatchPlayerRowProps {
	player: IMatchPlayer;
	onDrillDown: (focusId: number) => void;
}

const MatchPlayerRow = (props: IMatchPlayerRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.player.id);
	};

	let email;
	if (props.player.email !== "") {
		email = <a href={"mailto:" + props.player.email} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-envelope" style={{ fontSize: "30px", color: "gold" }} /></a>;
	}

	let call;
	let text;
	if (props.player.mobile !== "") {
		call = <a href={"tel:" + props.player.mobile} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-phone" style={{ fontSize: "30px", color: "navy" }} /></a>;
		text = <a href={"sms:" + props.player.mobile} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-comment" style={{ fontSize: "30px", color: "green" }} /></a>;
	}

	let iconName: string = "fa fa-hourglass-o";
	let iconColour: string = "red";
	if (props.player.confirmed) {
		iconName = "fa fa-thumbs-o-up";
		iconColour = "green";
	}

	return (
		<tr>
			<td className="text-center">{props.player.position}</td>
			<td><span className={iconName} style={{ color: iconColour}} /></td>
			{/* <td className="text-center">
				<a
					href={"https://competitions.lta.org.uk/player-profile/" + props.player.profileRef}
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fa fa-id-badge fa-lg" style={{ marginRight: "5px" }} />
				</a>
			</td> */}
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{props.player.name}</Button></td>
			<td className="text-center">{call}</td>
			<td className="text-center">{text}</td>
			<td className="text-center">{email}</td>
		</tr>
	);
};

export default MatchPlayerRow;