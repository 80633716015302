import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

interface IRecordBrowserSimpleProps {
	id: number;
	ids: number[];
	onRecordChange(id: number): void;
}

const RecordSimpleBrowser = React.memo(
(props: IRecordBrowserSimpleProps) => {
	const [recordNo, setRecordNo] = useState(props.ids.indexOf(Number(props.id)));
	const [recordCount, setRecordCount] = useState(props.ids.length);

	useEffect(
		() => {
			setRecordNo(props.ids.indexOf(Number(props.id)));
			setRecordCount(props.ids.length);
		},
		[props.id, props.ids],
	);

	const onPagePrior = () => {
		setPage(recordNo - 1);
	};

	const onPageNext = () => {
		setPage(recordNo + 1);
	};

	const setPage = (reqRecordNo: number) => {
		setRecordNo(reqRecordNo);
		props.onRecordChange(props.ids[reqRecordNo]);
	};

	let allowPrior: boolean = recordNo > 0;
	let allowNext: boolean = recordNo < recordCount - 1;

	return (
		<div>  
			<Button
				className="browser-left-btn"
				color={allowPrior ? "dark" : "secondary"}
				disabled={!allowPrior}
				onClick={onPagePrior}
				style={{ float: "left", verticalAlign: "bottom" }}
			>
				<i className="fa fa-lg fa-angle-left" />
			</Button>
			<Button
				className="browser-right-btn"
				color={allowNext ? "dark" : "secondary"}
				disabled={!allowNext}
				onClick={onPageNext}
				style={{ float: "right" }}
			>
				<i className="fa fa-lg fa-angle-right" />
			</Button>
		</div>
	);
},
(prevProps: IRecordBrowserSimpleProps, nextProps: IRecordBrowserSimpleProps) => {
	let same: boolean = false;
	same = prevProps.id === nextProps.id
		&& prevProps.ids.map((pp) => { return pp.toString(); }).join(",") === nextProps.ids.map((np) => { return np.toString(); }).join(",");
	return same;
});

export default RecordSimpleBrowser;