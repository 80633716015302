import React from 'react';
import MatchResultRow, { IMatchRubberResult } from './fnMatchResultRow';

interface IMatchResultTableProps {
	rubbers: IMatchRubberResult[];
}

const MatchResultTable = (props: IMatchResultTableProps) => {
	var rows: any[] = [];
	props.rubbers.forEach((rubber) => {
		rows.push(<MatchResultRow result={rubber} key={rubber.rubber} />);
	});
	return (
		<table className="table table-striped">
			<thead>
				<tr>
					<th className="text-center">R</th>
					<th>Home</th>
					<th>Away</th>
					<th>Score</th>
				</tr>
			</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	);
};

export default MatchResultTable;
