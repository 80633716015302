/*eslint no-undef: "error"*/
/*eslint no-restricted-globals: ["error", "event"]*/
import React, { useState } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Form
} from 'reactstrap';
import { NavLink  as RRDNavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import * as AuthStore from '../store/auth';
import * as LibraryStore from '../store/library';
import { bindActionCreators } from "redux";
import * as PlayerStore from '../store/player';
import { createUserManager } from '../utils/userManager';
import * as Oidc from "oidc-client";
import { AppConfig, IAppConfig } from '../config/appConfig';
//import userManager from '..';
let config: IAppConfig = AppConfig();

interface INavMenuProps {
	auth: AuthStore.IAuthState;
	clubId: number;
	playerId: number;
	isTeamCaptain: boolean;
	isExternal: boolean;
	roles: LibraryStore.ILibraryRole[];
	tags: LibraryStore.ILibraryTag[];
	actions: typeof PlayerStore.actionCreators;
}

type TNavMenuProps = INavMenuProps & RouteComponentProps<{}>;

const NavMenu = (props: TNavMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const onLogin = (e: any) => {
		e.preventDefault();
		let userManager: Oidc.UserManager = createUserManager();
		userManager.signinRedirect();
	};

	const onLogout = (e: any) => {
		e.preventDefault();
		props.actions.playerReset();
		let userManager: Oidc.UserManager = createUserManager();
		userManager.signoutRedirect();
	};

	const onBrowserBack = (e: any) => {
		e.preventDefault();
		props.history.goBack();
	};

	const onBrowserForward = (e: any) => {
		e.preventDefault();
		props.history.goForward();
	};

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const onCloseMenu = () => {
		setIsOpen(false);
	};

	// availability
	let availabilityLink;
	if (props.auth.isLoggedIn && props.playerId > 0 && props.isTeamCaptain && !props.isExternal && props.auth.deviceOnLine) {
		availabilityLink = (
			<NavItem>
				<NavLink tag={RRDNavLink} to="/activity/list" onClick={onCloseMenu}><i className="fa fa-calendar" /> Availability</NavLink>
			</NavItem>
		);
	}

	// check if allowed to access matches
	let matchesLink;
	if (props.playerId > 0 && !props.isExternal) {
		matchesLink = (
			<NavItem>
				<NavLink tag={RRDNavLink} to="/match/list" onClick={onCloseMenu}><i className="fa fa-calendar" /> Matches</NavLink>
			</NavItem>
		);
	}

	// check if allowed to access teams
	let teamsLink;
	if (props.playerId > 0 && !props.isExternal) {
		teamsLink = (
			<NavItem>
				<NavLink tag={RRDNavLink} to="/team/list" onClick={onCloseMenu}><i className="fa fa-list" /> Teams</NavLink>
			</NavItem>
		);
	}

	// check if allowed to access players
	let playersLink;
	// if (props.playerId > 0 && !props.isExternal) {
	if (props.playerId > 0) {
		playersLink = (
			<NavItem>
				<NavLink tag={RRDNavLink} to="/player/list" onClick={onCloseMenu}><i className="fa fa-user" /> Players</NavLink>
			</NavItem>
		);
	}

	// check if allowed to access player stats
	let statsLink;
	if (props.playerId > 0 && !props.isExternal) {
		statsLink = (
			<NavItem>
				<NavLink tag={RRDNavLink} to="/player/stats" onClick={onCloseMenu}><i className="fa fa-signal" /> Stats</NavLink>
			</NavItem>
		);

	}

	// log out link
	let logOutLink;
	if (props.auth.deviceOnLine) {
		if (!props.auth.isLoggedIn) {
			logOutLink = (
				<NavItem>
					<NavLink className="nav-link-icon" id="accountLogIn" href="#" onClick={onLogin}>
						<i className="fa fa-sign-in" /> Log in
					</NavLink>
				</NavItem>
			);
		} else {
			logOutLink = (
				<NavItem>
					<NavLink className="nav-link-icon" id="accountLogOut" href="#" onClick={onLogout}>
						<i className="fa fa-sign-out" /> Log out
					</NavLink>
				</NavItem>
			);
		}
	}

	// user account link
	let accountLink;
	if (config.manageAccountUrl && config.manageAccountUrl !== "") {
		let clientLink: string = config.manageAccountUrl + "?clientId=" + config.clientId;
		accountLink = (
			<NavItem>
				<NavLink className="nav-link-icon" id="accountLink" href={clientLink} target="_blank" rel="noopener noreferrer">
					<i className="fa fa-user" /> My account
				</NavLink>
			</NavItem>
		);
	}

	let nav = (
		<Navbar color="dark" dark={true} expand="md" fixed="top" className="pb-0 pt-0">
			<NavbarBrand>
				<Form inline={true}>
					<table className="float-left ml-0">
						<tbody>
							<tr>
								<td>
									<Nav navbar={true}>
										<NavItem>
											<NavLink tag={RRDNavLink} to="#" onClick={onBrowserBack} className="mr5" aria-label="Back">
												<i className="fa fa-chevron-left" />
											</NavLink>
										</NavItem>
									</Nav>
								</td>
								<td style={{width: 10}} />
								<td>
									<Nav navbar={true}>
										<NavItem>
											<NavLink tag={RRDNavLink} to={'/'} className="m5"><i className="fa fa-home" /> {"Team Player " + config.version}</NavLink>
										</NavItem>
									</Nav>
								</td>
								<td style={{width: 10}} />
								<td>
									<Nav navbar={true}>
										<NavItem>
											<NavLink tag={RRDNavLink} to="#" onClick={onBrowserForward} aria-label="Forward">
												<i className="fa fa-chevron-right" />
											</NavLink>
										</NavItem>
									</Nav>
								</td>
							</tr>
						</tbody>
					</table>
				</Form>
			</NavbarBrand>
			<NavbarToggler onClick={onToggle} aria-label="Toggle Menu" />
			<Collapse id="responsive-navbar-nav" isOpen={isOpen} navbar={true}>
				<Nav navbar={true} className="mr-auto">
					{teamsLink}
					{matchesLink}
					{playersLink}
					{availabilityLink}
					{statsLink}
				</Nav>
				<Nav className="ml-auto" navbar={true}>
					{accountLink}
					{logOutLink}
				</Nav>
			</Collapse>
		</Navbar>
	);
	return nav;
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	clubId: state.players.myClubId,
	playerId: state.players.myPlayerId,
	isTeamCaptain: state.players.isTeamCaptain,
	isExternal: state.players.isExternal,
	roles: state.library.data.roles,
	tags: state.library.data.tags
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(PlayerStore.actionCreators, dispatch)
	};
}

export default withRouter(connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(NavMenu) as any);
