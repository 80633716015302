import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IPlayersState, IPlayer, IPlayerTag, IPlayerFilter, IPlayerRecord, actionCreators as playerActionCreators } from '../../store/player';
import { IAuthState } from '../../store/auth';
import { ILibraryRole, ILibraryTag } from '../../store/library';
import PlayerEdit from '../player/fnPlayerEdit';
import { bindActionCreators } from "redux";
import PlayerFilterEdit from '../player/fnPlayerFilterEdit';
import PlayerListTable from './fnPlayerListTable';

interface IPlayerListProps {
	auth: IAuthState;
	playerStore: IPlayersState;
	playerTags: IPlayerTag[];
	roles: ILibraryRole[];
	tags: ILibraryTag[];
	filter: IPlayerFilter;
	isExternal: boolean;
	actions: typeof playerActionCreators;
}

type TPlayerListProps =
	IPlayerListProps
	& RouteComponentProps<{}>;

const PlayerList = (props: TPlayerListProps) => {
	// if not filtering get default position;
	let playerFilter: IPlayerFilter = {
		filtering: props.filter.filtering,
		status: props.filter.status ? props.filter.status : "X",
		gender: props.filter.gender ? props.filter.gender : "X",
		tags: props.filter.tags ? props.filter.tags : []
	};
	if (!playerFilter.filtering) {
		playerFilter.status = "X";
		playerFilter.gender = "X";
		playerFilter.tags = [];
	}

	const tagsMatch = (playerId: number, allTags: IPlayerTag[], filterTags: number[]): boolean => {
		return allTags.filter((tg) => { return tg.playerId === playerId && filterTags.indexOf(Number(tg.tagId)) >= 0; }).length > 0;
	};

	let players: IPlayer[] = [];
	props.playerStore.data.players.forEach((pl: IPlayer) => {
		if ((playerFilter.gender === "X" || pl.sex === playerFilter.gender)
		&& (playerFilter.status === "I" || (props.filter.status === "O" && pl.archived) || (playerFilter.status === "X" && !pl.archived))
		&& (playerFilter.tags.length === 0 || tagsMatch(pl.id, props.playerTags, playerFilter.tags))) {
			players.push(pl);
		}
	});
	players.sort((a: IPlayer, b: IPlayer) => {
		if (a.surname < b.surname) {
			return -1;
		} else if (a.surname > b.surname) {
			return 1;
		} else if (a.firstName < b.firstName) {
			return -1;
		} else if (a.firstName > b.firstName) {
			return 1;
		} else {
			return 0;
		}
	});

	const onPlayerSaved = (player: IPlayerRecord) => {
		props.actions.playerDetailsLoaded(player);
	};

	const onFilterSaved = (filter: IPlayerFilter) => {
		props.actions.playerFilterSet(filter);
	};

	const onDrillDown = (focusId: number) => {
		if (!props.isExternal) {
			let playerIds: number[] = players.map((pl) => { return pl.id; });
			props.history.push("/player/details/" + focusId.toString(), { "ids": playerIds });
		}
	};

	let addPlayer;
	if (props.playerStore.isTeamCaptain) {
		addPlayer = (
			<PlayerEdit
				title="Add Player"
				playerId={0}
				clubId={props.playerStore.myClubId}
				auth={props.auth}
				roles={props.roles}
				tags={props.tags}
				onPlayerSaved={onPlayerSaved}
				online={props.auth.deviceOnLine}
			/>
		);
	}

	return (
		<div>
			<div className="container body-content">
				<PlayerFilterEdit title="Player Filter" filter={props.filter} tags={props.tags} onFilterSaved={onFilterSaved} />
				<PlayerListTable players={players} isTeamCaptain={props.playerStore.isTeamCaptain} onDrillDown={onDrillDown} />
				{addPlayer}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	playerStore: state.players,
	playerTags: state.players.data.tags,
	roles: state.library.data.roles,
	tags: state.library.data.tags,
	isExternal: state.players.isExternal,
	filter: state.players.filter
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(playerActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(PlayerList) as any;
