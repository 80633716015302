import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	FormFeedback
} from 'reactstrap';
import ChecklistBoxItem, { IChecklistItem } from './fnChecklistBoxItem';

export interface IChecklistBoxProps {
	title: string;
	items: IChecklistItem[];
	callback?: (items: IChecklistItem[]) => void;
	icon?: string;
	errorMsg?: string;
}

const ChecklistBox = React.memo(
(props: IChecklistBoxProps) => {
	const [items, setItems] = useState(props.items);
	const [showing, setShowing] = useState(false);

	// align state to props when props change
	useEffect(
		() => {
			setItems(props.items);
		},
		[props.items],
	);

	// using in memory copy to track item states without re-rendering this control when items are individually checked or unchecked
	let editItems = [...items];	

	const showModal = () => {
		setShowing(true);
	};

	const toggle = () => {
		setShowing(!showing);
	};

	// record item changes in memory to avoid re-renderng (ie. no setState)
	const onItemChanged = (value: string, checked: boolean) => {
		editItems = editItems.map((item) => {
			if (item.value === value) {
				return { text: item.text, value: item.value, checked: checked, readOnly: item.readOnly };
			} else {
				return item;
			}
		});
	};

	const onAccept = () => {
		if (props.callback) {
			props.callback(editItems);
		}
		setShowing(false);
	};

	const onCancel = () => {
		setShowing(false);
	};

	const onSelectAll = () => {
		setItems(items.map((item) => {
			return { text: item.text, value: item.value, checked: item.readOnly ? item.checked : true, readOnly: item.readOnly};
		}));
	};

	const onClearAll = () => {
		setItems(items.map((item) => {
			return { text: item.text, value: item.value, checked: item.readOnly ? item.checked : false, readOnly: item.readOnly};
		}));
	};

	let modal;
	if (showing) {
		var rows: any[] = [];
		items.forEach((item) => {
			rows.push(
				<ChecklistBoxItem item={item} key={item.value} callback={onItemChanged} />
			);
		});

		modal = (
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"}>
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody>
					<Form>
						{rows}
					</Form>
				</ModalBody>
				<ModalFooter>
					<div>
						<Button color="link" onClick={onSelectAll} style={{ paddingLeft: 0 }}><i className="fa fa-check" /> all</Button>
						<Button color="link" onClick={onClearAll}><i className="fa fa-times" /> none</Button>
					</div>
					<Button color="primary" type="submit" onClick={onAccept}>OK</Button>{' '}
					<Button color="secondary" onClick={onCancel}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		);
	}

	let icon = "fa fa-search";
	if (props.icon) {
		icon = props.icon;
	}

	let invalid: boolean = false;
	let errorControl;
	if (props.errorMsg && props.errorMsg !== "") {
		invalid = true;
		errorControl = <FormFeedback>{props.errorMsg}</FormFeedback>;
	}

	const control = (
		<InputGroup>
			<Input
				id="checklist"
				name="checklist"
				value={items.filter((item) => { return item.checked; }).map((item) => { return item.text; }).join(", ")}
				tabIndex={-1}
				readOnly={true}
				invalid={invalid}
			/>
			<InputGroupAddon addonType="append">
				<Button color="secondary" onClick={showModal}><i className={icon} /></Button>
			</InputGroupAddon>
			{errorControl}
		</InputGroup>
	);

	return <div>{modal}{control}</div>;
},
(prevProps: IChecklistBoxProps, nextProps: IChecklistBoxProps) => {
	let same: boolean = prevProps.errorMsg === nextProps.errorMsg && prevProps.items.length === nextProps.items.length;
	if (same) {
		let prevSelection = prevProps.items.filter((item) => { return item.checked; }).map((item) => { return item.text; }).join(", ");
		let nextSelection = nextProps.items.filter((item) => { return item.checked; }).map((item) => { return item.text; }).join(", ");
		same = prevSelection === nextSelection;
	}
	return same;
});

export default ChecklistBox;

export function ChecklistBoxSelectionAsString(items: IChecklistItem[]): string {
	let itemsStr: string = "";
	if (items.length > 0) {
		itemsStr = items.filter((i) => { return i.checked; }).map((i2) => { return i2.value; }).join(",");
	}
	return itemsStr;
}
