import React from 'react';
import { IActivity, ICompetition } from '../../store/match';
import { formatDateString, EDateFormat } from '../../utils/dates';
import { Button } from 'reactstrap';

export interface IActivityListItem {
	activity: IActivity;
	competition: ICompetition;
}

interface IActivityListRowProps {
	item: IActivityListItem;
	onDrillDown: (focusId: number) => void;	
}

const ActivityListRow = (props: IActivityListRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		let activityId: number = props.item.activity.id;
		props.onDrillDown(activityId);
	};

	let dateStr: string = formatDateString(props.item.activity.date, EDateFormat.dayMonthWithTime24H);
	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{dateStr}</Button></td>
			<td>{props.item.activity.title}</td>
		</tr>
	);
};

export default ActivityListRow;