import React from 'react';
import { monthName, dayName } from '../../../utils/dates';

interface ICalendarDateProps {
	date: Date;
	backgroundColor: string;
	dayFontColor: string;
	includeDayName: boolean;
}

const fnCalendarDate = (props: ICalendarDateProps) => {
	let monthShort = monthName(props.date.getMonth() + 1).substring(0, 3);
	let dayShort = dayName(props.date.getDay() + 1).substring(0, 3);
	let day;
	if (props.includeDayName) {
		day = (
			<div 
				style={{
					paddingTop: 4,
					fontSize: 16,
					fontWeight: "bold"
				}}
			>
				{dayShort}
			</div>
		);
	}

	return (
		<div
			style={{
				width: 50,
				textAlign: "center",
			}}
		>
			<div
				style={{
					borderStyle: "solid",
					borderColor: "black",
					overflow: "hidden",
					borderWidth: 1,
					borderRadius: 5,
				}}
			>
				<div
					style={{
						backgroundColor: props.backgroundColor,
						color: "white",
						fontSize: 16,
						fontWeight: "bold",
						paddingTop: 3,
						paddingBottom: 3,
						textAlign: "center",
					}}
				>
					{monthShort}
				</div>
				<div
					style={{
						fontSize: 24,
						textAlign: "center",
						color: props.dayFontColor,
						height: 36,
						borderColor: "black",
						borderStyle: "solid",
						borderWidth: 0,
						borderTopWidth: 1
					}}
				>
					{props.date.getDate()}
				</div>
			</div>
			{day}
		</div>
	);
};

const CalendarDate = React.memo(fnCalendarDate, (prevProps: ICalendarDateProps, nextProps: ICalendarDateProps) => {
	let same: boolean = false;
	same = prevProps.date.getTime() === nextProps.date.getTime() && prevProps.backgroundColor === nextProps.backgroundColor;
	return same;
});

export default CalendarDate;