import { Action, Reducer } from 'redux';
import { LOAD, SAVE } from 'redux-storage';

export interface IStorageState {
	loadedFromLocalStorage: boolean;
}

const unloadedState: IStorageState = {
	loadedFromLocalStorage: false
};

export const reducer: Reducer<IStorageState> = (state: IStorageState | undefined, action: Action) => {
	if (!state) {
		state = unloadedState;
	}
	switch (action.type) {
		case LOAD:
			return { ...state, loadedFromLocalStorage: true };
		case SAVE:
			return state;
		default:
			return state;
	}
};