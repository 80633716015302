import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IAuthState } from '../../store/auth';
import { ILibraryState } from '../../store/library';
import { IAvailability, IAvailabilitySummary, availabilitySummary, actionCreators as AvailabilityActionCreators, IAvailabilityUpdate } from '../../store/availability';
import { ISelection, actionCreators as SelectionActionCreators } from '../../store/selection';
import { IResult } from '../../store/result';
import { IMatchesState, IMatch, emptyMatch, ITeam, IActivity, emptyActivity, emptyTeam, ICompetition, ILeague, emptyCompetition, emptyLeague, actionCreators as MatchActionCreators } from '../../store/match';
import { actionCreators as PlayerActionCreators, IPlayer, emptyPlayer } from '../../store/player';
import { bindActionCreators } from "redux";
import ScheduleWidget from '../widgets/fnScheduleWidget';
import MatchWidget from '../widgets/fnMatchWidget';
import { IMatchWidgetMatch } from '../widgets/fnMatchRow';
import Loading from '../controls/spinners/fnLoading';
import { ELoadingSize } from '../controls/spinners/fnLoading';
import { IScheduleItem } from './fnScheduleItem';
import { today as dateUtilsToday, parseDateString } from '../../utils/dates';

interface IProfileProps {
	auth: IAuthState;
	playerId: number;
	library: ILibraryState;
	matches: IMatchesState;
	players: IPlayer[];
	availability: IAvailability[];
	selection: ISelection[];
	results: IResult[];
	isExternal: boolean;
	actions: {
		availability: typeof AvailabilityActionCreators,
		selection: typeof SelectionActionCreators,
		match: typeof MatchActionCreators,
		player: typeof PlayerActionCreators
	};
}

// At runtime, Redux will merge together...
type TProfileProps = IProfileProps & RouteComponentProps<{}>;

const Profile = (props: TProfileProps) => {
	const availabilityCallback = (av: IAvailability) => {
		if (props.auth.user) {
			let avu: IAvailabilityUpdate = { availability: av, sendEmail: false }
			props.actions.availability.setAvailability(props.auth.user.accessToken, avu);
		}
	};

	const matchDetailsCallback = (matchId: number) => {
		props.history.push("/match/details/" + matchId.toString());
	};

	const myMatchesList = (): IMatchWidgetMatch[] => {
		var playerSelection = props.selection.filter((se) => { return se.playerId === props.playerId; });
		var playerMatches: IMatch[] = [];
		playerSelection.forEach((se) => {
			var index = props.matches.data.matches.findIndex((match) => { return match.id === se.matchId; });
			if (index >= 0) {
				playerMatches.push(props.matches.data.matches[index]);
			}
		});
		playerMatches.sort((a: IMatch, b: IMatch) => {
			if (a.date < b.date) {
				return -1;
			} else if (a.date > b.date) {
				return 1;
			} else {
				return 0;
			}
		});
		var widgetData: IMatchWidgetMatch[] = [];
		playerMatches.forEach((match) => {
			var team = props.matches.data.teams.find((tm) => { return (tm.id === match.teamId); });
			let teamName: string = "";
			let isMixed: boolean = false;
			if (team) {
				teamName = team.name;
				let competitionId = team.competitionId;
				let competitionIndex = props.matches.data.competitions.findIndex((cp) => { return cp.id === competitionId; });
				if (competitionIndex >= 0) {
					let competition = props.matches.data.competitions[competitionIndex];
					isMixed = competition.sex === "X";
					let leagueIndex = props.matches.data.leagues.findIndex((lg) => { return lg.id === competition.leagueId; });
					if (leagueIndex >= 0) {
						let league = props.matches.data.leagues[leagueIndex];
						if (league.prefix !== "") {
							teamName = league.prefix + " " + teamName;
						}
					}
				}
			}
			var result = props.results.find((rs) => { return rs.playerId === props.playerId && rs.matchId === match.id; });
			let rubbersFor: number = 0;
			let rubbersAgainst: number = 0;
			if (result) {
				rubbersFor = result.rubbersFor;
				rubbersAgainst = result.rubbersAgainst;
			}
			var wdMatch: IMatchWidgetMatch = {
				id: match.id,
				date: match.date,
				team: teamName,
				isMixed: isMixed,
				venue: match.venue,
				opposition: match.opposition,
				rubbersFor: rubbersFor,
				rubbersAgainst: rubbersAgainst
			};
			widgetData.push(wdMatch);
		});
		return widgetData;
	};

	const clubMatchesList = (last: boolean): IMatchWidgetMatch[] => {
		let today = dateUtilsToday();
		let historicMatches = props.matches.data.matches.filter((match) => { if (last) { return parseDateString(match.date) < today && match.completed; } else { return parseDateString(match.date) > today; } });
		historicMatches.sort((a: IMatch, b: IMatch) => {
			if (a.date < b.date) {
				if (last) { return 1; } else { return -1; }
			} else if (a.date > b.date) {
				if (last) { return -1; } else { return 1; }
			} else {
				return 0;
			}
		});
		let lastMatches: IMatch[] = [];
		props.matches.data.teams.forEach((team) => {
			var index = historicMatches.findIndex((match) => { return match.teamId === team.id; });
			if (index >= 0) {
				lastMatches.push(historicMatches[index]);
			}
		});
		lastMatches.sort((a: IMatch, b: IMatch) => {
			if (a.date < b.date) {
				if (last) { return 1; } else { return -1; }
			} else if (a.date > b.date) {
				if (last) { return -1; } else { return 1; }
			} else {
				return 0;
			}
		});
		var widgetData: IMatchWidgetMatch[] = [];
		lastMatches.forEach((match) => {
			var team = props.matches.data.teams.find((tm) => { return (tm.id === match.teamId); });
			let teamName = "";
			let isMixed: boolean = false;
			if (team) {
				teamName = team.name;
				let competitionId: number = team.competitionId;
				let competition: ICompetition = emptyCompetition;
				let cpIndex: number = props.matches.data.competitions.findIndex((cp) => { return cp.id === competitionId; });
				if (cpIndex >= 0) {
					competition = props.matches.data.competitions[cpIndex];
					isMixed = competition.sex === "X";
				}
				let league: ILeague = emptyLeague;
				let lgIndex: number = props.matches.data.leagues.findIndex(lg => { return lg.id === competition.leagueId; });
				if (lgIndex >= 0) {
					league = props.matches.data.leagues[lgIndex];
				}
				if (league.prefix !== "") {
					teamName = league.prefix + " " + teamName;
				}
			}
			var wdMatch: IMatchWidgetMatch = {
				id: match.id,
				date: match.date,
				team: teamName,
				isMixed: isMixed,
				venue: match.venue,
				opposition: match.opposition,
				rubbersFor: match.rubbersFor,
				rubbersAgainst: match.rubbersAgainst
			};
			widgetData.push(wdMatch);
		});
		return widgetData;
	};

	const scheduleList = (sourceProps: IProfileProps): IScheduleItem[] => {
		let items: IScheduleItem[] = [];
		if (sourceProps.playerId > 0 && sourceProps.matches.data.matches.length > 0) {
			let today: Date = new Date();
			let todayDay = (today.getFullYear() * 12 + today.getMonth()) * 100 + today.getDate();
			// process match selection first, keeping track of associated activities
			let matchActivities: number[] = [];
			let matchActivitiesLocked: number[] = [];
			let mySelection: ISelection[] = sourceProps.selection.filter(se => { return se.playerId === sourceProps.playerId; });
			let mySelectionCnt: number = mySelection.length;
			let mySelectionNo: number = 0;
			while (mySelectionNo < mySelectionCnt) {
				let se: ISelection = mySelection[mySelectionNo];
				let maIndex = sourceProps.matches.data.matches.findIndex(ma => { return ma.id === se.matchId; });
				let match: IMatch = emptyMatch;
				if (maIndex >= 0) {
					match = sourceProps.matches.data.matches[maIndex];
				}
				let matchDate: Date = parseDateString(match.date);
				let matchDay = (matchDate.getFullYear() * 12 + matchDate.getMonth()) * 100 + matchDate.getDate();
				if (matchDay >= todayDay) {
					let tmIndex = sourceProps.matches.data.teams.findIndex(tm => { return tm.id === match.teamId; });
					let team: ITeam = emptyTeam;
					if (tmIndex >= 0) {
						team = sourceProps.matches.data.teams[tmIndex];
					}
					let cpIndex: number = sourceProps.matches.data.competitions.findIndex(cp => { return cp.id === team.competitionId; });
					let competition: ICompetition = emptyCompetition;
					if (cpIndex >= 0) {
						competition = sourceProps.matches.data.competitions[cpIndex];
					}
					let lgIndex: number = sourceProps.matches.data.leagues.findIndex(lg => { return lg.id === competition.leagueId; });
					let league: ILeague = emptyLeague;
					if (lgIndex >= 0) {
						league = sourceProps.matches.data.leagues[lgIndex];
					}
					let activityTitle: string | undefined = undefined;
					let avSummary: IAvailabilitySummary | undefined = undefined;
					if (match.activityId > 0) {
						let acIndex = sourceProps.matches.data.activities.findIndex(ac => {return ac.id === match.activityId; });
						let activity: IActivity = emptyActivity;
						if (acIndex >= 0) {
							activity = sourceProps.matches.data.activities[acIndex];
							activityTitle = activity.title;
						}
						let activityAv: IAvailability[] = sourceProps.availability.filter(request => { return request.activityId === match.activityId; });
						avSummary = availabilitySummary(activityAv);
					}
					if (se.locked || se.index > 0) {
						items.push({
							key: "M" + se.matchId.toString(),
							date: matchDate,
							fm: {
								key: match.id.toString(),
								match: match,
								team: team,
								competition: competition,
								league: league,
								activityTitle: activityTitle,
							},
							selection: se,
							availabilitySummary: avSummary,
							readOnly: !sourceProps.auth.deviceOnLine,
							sex: competition.sex
						});
						if (match.activityId) {
							matchActivities.push(match.activityId);
						}
					} else {
						if (match.activityId) {
							matchActivitiesLocked.push(match.activityId);
						}
					}
				}
				mySelectionNo += 1;
			}
			// next process availability, igoring those already selected for
			let myAvailability: IAvailability[] = sourceProps.availability.filter(av => {
				return av.playerId === sourceProps.playerId && matchActivities.indexOf(av.activityId) < 0;
			});
			let myAvailabilityCnt: number = myAvailability.length;
			let myAvailabilityNo: number = 0;
			while (myAvailabilityNo < myAvailabilityCnt) {
				let av: IAvailability = myAvailability[myAvailabilityNo];
				let acIndex = sourceProps.matches.data.activities.findIndex(ac => {return ac.id === av.activityId; });
				let activity: IActivity = emptyActivity;
				if (acIndex >= 0) {
					activity = sourceProps.matches.data.activities[acIndex];
				}
				let cpIndex: number = sourceProps.matches.data.competitions.findIndex(cp => { return cp.id === activity.competitionId; });
				let competition: ICompetition = emptyCompetition;
				if (cpIndex >= 0) {
					competition = sourceProps.matches.data.competitions[cpIndex];
				}
				let activityDate: Date = parseDateString(activity.date);
				let activityDay = (activityDate.getFullYear() * 12 + activityDate.getMonth()) * 100 + activityDate.getDate();
				if (activityDay >= todayDay) {
					let activityAv: IAvailability[] = sourceProps.availability.filter(request => { return request.activityId === activity.id; });
					let avSummary: IAvailabilitySummary = availabilitySummary(activityAv);
					items.push({
						key: "A" + av.activityId.toString(),
						date: parseDateString(activity.date),
						activity: activity,
						availability: av,
						availabilitySummary: avSummary,
						readOnly: !sourceProps.auth.deviceOnLine || matchActivitiesLocked.indexOf(av.activityId) >= 0,
						sex: competition.sex
					});
				}
				myAvailabilityNo += 1;
			}
			return items.sort((a: IScheduleItem, b: IScheduleItem) => {
				return a.date.getTime() - b.date.getTime();
			});
		}	else {
			return items;
		}
	};

	let userProfile;
	if (props.auth.user && !props.auth.user.emailVerified) {
		userProfile = (
			<div style={{marginLeft: 10}}>
				<span
					className="fa fa-exclamation-sign"
					style={{
						fontSize: 75,
						color: "red",
						float: "left",
						marginRight: 20
					}}
				/>
				<p
					style={{
						fontSize: 18
					}}
				>
					Awaiting email confirmation
				</p>
				<p>
					Check your email, your account needs confirming before it is fully activiated.
					Refresh this page when you have followed the link in the email to confirm your account.
				</p>
			</div>
		);
	}

	if (props.playerId > 0 && props.matches.data && props.matches.data.activities && props.matches.data.activities.length > 0 && props.availability && props.availability.length > 0) {
		let myPlayer: IPlayer = emptyPlayer;
		let myPlayerIndex = props.players.findIndex(pl => { return pl.id === props.playerId; });
		if (myPlayerIndex >= 0) {
			myPlayer = props.players[myPlayerIndex];
		}

		let clubNextMatches;
		if (!props.isExternal) {
			clubNextMatches = <MatchWidget
				title={"Club Next Matches"}
				controlName="wgtClubNextMatches"
				matches={clubMatchesList(false)}
				hideRubbers={true}
			/>
		}

		let clubLastMatches;
		if (!props.isExternal) {
			clubLastMatches = <MatchWidget
				title={"Club Last Matches"}
				controlName="wgtClubLastMatches"
				matches={clubMatchesList(true)}
				sortDescending={true}
			/>
		}

		let AvProfile = (
			<div className="container body-content">
				<ScheduleWidget
					title={"My Schedule"}
					controlName="wgtMySchedule"
					playerId={props.playerId}
					player={myPlayer}
					items={scheduleList(props)}
					callback={availabilityCallback}
					openByDefault={true}
					callbackMatchDetails={matchDetailsCallback}
				/>

				<MatchWidget
					title={"My Results"}
					controlName="wgtMyMatches"
					matches={myMatchesList()}
					sortDescending={true}
					emptyMessage="You currently have no match results. Match results will only show here once they have been 
						correctly submitted on the LTA online system and have been confirmed by your team captain."
				/>

				{clubNextMatches}

				{clubLastMatches}

			</div>
		);
		return (
			<div>
				{AvProfile}
			</div>
		);
	} else if (userProfile) {
		return userProfile;
	} else {
		return (
			<div>
				<Loading message="loading..." size={ELoadingSize.large} delayed={true} delayInMs={1000} />
			</div>
		);
	}
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	playerId: state.players.myPlayerId,
	library: state.library,
	matches: state.matches,
	players: state.players.data.players,
	availability: state.availability.data.availability,
	selection: state.selection.data.selection,
	results: state.result.data.results,
	isExternal: state.players.isExternal,
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: {
			availability: bindActionCreators(AvailabilityActionCreators, dispatch),
			selection: bindActionCreators(SelectionActionCreators, dispatch),
			match: bindActionCreators(MatchActionCreators, dispatch),
			player: bindActionCreators(PlayerActionCreators, dispatch)
		}
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(Profile) as any;
