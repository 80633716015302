import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import * as AvailabilityStore from '../../store/availability';
import WrapperWidget from './fnWrapperWidget';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge'; 
import NotifyPlayerTable from './fnNotifyPlayerTable';
import { INotifyWidgetPlayer } from './fnNotifyPlayerRow';

interface INotifyWidgetProps {
	title: string;
	controlName: string;
	activityId: number;
	players: INotifyWidgetPlayer[];
	callback: (av: AvailabilityStore.IAvailability[], email: boolean) => void;
}

const NotifyWidget = (props: INotifyWidgetProps) => {
	const [players, setPlayers] = useState(props.players);

	useEffect (
		() => {
			setPlayers(props.players);
		},
		[props.players]
	);

	const onSelectAll = () => {
		setPlayers(
			players.map((player: INotifyWidgetPlayer) => {
				return { id: player.id, firstName: player.firstName, surname: player.surname, checked: true };
			})
		);
	};

	const onClearAll = () => {
		setPlayers(
			players.map((player: INotifyWidgetPlayer) => {
				return { id: player.id, firstName: player.firstName, surname: player.surname, checked: false };
			})
		);
	};

	const onSendSelected = () => {
		let playersToSend = players.filter((player: INotifyWidgetPlayer) => { return player.checked; });
		if (playersToSend.length > 0) {
			let av: AvailabilityStore.IAvailability[] = playersToSend.map((player: INotifyWidgetPlayer) => {
				return { activityId: props.activityId, playerId: player.id, status: AvailabilityStore.EAvailabilityStatus.Pending, notes: "" };
			});
			props.callback(av, true);
		}
	};

	const onFlagSelected = () => {
		let playersToSend = players.filter((player: INotifyWidgetPlayer) => { return player.checked; });
		if (playersToSend.length > 0) {
			let av: AvailabilityStore.IAvailability[] = playersToSend.map((player: INotifyWidgetPlayer) => {
				return { activityId: props.activityId, playerId: player.id, status: AvailabilityStore.EAvailabilityStatus.Pending, notes: "" };
			});
			props.callback(av, false);
		}
	};

	const onPlayerCheckChanged = (playerId: number, checked: boolean) => {
		setPlayers(
			players.map((player: INotifyWidgetPlayer) => {
				let plChecked = player.checked;
				if (player.id === playerId) {
					plChecked = checked;
				}
				return { id: player.id, firstName: player.firstName, surname: player.surname, checked: plChecked };
			})
		);
	};

	var badges: IWrapperWidgetBadge[] = [
		{ name: "", value: players.length.toString() }
	];

	let panelClass = "warning";
	return (
		<WrapperWidget title={props.title} controlName={props.controlName} badges={badges} panelClass={panelClass} glyph={"fa fa-envelope"} >
			<ButtonGroup style={{ width: "100%"}}>
				<Button onClick={onSelectAll}><i className="fa fa-check" /> Select All</Button>
				<Button onClick={onClearAll}><i className="fa fa-times" /> Clear All</Button>
				<Button onClick={onSendSelected}><i className="fa fa-envelope" /> Email Selected</Button>
				<Button onClick={onFlagSelected}><i className="fa fa-flag" /> Flag Selected</Button>
			</ButtonGroup>
			<NotifyPlayerTable activityId={props.activityId} players={players} callback={onPlayerCheckChanged} />
		</WrapperWidget>
	);
};

export default NotifyWidget;