import React from 'react';
import { IAuthState } from '../../store/auth';
import { IMatch, emptyMatch, IMatchFetchResult } from '../../store/match';
import { parseDateString, JSONstringifyDateReplacer, dateToString, EDateFormat } from '../../utils/dates';
import { ETimePickerMinuteSlots } from '../controls/dates/fnTimePicker';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button, Alert } from 'reactstrap';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import Loading, { ELoadingSize } from '../controls/spinners/fnLoading';
import { EApiResultType, ApiCheckResponseForError } from '../api';
import { AppConfig, IAppConfig } from '../../config/appConfig';
let config: IAppConfig = AppConfig();

interface IMatchEditProps {
	matchId: number;
	title: string;
	auth: IAuthState;
	onMatchSaved: (match: IMatch) => void;
	online: boolean;
}

interface IMatchEditState {
	match: IMatch;
	loading: boolean;
	changesMade: boolean;
}

const MatchEdit = (props: IMatchEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const [validate, setValidate] = React.useState(false);
	const [serverError, setServerError] = React.useState("");
	const [data, setData] = React.useState({
		match: emptyMatch,
		loading: false,
		changesMade: false
	} as IMatchEditState);

	const fetchRecord = () => {
		if (props.auth.user && props.matchId > 0) {
			// set loading flag
			setData(currData => { return {...currData, loading: true}; });
			// request data from server
			fetch(`${config.apiUrl}/match/details?matchId=${props.matchId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + props.auth.user.accessToken,
					"Pragma": "no-cache"
				},
			})
				.then(response => response.json() as Promise<IMatchFetchResult>)
				.then(recordFetched)
				.catch((error) => {
					setServerError(error.message);
				});
		} else {
			setServerError("not authorised");
		}
	};

	const recordFetched = (result: IMatchFetchResult) => {
		switch (result.result.resultType) {
			case EApiResultType.OK:
				setData({ match: result.data, loading: false, changesMade: false});
				break;
			case EApiResultType.Error:
				setServerError(result.result.errorMessage);
				break;
			default:
				setServerError("Match record not found");
				break;
		}
	};

	const recordSaved = (result: IMatchFetchResult) => {
		if (result.result.resultType === EApiResultType.OK) {
			props.onMatchSaved(result.data);
			hideModal();
		} else {
			setServerError(result.result.errorMessage);
		}
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			if (props.auth.user) {
				fetch(`${config.apiUrl}/match`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + props.auth.user.accessToken,
						"Pragma": "no-cache"
					},
					body: JSON.stringify(data.match, JSONstringifyDateReplacer)
				})
				.then(Response => {
					ApiCheckResponseForError(Response);
					return Response.json() as Promise<IMatchFetchResult>;
				})
				.then(recordSaved)
				.catch((error) => {
					setServerError(error.message);
				});
			}
		} else {
			setValidate(true);
		}
	};

	const showModal = () => {
		fetchRecord();
		setShowing(true);
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		setShowing(!showing);
	};

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				match: {
					...currData.match,
					[name]: value
				},
				changesMade: true 
			};
		});
	};

	const onMatchChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "completed") {
			value = e.currentTarget.checked;
		}
		setRecordValue(name, value);
	};

	const onDateChange = (date: Date) => {
		setRecordValue("date", dateToString(date, EDateFormat.webApi));
	};
		
	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		return errorMsg;
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	let controls: IEditControl[] = [];
	// date/time
	controls.push({
		id: "date",
		label: "Date/Time:",
		isCustom: true,
		inputType: "DateTimePicker",
		subType: ETimePickerMinuteSlots.five,
		value: parseDateString(data.match.date),
		onChange: onDateChange,
		onValidate: onValidate
	});
	// venue
	let venueOptions: any[] = [];
	venueOptions.push(<option value={"H"} key={"H"}>Home</option>);
	venueOptions.push(<option value={"A"} key={"A"}>Away</option>);
	controls.push({
		id: "venue",
		label: "Venue:",
		isCustom: true,
		inputType: "select",
		value: data.match.venue,
		onChange: onMatchChange,
		onValidate: onValidate,
		children: venueOptions
	});
	// notes
	controls.push({
		id: "notes",
		label: "Notes:",
		isCustom: false,
		inputType: "textarea",
		maxLength: 1000,
		rows: 8,
		value: data.match.notes,
		onChange: onMatchChange,
		onValidate: onValidate
	});
	// location
	controls.push({
		id: "location",
		label: "Location:",
		isCustom: false,
		inputType: "text",
		maxLength: 300,
		value: data.match.location,
		onChange: onMatchChange,
		onValidate: onValidate
	});
	// completed
	let completedControl: any[] = [];
	completedControl.push(
		<label className="switch" key="1">
			<input type="checkbox" id="completed" checked={data.match.completed} onChange={onMatchChange} />
			<span className="slider round" />
		</label>
	);
	controls.push({
		id: "completed",
		label: "Completed:",
		isCustom: true,
		inputType: "",
		maxLength: 0,
		value: data.match.completed,
		onValidate: onValidate,
		children: completedControl
	});

	let serverErrorCtrl;
	if (serverError !== "") {
		serverErrorCtrl = (
			<Alert color="danger">
				{serverError}
			</Alert>
		);
	}

	let loadingCtrl;
	if (data.loading) {
		if (serverErrorCtrl) {
			loadingCtrl = serverErrorCtrl;
		} else {
			// loading spinner and message will be displayed if loading takes more than 300 milliseconds
			loadingCtrl = <Loading message="fetching data from server" size={ELoadingSize.medium} delayed={true} delayInMs={300} />;
		}
	}

	// create controls with latest values
	//let controls: IEditControl[] = configureControls();

	return (
		<span>
			<Button color="secondary" onClick={showModal} disabled={!props.online}>
				<i className="fa fa-edit" /> Edit
			</Button>
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"} size="lg">
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody style={{ overflowY: data.loading ? 'hidden' : 'auto'}}>

					{/*  use overlay to hide edit controls until data is fetched from api */}
					<div className="modal-body-overlay" style={{ display: data.loading ? 'block' : 'none' }}>
						<div className="m-3">
							{loadingCtrl}
						</div>
					</div>

					{/*  show any errors returned from api save */}
					<div style={{ display: data.loading ? 'none' : 'block' }}>
						{serverErrorCtrl}
					</div>

					<EditControlSet controls={controls} validate={validate} />

				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={hideModal}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</span>
	);
};

export default MatchEdit;