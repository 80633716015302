import React from 'react';
import MatchListRow, { IMatchListItem } from './fnMatchListRow';

interface IMatchListTableProps {
	items: IMatchListItem[];
	onDrillDown: (focusId: number) => void;
}

const MatchListTable = (props: IMatchListTableProps) => {
	var rows: any[] = [];
	props.items.forEach((item) => {
		rows.push(
			<MatchListRow item={item} key={item.match.id} onDrillDown={props.onDrillDown} />
		);
	});

	return (
		<div className="table-responsive" style={{ borderWidth: 0 }}>
			<table className="table table-striped table-sm mt-2">
				<thead>
					<tr>
						<th>Date</th>
						<th>Team</th>
						<th className="text-center">Venue</th>
						<th>Opposition</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
};

export default MatchListTable;