import React from 'react';
import MatchPlayerRow, { IMatchPlayer } from './fnMatchPlayerRow';
import { IPlayer } from '../../store/player';
import { isIOS, isSafari, isMobileSafari } from "react-device-detect";

interface IMatchPlayerTableProps {
	players: IMatchPlayer[];
	captain: IPlayer;
	location: string;
	onDrillDown: (focusId: number) => void;
}

const MatchPlayerTable = (props: IMatchPlayerTableProps) => {
	var rows: any[] = [];
	let teamEmail: string = "";
	let teamSms: string = "";
	props.players.forEach((player) => {
		rows.push(<MatchPlayerRow player={player} key={player.id} onDrillDown={props.onDrillDown} />);
		if (player.email !== "") {
			if (teamEmail !== "") {
				teamEmail = teamEmail + ',';
			}
			teamEmail = teamEmail + player.email;
		}
		if (player.mobile !== "") {
			if (teamSms !== "") {
				teamSms = teamSms + ',';
			}
			teamSms = teamSms + player.mobile;
		}
	});
	let email;
	if (teamEmail !== "") {
		email = <a href={"mailto:" + teamEmail} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-envelope" style={{ fontSize: "30px", color: "gold" }} /></a>;
	}
	let text;
	if (teamSms !== "") {
		let href: string = "sms:" + teamSms;
		if (isIOS || isSafari || isMobileSafari) {
			href = "sms:/open?addresses=" + teamSms;
		}
		text = <a href={href} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-comment" style={{ fontSize: "30px", color: "green" }} /></a>;
	}

	if (rows.length > 0) {
		return (
			<table className="table table-striped">
				<thead>
					<tr>
						<th className="text-center" style={{ width: 15, paddingBottom: 15 }}>P</th>
						<th className="text-center" style={{ width: 15 }} />
						<th style={{ paddingBottom: 15 }}>Player</th>
						<th className="text-center" style={{ width: 45 }} />
						<th className="text-center" style={{ width: 45 }} >{text}</th>
						<th className="text-center" style={{ width: 45 }} >{email}</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		);
	} else {
		return (
			<div>
				<p>
					{"Your captain has not yet selected players for this match"}
				</p>
			</div>
		);
	}
};

export default MatchPlayerTable;