import React from 'react';
import ActivityListRow, { IActivityListItem } from './fnActivityListRow';

interface IActivityListTableProps {
	items: IActivityListItem[];
	onDrillDown: (focusId: number) => void;	
}

const ActivityListTable = (props: IActivityListTableProps) => {
	var rows: any[] = [];
	props.items.forEach((item) => {
		rows.push(
			<ActivityListRow item={item} key={item.activity.id} onDrillDown={props.onDrillDown} />
		);
	});

	return (
		<div>
			<div className="table-responsive" style={{ borderWidth: 0 }}>
				<table className="table table-striped table-sm mt-2">
					<thead>
						<tr>
							<th>Date</th>
							<th>Title</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ActivityListTable;