import React from 'react';
import { IAvailabilityWidgetPlayer } from './fnAvailabilityPlayerRow';
import AvailabilityPlayerTable from './fnAvailabilityPlayerTable';
import { IAvailabilityWidgetActivity } from './fnActivityRow';
import ActivityTable from './fnActivityTable';
import * as AvailabilityStore from '../../store/availability';

interface IAvailabilityTableProps {
	playerId?: number;
	activities?: IAvailabilityWidgetActivity[];
	activityId?: number;
	players?: IAvailabilityWidgetPlayer[];
	callback: (av: AvailabilityStore.IAvailability) => void;
}

const AvailabilityTable = (props: IAvailabilityTableProps) => {
	if (props.playerId && props.activities) {
		return <ActivityTable playerId={props.playerId} activities={props.activities} callback={props.callback} />;
	} else if (props.activityId && props.players) {
		return <AvailabilityPlayerTable activityId={props.activityId} players={props.players} callback={props.callback} />;
	} else {
		return null;
	}
};

export default AvailabilityTable;
