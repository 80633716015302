import React from 'react';
import { ITeam, ICompetition, ILeague } from '../../store/match';
import { Button } from 'reactstrap';

export interface ITeamListItem {
	team: ITeam;
	competition: ICompetition;
	league: ILeague;
}

interface ITeamListRowProps {
	item: ITeamListItem;
	onDrillDown: (focusId: number) => void;
}

const TeamListRow = (props: ITeamListRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.item.team.id);
	};

	let teamName: string = props.item.team.name;
	if (props.item.league.prefix !== "") {
		teamName = props.item.league.prefix + " " + teamName;
	}
	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{teamName}</Button></td>
			<td>{props.item.team.division}</td>
		</tr>
	);
};

export default TeamListRow;