import React from 'react';
import ActivityRow, { IAvailabilityWidgetActivity } from './fnActivityRow';
import * as AvailabilityStore from '../../store/availability';

interface IActivityTableProps {
	playerId: number;
	activities: IAvailabilityWidgetActivity[];
	callback: (av: AvailabilityStore.IAvailability) => void;
}

const ActivityTable = (props: IActivityTableProps) => {
	let activities = props.activities;
	activities.sort((a: IAvailabilityWidgetActivity, b: IAvailabilityWidgetActivity) => {
		if (a.date < b.date) {
			return -1;
		} else if (a.date > b.date) {
			return 1;
		} else {
			return 0;
		}
	});
	var rows: any[] = [];
	if (activities && activities.length > 0) {
		activities.forEach((activity) => {
			rows.push(
				<ActivityRow data={activity} key={activity.id} playerId={props.playerId} callback={props.callback} />
			);
		});
	}
	return (
		<table className="table table-striped table-sm">
			<thead>
				<tr>
					<th>Date</th>
					<th>Title</th>
					<th>Available</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	);
};

export default ActivityTable;
