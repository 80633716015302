import React from "react";
import { RingLoader } from 'react-spinners';
import Delay from '../delay/fnDelay';

interface ILoadingProps {
	message?: string;
	size?: ELoadingSize;
	delayed: boolean;
	delayInMs?: number;
}

export enum ELoadingSize {
	small= 1,
	medium= 2,
	large= 3
}

const Loading = (props: ILoadingProps) => {
	let message: string = "";
	if (props.message) {
		message = props.message;
	}
	let size: number = 150;
	if (props.size) {
		switch (props.size) {
			case ELoadingSize.small:
				size = 50;
				break;
			case ELoadingSize.medium:
				size = 120;
				break;
			case ELoadingSize.large:
				size = 190;
				break;
			default:
				size = 120;
				break;
		}
	}
	let delay: number = 0;
	if (props.delayed) {
		if (props.delayInMs) {
			delay = props.delayInMs;
		} else {
			delay = 250;
		}
	}
	return (
		<Delay delayInMs={delay} >
			<table
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				<tbody>
					<tr
						style={{
							verticalAlign: "center"
						}}
					>
						<td style={{ width: "50%" }} />
						<td>
							<RingLoader color={'#123abc'} size={size} />
							<div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>{message}</div>
						</td>
						<td style={{ width: "50%" }} />
					</tr>
				</tbody>
			</table>
		</Delay>
	);
};

export default Loading;
