import { IAppThunkAction } from './';
import { Action, Reducer } from 'redux';
import * as api from '../components/api';
import { AppConfig, IAppConfig } from '../config/appConfig';
let config: IAppConfig = AppConfig();

export interface IPlayersState {
	isLoading: boolean;
	fetched?: Date;
	data: IPlayerData;
	filter: IPlayerFilter;
	statsFilter: IStatsFilter;
	myPlayerId: number;
	myClubId: number;
	isPlayer: boolean;
	isTeamCaptain: boolean;
	isExternal: boolean;
	isSystemAdministrator: boolean;
}

export interface IPlayerData {
	version: number;
	players: IPlayer[];
	roles: IPlayerRole[];
	tags: IPlayerTag[];
}

interface IPlayerDataFetchResult {
	result: api.IApiResult;
	data: IPlayerData;
}

export interface IPlayer {
	id: number;
	clubId: number;
	firstName: string;
	surname: string;
	sex: string;
	email: string;
	mobile: string;
	ltaNumber: string;
	ltaRating: string;
	itfSinglesRating: string;
	itfDoublesRating: string;
	profileRef: string;
	archived: boolean;
	registered: boolean;
	isJunior: boolean;
	guardianEmail: string;
}

export interface IPlayerRole {
	playerId: number;
	roleId: number;
}

export interface IPlayerTag {
	playerId: number;
	tagId: number;
}

export interface IPlayerRecord {
	player: IPlayer;
	roles: number[];
	tags: number[];
	thisIsMe: boolean;
}

export interface IPlayerFetchResult {
	result: api.IApiResult;
	data: IPlayerRecord;
}

export interface IPlayerFilter {
	filtering: boolean;
	status: string;
	gender: string;
	tags: number[];
}

export interface IStatsFilter {
	filtering: boolean;
	competitions: number[];
}

export const emptyPlayer: IPlayer = {
	id: 0,
	clubId: 0,
	firstName: "",
	surname: "",
	sex: "M",
	email: "",
	mobile: "",
	ltaNumber: "",
	ltaRating: "10.2",
	itfSinglesRating: "",
	itfDoublesRating: "",
	profileRef: "",
	archived: false,
	registered: false,
	isJunior: false,
	guardianEmail: ""
};
const emptyPlayerFilter: IPlayerFilter = { filtering: false, status: "X", gender: "X", tags: [] };
const emptyStatsFilter: IStatsFilter = { filtering: false, competitions: [] };

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestPlayerData { type: 'REQUEST_PLAYER_DATA'; }
interface IPlayerDataLoaded { type: 'PLAYER_DATA_LOADED'; data: IPlayerData; }
interface IPlayerFilterSet { type: 'PLAYER_FILTER_SET';	filter: IPlayerFilter; }
interface IStatsFilterSet { type: 'STATS_FILTER_SET'; statsFilter: IStatsFilter; }
interface IRequestPlayerDetails { type: 'REQUEST_PLAYER_DETAILS'; }
interface IPlayerDetailsLoaded { type: 'PLAYER_DETAILS_LOADED'; player: IPlayerRecord; }
interface IPlayerReset { type: 'PLAYER_RESET'; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestPlayerData | IPlayerDataLoaded | IRequestPlayerDetails | IPlayerDetailsLoaded | 
	IPlayerFilterSet | IPlayerReset | IStatsFilterSet;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	// bulk player data request
	requestPlayerData: (accessToken: string, clubId: number): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/player/list?clubId=${clubId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<IPlayerDataFetchResult>)
			.then(data => {
				switch (data.result.resultType) {
					case api.EApiResultType.OK:
						dispatch({ type: 'PLAYER_DATA_LOADED', data: data.data });
						break;
					case api.EApiResultType.Error:
						console.log("Player data fetch: " + data.result.errorMessage);
						break;
					default:
						console.log("Player data not found");
						break;
				}
			})
			.catch((error) => { console.log(JSON.stringify(error)); });
		dispatch({ type: 'REQUEST_PLAYER_DATA' });
	},
	// individual player request from api
	requestPlayerDetails: (accessToken: string, userId: string, thisIsMe: boolean): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(`${config.apiUrl}/player/details?userId=${userId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + accessToken
			}
		})
			.then(response => response.json() as Promise<IPlayerFetchResult>)
			.then(response => {
				response.data.thisIsMe = thisIsMe;
				dispatch({ type: 'PLAYER_DETAILS_LOADED', player: response.data }); 
			})
			.catch(error => { console.log(JSON.stringify(error)); });
		dispatch({ type: 'REQUEST_PLAYER_DETAILS' });
	},
	playerDetailsLoaded: (player: IPlayerRecord): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'PLAYER_DETAILS_LOADED', player: player });
	},
	playerFilterSet: (filter: IPlayerFilter): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'PLAYER_FILTER_SET', filter: filter });
	},
	statsFilterSet: (statsFilter: IStatsFilter): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'STATS_FILTER_SET', statsFilter: statsFilter });
	},
	playerReset: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'PLAYER_RESET' });
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IPlayersState = {
	isLoading: false,
	data: { version: 0, players: [], roles: [], tags: [] },
	filter: emptyPlayerFilter,
	statsFilter: emptyStatsFilter,
	myPlayerId: 0,
	myClubId: 0,
	isPlayer: true,
	isTeamCaptain: false,
	isExternal: false,
	isSystemAdministrator: false
};

export const reducer: Reducer<IPlayersState> = (state: IPlayersState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_PLAYER_DATA':
			return Object.assign({}, { ...state }, { isLoading: true });
		case 'PLAYER_DATA_LOADED':
			return Object.assign({}, { ...state }, { data: action.data, isLoading: false });
		case 'PLAYER_DETAILS_LOADED':
			let myPlayerId: number = state.myPlayerId;
			let myClubId: number = state.myClubId;
			let isTeamCaptain: boolean = state.isTeamCaptain;
			let isExternal: boolean = state.isExternal;
			let isSystemAdministrator: boolean = state.isSystemAdministrator;
			if (action.player.thisIsMe) {
				myPlayerId = action.player.player.id;
				myClubId = action.player.player.clubId;
				isTeamCaptain = action.player.roles.indexOf(20) >= 0;
				isExternal = action.player.roles.indexOf(30) >= 0;
				isSystemAdministrator = action.player.roles.indexOf(99) >= 0;	
			}
			// players
			let players = state.data.players.filter((pl) => { return pl.id !== action.player.player.id;	});
			players.push(action.player.player);
			// roles
			let roles = state.data.roles.filter((rl) => { return rl.playerId !== action.player.player.id; });
			action.player.roles.forEach((rl) => { roles.push({ playerId: action.player.player.id, roleId: rl }); });
			// tags
			let tags = state.data.tags.filter((tg) => { return tg.playerId !== action.player.player.id; });
			action.player.tags.forEach((tg) => { tags.push({ playerId: action.player.player.id, tagId: tg }); });
			return Object.assign({}, { ...state }, { data: Object.assign({}, { ...state.data }, { players: players, roles: roles, tags: tags }) }, { myPlayerId: myPlayerId, myClubId: myClubId, isTeamCaptain: isTeamCaptain, isExternal: isExternal, isSystemAdministrator: isSystemAdministrator } );
		case 'PLAYER_FILTER_SET':
			return Object.assign({}, { ...state }, { filter: action.filter });
		case 'STATS_FILTER_SET':
			return Object.assign({}, { ...state }, { statsFilter: action.statsFilter });
		case 'PLAYER_RESET':
			return Object.assign({}, { ...state }, { myPlayerId: 0 });
		default:
			return state;
	}
};