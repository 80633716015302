
export interface IApiResult {
	resultType: EApiResultType;
	errorMessage: string;
}

export enum EApiResultType {
	OK,
	Empty,
	Error
}

export function ApiCheckResponseForError(result: any) {
	if (!result.ok) {
		let errorMsg: string = result.statusText;
		if (result.status === 403) {
			errorMsg = "You do not have the permission to perform this action. Please ask the system administrator for help.";
		}
		throw Error(errorMsg);
	}
}