import React from 'react';
import { ILibraryTag } from '../../store/library';
import { IPlayerFilter } from '../../store/player';
import { ChecklistBoxSelectionAsString } from '../controls/modal/fnChecklistBox';
import { IChecklistItem } from '../controls/modal/fnChecklistBoxItem';
import EditControlSet from '../controls/modal/fnEditControlSet';
import { IEditControl } from '../controls/modal/fnEditControl';
import { Modal,	ModalHeader, ModalBody,	ModalFooter, Button } from 'reactstrap';

interface IPlayerFilterEditProps {
	title: string;
	filter: IPlayerFilter;
	tags: ILibraryTag[];
	onFilterSaved: (filter: IPlayerFilter) => void;
}

interface IPlayerFilerEditData {
	filter: IPlayerFilter;
	selectedTags: IChecklistItem[];
	changesMade: boolean;
}

const PlayerFilterEdit = (props: IPlayerFilterEditProps) => {
	const [showing, setShowing] = React.useState(false);
	const initialDataState: IPlayerFilerEditData = {
		filter: props.filter,
		selectedTags: props.tags.map((tag) => {
			return {
				text: tag.tag,
				value: tag.id.toString(),
				checked: props.filter.tags.indexOf(tag.id) >= 0
			};
		}),
		changesMade: false
	};
	const [data, setData] = React.useState(initialDataState);

	const revertStatusToProps = () => {
		if (data.changesMade) {
			setData(initialDataState);
		}
	};

	const hideModal = () => {
		setShowing(false);
	};

	const toggle = () => {
		revertStatusToProps();
		setShowing(!showing);
	};

	const saveChanges = () => {
		if (validateAll() === true) {
			props.onFilterSaved(data.filter);
			hideModal();
		}
	};

	const cancelChanges = () => {
		revertStatusToProps();
		hideModal();
	};

	const setRecordValue = (name: string, value: any) => {
		setData(currData => {
			return {
				...currData,
				filter: {
					...currData.filter,
					[name]: value
				},
				changesMade: true 
			};
		});
	};

	const onFilterControlChange = (e: any) => {
		let name = e.currentTarget.id;
		let value = e.currentTarget.value;
		if (name === "filtering") {
			setRecordValue(name, e.currentTarget.checked);
		} else {
			setRecordValue(name, value);
		}
	};

	const onTagsUpdated = (items: IChecklistItem[]) => {
		let tagIds: number[] = [];
		items.forEach((tag) => {
			if (tag.checked) {
				tagIds.push(Number(tag.value));
			}
		});
		setData(currData => {
			return {
				...currData,
				filter: {
					...currData.filter,
					tags: tagIds
				},
				selectedTags: items,
				changesMade: true 
			};
		});
	};

	const onValidate = (control: IEditControl) => {
		let errorMsg = "";
		switch (control) {
			default:
				break;
		}
		return errorMsg;
	};

	const validateAll = () => {
		let errMessage = "";
		let controlCount = controls.length;
		let controlNo: number = 0;
		while (controlNo < controlCount && errMessage === "") {
			errMessage = onValidate(controls[controlNo]);
			if (errMessage !== "") {
				break;
			} else {
				controlNo += 1;
			}
		}
		return errMessage === "";
	};

	let controls: IEditControl[] = [];
	// filtering
	let filteringControl: any[] = [];
	filteringControl.push(
		<label className="switch" key="1">
			<input type="checkbox" id="filtering" checked={data.filter.filtering} onChange={e => onFilterControlChange(e)} />
			<span className="slider round" />
		</label>
	);
	controls.push({
		id: "filtering",
		label: "Filtering:",
		isCustom: true,
		inputType: "",
		maxLength: 0,
		value: data.filter.filtering,
		onChange: onFilterControlChange,
		onValidate: onValidate,
		children: filteringControl
	});
	// status
	let statusOptions: any[] = [];
	statusOptions.push(<option value="X" key="X">Exclude archived</option>);
	statusOptions.push(<option value="I" key="I">Include archived</option>);
	statusOptions.push(<option value="O" key="O">Archived only</option>);
	controls.push({
		id: "status",
		label: "Status:",
		isCustom: true,
		inputType: "select",
		value: data.filter.status,
		onChange: onFilterControlChange,
		onValidate: onValidate,
		children: statusOptions
	});
	// gender
	let genderOptions: any[] = [];
	genderOptions.push(<option value="M" key="M">Male</option>);
	genderOptions.push(<option value="F" key="F">Female</option>);
	genderOptions.push(<option value="X" key="X">Either</option>);
	controls.push({
		id: "gender",
		label: "Gender:",
		isCustom: true,
		inputType: "select",
		value: data.filter.gender,
		onChange: onFilterControlChange,
		onValidate: onValidate,
		children: genderOptions
	});
	// tags
	controls.push({
		id: "tags",
		label: "Tags:",
		title: "Player Tags",
		isCustom: true,
		inputType: "ChecklistBox",
		value: ChecklistBoxSelectionAsString(data.selectedTags),
		children: data.selectedTags,
		callback: onTagsUpdated
	});

	return (
		<div>
			<Button color={props.filter.filtering ? "warning" : "secondary"} onClick={toggle}>
				<i className="fa fa-filter" />{props.filter.filtering ? "Filtering..." : "Filter"}
			</Button>
			<Modal isOpen={showing} toggle={toggle} backdrop={"static"}>
				<ModalHeader toggle={toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody>
					<EditControlSet controls={controls} validate={true} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" onClick={saveChanges} disabled={!data.changesMade}>Save</Button>{' '}
					<Button color="secondary" onClick={cancelChanges}>Cancel</Button>					
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default PlayerFilterEdit;
