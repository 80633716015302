import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { ITeamFilter, ITeam, ILeague, ICompetition, actionCreators as matchActionCreators,
	emptyLeague, emptyCompetition } from '../../store/match';
import { IAuthState } from '../../store/auth';
import { bindActionCreators } from "redux";
import TeamFilterEdit from '../team/fnTeamFilterEdit';
import { ITeamListItem } from './fnTeamListRow';
import TeamListTable from './fnTeamListTable';

interface ITeamListProps {
	auth: IAuthState;
	teams: ITeam[];
	competitions: ICompetition[];
	leagues: ILeague[];
	filter: ITeamFilter;
	actions: typeof matchActionCreators;
}

type TTeamListProps =
	ITeamListProps
	& RouteComponentProps<{}>;

const TeamList = (props: TTeamListProps) => {

	const onFilterSaved = (filter: ITeamFilter) => {
		props.actions.teamFilterSet(filter);
	};

	const onDrillDown = (focusId: number) => {
		let teamIds: number[] = items.map((item) => { return item.team.id; });
		props.history.push("/team/details/" + focusId.toString(), { "ids": teamIds });
	};

	// if not filtering get default position;
	let teamFilter: ITeamFilter = {
		filtering: props.filter.filtering,
		competitions: props.filter.competitions
	};
	if (!teamFilter.filtering) {
		teamFilter.competitions = [];
	}
	let items: ITeamListItem[] = [];
	props.teams.forEach((team) => {
		let competition: ICompetition = emptyCompetition;
		let competitionIdx: number = props.competitions.findIndex((cp) => { return cp.id === team.competitionId; });
		if (competitionIdx >= 0) {
			competition = props.competitions[competitionIdx];
		}
		let league: ILeague = emptyLeague;
		let leagueIndex: number = props.leagues.findIndex((lg) => { return lg.id === competition.leagueId; });
		if (leagueIndex >= 0) {
			league = props.leagues[leagueIndex];
		}
		if (teamFilter.competitions.length === 0 || teamFilter.competitions.indexOf(team.competitionId) >= 0) {
			items.push({ team: team, competition: competition, league: league });
		}
	});
	items.sort((a: ITeamListItem, b: ITeamListItem) => {
		let nameA: string = a.league.prefix + " " + a.team.name;
		let nameB: string = b.league.prefix + " " + b.team.name;
		if (nameA < nameB) {
			return -1;
		} else if (nameA > nameB) {
			return 1;
		} else {
			return 0;
		}
	});

	return (
		<div className="container body-content">
			<TeamFilterEdit title="Team Filter" filter={props.filter} competitions={props.competitions} onFilterSaved={onFilterSaved} />
			<TeamListTable items={items} onDrillDown={onDrillDown} />
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	teams: state.matches.data.teams,
	competitions: state.matches.data.competitions,
	leagues: state.matches.data.leagues,
	filter: state.matches.teamFilter
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(matchActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(TeamList) as any;
