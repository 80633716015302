import React from 'react';
import { Button } from 'reactstrap';
import { formatDateString, EDateFormat } from '../../utils/dates';

export interface ITeamMatch {
	id: number;
	date: string;
	opposition: string;
	venue: string;
	result: string;
	points: number;
}

interface ITeamMatchRowProps {
	match: ITeamMatch;
	onDrillDown: (focusId: number) => void;
}

const TeamMatchRow = (props: ITeamMatchRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.match.id);
	};

	let dateStr: string = formatDateString(props.match.date, EDateFormat.dayMonthWithTime24H);
	let points: string = "-";
	if (props.match.result !== "") {
		points = props.match.points.toString();
	}

	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{dateStr}</Button></td>
			<td>{props.match.opposition}</td>
			<td className="text-center">{props.match.venue}</td>
			<td className="text-center">{points}</td>
		</tr>
	);
};

export default TeamMatchRow;
