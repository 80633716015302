import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import WrapperWidget from './fnWrapperWidget';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';
import { withRouter } from 'react-router-dom';
import { IMatchWidgetMatch } from './fnMatchRow';
import MatchTable from './fnMatchTable';

interface IMatchWidgetProps {
	title: string;
	controlName: string;
	matches: IMatchWidgetMatch[];
	openByDefault?: boolean;
	hideRubbers?: boolean;
	emptyMessage?: string;
	sortDescending?: boolean;
}

type TMatchWidgetProps = IMatchWidgetProps & RouteComponentProps<{}>;

const MatchWidget = (props: TMatchWidgetProps) => {

	const onDrillDown = (focusId: number) => {
		let matchIds: number[] = props.matches.map((ma) => { return ma.id; });
		if (props.history) {
			props.history.push("/match/details/" + focusId.toString(), { ids: matchIds });
		}
	};

	let badges: IWrapperWidgetBadge[] = [
		{ name: "", value: props.matches.length.toString() }
	];
		
	return (
		<WrapperWidget title={props.title} controlName={props.controlName} badges={badges} panelClass={"primary"} openByDefault={props.openByDefault} >
			<MatchTable matches={props.matches} onDrillDown={onDrillDown} emptyMessage={props.emptyMessage} hideRubbers={props.hideRubbers} sortDescending={props.sortDescending} />
		</WrapperWidget>
	);
};

export default withRouter(connect()(MatchWidget)) as any;
// export default connect()(MatchWidget) as any;
