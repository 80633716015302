import React from 'react';
import * as AvailabilityStore from '../../store/availability';
import WrapperWidget from './fnWrapperWidget';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';
import { IAvailabilityWidgetPlayer } from './fnAvailabilityPlayerRow';
import { IAvailabilityWidgetActivity } from './fnActivityRow';
import AvailabilityTable from './fnAvailabilityTable';

interface IAvailabilityWidgetProps {
	title: string;
	controlName: string;
	playerId?: number;
	activities?: IAvailabilityWidgetActivity[];
	activityId?: number;
	players?: IAvailabilityWidgetPlayer[];
	callback: (av: AvailabilityStore.IAvailability) => void;
	singleCount?: boolean;
	panelClass?: string;
	glyph?: string;
	openByDefault?: boolean;
}

const AvailabilityWidget = (props: IAvailabilityWidgetProps) => {
	let available = 0;
	let unavailable = 0;
	let maybe = 0;
	let pending = 0;

	let av;
	if (props.activities) {
		av = props.activities.map((ac) => { return ac.status; });
	} else if (props.players) {
		av = props.players.map((pl) => { return pl.status; });
	}
	if (av) {
		av.forEach((status: AvailabilityStore.EAvailabilityStatus) => {
			switch (status) {
				case AvailabilityStore.EAvailabilityStatus.Available:
					available += 1;
					break;
				case AvailabilityStore.EAvailabilityStatus.Unavailable:
					unavailable += 1;
					break;
				case AvailabilityStore.EAvailabilityStatus.Maybe:
					maybe += 1;
					break;
				default:
					pending += 1;
					break;
			}
		});
	}

	let panelClass = "primary";
	if (props.panelClass) {
		panelClass = props.panelClass;
	} else if (pending > 0) {
		panelClass = "danger";
	}

	var badges: IWrapperWidgetBadge[] = [];
	if (props.singleCount) {
		badges.push({ name: "", value: (available + unavailable + maybe + pending).toString() });
	} else {
		badges.push({ name: "Yes", value: available.toString() });
		badges.push({ name: " No", value: unavailable.toString() });
		badges.push({ name: " Maybe", value: maybe.toString() });
		badges.push({ name: " ?", value: pending.toString() });
	}

	return (
		<WrapperWidget title={props.title} controlName={props.controlName} badges={badges} panelClass={panelClass} glyph={props.glyph} openByDefault={props.openByDefault} >
			<AvailabilityTable playerId={props.playerId} activities={props.activities} activityId={props.activityId} players={props.players} callback={props.callback} />
		</WrapperWidget >
	);
};

export default AvailabilityWidget;
