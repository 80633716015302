import React, { useEffect } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';

interface IChecklistBoxItemProps {
	item: IChecklistItem;
	callback: (value: string, checked: boolean) => void;
}

export interface IChecklistItem {
	text: string;
	value: string;
	checked: boolean;
	readOnly?: boolean;
}

const ChecklistBoxItem = React.memo(
(props: IChecklistBoxItemProps) => {
	const [checked, setChecked] = React.useState(props.item.checked);

	useEffect(
		() => {
			setChecked(props.item.checked);
		},
		[props.item.checked],
	);

	const onChange = (e: any) => {
		props.callback(props.item.value, e.target.checked);
		setChecked(e.target.checked);
	};

	return (
		<FormGroup>
			<CustomInput
				type="checkbox"
				className="mb1"
				label={props.item.text}
				checked={checked}
				onChange={onChange}
				id={props.item.value}
				style={{ marginLeft: 0 }}
				disabled={props.item.readOnly}
			/>
		</FormGroup>
	);
},
(prevProps: IChecklistBoxItemProps, nextProps: IChecklistBoxItemProps) => {
	let same: boolean = false;
	same = prevProps.item.text === nextProps.item.text
		&& prevProps.item.value === nextProps.item.value
		&& prevProps.item.checked === nextProps.item.checked;
	return same;
});

export default ChecklistBoxItem;