import React from 'react';
import AvailabilityPlayerRow, { IAvailabilityWidgetPlayer } from './fnAvailabilityPlayerRow';
import * as AvailabilityStore from '../../store/availability';
import { isIOS, isSafari, isMobileSafari } from "react-device-detect";

interface IAvailabilityPlayerTableProps {
	activityId: number;
	players: IAvailabilityWidgetPlayer[];
	callback: (av: AvailabilityStore.IAvailability) => void;
}

const AvailabilityPlayerTable = (props: IAvailabilityPlayerTableProps) => {
	var rows: any[] = [];
	let playerEmails: string = "";
	let playerMobiles: string = "";
	if (props.players && props.players.length > 0) {
		props.players.forEach((player) => {
			rows.push(
				<AvailabilityPlayerRow data={player} key={player.id} activityId={props.activityId} callback={props.callback} />
			);
			// update emails list	
			if (player.email !== "") {
				if (playerEmails !== "") {
					playerEmails = playerEmails + ',';
				}
				playerEmails = playerEmails + player.email;
			}
			// update mobiles list
			if (player.mobile !== "") {
				if (playerMobiles !== "") {
					playerMobiles = playerMobiles + ',';
				}
				playerMobiles = playerMobiles + player.mobile;
			}
		});
	}
	let email;
	if (playerEmails !== "") {
		email = <a href={"mailto:" + playerEmails} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-envelope" style={{ fontSize: "30px", color: "gold" }} /></a>;
	}
	let text;
	if (playerMobiles !== "") {
		let href: string = "sms:" + playerMobiles;
		if (isIOS || isSafari || isMobileSafari) {
			href = "sms:/open?addresses=" + playerMobiles;
		}
		text = <a href={href} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-comment" style={{ fontSize: "30px", color: "green" }} /></a>;
	}
	return (
		<table className="table table-striped table-sm">
			<thead>
				<tr>
					<th>Player</th>
					<th>Available</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
			<tfoot>
				<tr>
					<td>
						{email}
						{text}
					</td>
					<td />
				</tr>
			</tfoot>
		</table>
	);
};

export default AvailabilityPlayerTable;
