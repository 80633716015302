import React from 'react';
import {
	FormGroup,
	FormFeedback,
	Label,
	Col,
	Input,
	CustomInput
} from 'reactstrap';
import ChecklistBox from './fnChecklistBox';
import DateTimePicker from '../dates/fnDateTimePicker';

export interface IEditControl {
	id: string;
	label: string;
	title?: string;
	icon?: string;
	isCustom?: boolean;
	inputType: any;
	subType?: number;
	maxLength?: number;
	rows?: number;
	value: any;
	children?: any[];
	onChange?: (e: any) => void;
	onValidate?: (control: IEditControl) => string;
	callback?: (e: any) => void;
}

interface IEditControlProps {
	control: IEditControl;
	validate: boolean;
}

const EditControl = React.memo(
(props: IEditControlProps) => {
	let invalid: boolean = false;
	let errorMsg: string = "";
	let errorControl;
	if (props.validate && props.control.onValidate) {
		errorMsg = props.control.onValidate(props.control);
		if (errorMsg && errorMsg !== "") {
			invalid = true;
			errorControl = <FormFeedback>{errorMsg}</FormFeedback>;
		}
	}
	let inputControl;
	if (props.control.isCustom) {
		if (props.control.inputType === "") {
			// bespoke control
			inputControl = (
				<div>
					{props.control.children}
				</div>
			);
		} else if (props.control.inputType === "DateTimePicker") {
			// custom date/time picker control
			inputControl = (
				<DateTimePicker
					date={props.control.value}
					onDateChange={props.control.onChange}
					minuteSlots={props.control.subType}
				/>
			);
		} else if (props.control.inputType === "ChecklistBox") {
			// custom checklist box control
			inputControl = (
				<ChecklistBox
					title={props.control.title ? props.control.title : "Select option(s)"}
					items={props.control.children ? props.control.children : []}
					callback={props.control.callback}
					icon={props.control.icon}
					errorMsg={errorMsg}
				/>
			);
		} else if (props.control.inputType === "switch") {
			// reactstrap swich control - TO DO!
			inputControl = (
				<CustomInput
					type={props.control.inputType}
					id={props.control.id}
					value={props.control.value}
					maxLength={props.control.maxLength}
					onChange={props.control.onChange}
					invalid={invalid}
					bsSize="lg"
				>
					{props.control.children}
				</CustomInput>
			);
		} else {
			// reactstrap custom control
			inputControl = (
				<CustomInput
					type={props.control.inputType}
					id={props.control.id}
					value={props.control.value}
					maxLength={props.control.maxLength}
					onChange={props.control.onChange}
					invalid={invalid}
				>
					{props.control.children}
				</CustomInput>
			);
		}
	} else {
		// reactstrap standard control
		inputControl = (
			<Input
				type={props.control.inputType}
				id={props.control.id}
				value={props.control.value}
				maxLength={props.control.maxLength}
				onChange={props.control.onChange}
				invalid={invalid}
			>
				{props.control.children}
			</Input>
		);
	}

	return (
		<FormGroup row={true} key={props.control.id}>
			<Label for={props.control.id} sm={3}>{props.control.label}</Label>
			<Col sm={9}>
				{inputControl}
				{errorControl}
			</Col>
		</FormGroup>
	);
},
(prevProps: IEditControlProps, nextProps: IEditControlProps) => {
	let same: boolean = prevProps.validate === nextProps.validate;
	if (same) {
		if (prevProps.control.value !== undefined && nextProps.control.value !== undefined) {
			same = prevProps.control.value === nextProps.control.value;
		}
	}
	if (same) {
		if (prevProps.control.children && nextProps.control.children) {
			same = prevProps.control.children.length === nextProps.control.children.length;
		}
	}
	return same;
});

export default EditControl;