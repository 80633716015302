import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/fnLayout';
import Profile from './components/profile/fnProfile';
import TeamList from './components/team/fnTeamList';
import TeamDetails from './components/team/fnTeamDetails';
import MatchList from './components/match/fnMatchList';
import MatchDetails from './components/match/fnMatchDetails';
import ActivityList from './components/activity/fnActivityList';
import ActivityDetails from './components/activity/fnActivityDetails';
import PlayerList from './components/player/fnPlayerList';
import PlayerStats from './components/player/fnPlayerStats';
import PlayerDetails from './components/player/fnPlayerDetails';
import Cache from './components/api/fnCache';
import Callback from './components/fnCallback';

const App = () => (
	<Layout>
		<Switch>
			<Route exact={true} path='/' component={Profile} />
			<Route path='/profile' component={Profile} />
			<Route path='/team/list' component={TeamList} />
			<Route path='/team/details/:teamId?' component={TeamDetails} />
			<Route path='/match/list' component={MatchList} />
			<Route path='/match/details/:matchId?' component={MatchDetails} />
			<Route path='/activity/list' component={ActivityList} />
			<Route path='/activity/details/:activityId?' component={ActivityDetails} />
			<Route path='/player/list' component={PlayerList} />
			<Route path='/player/stats' component={PlayerStats} />
			<Route path='/player/details/:playerId?' component={PlayerDetails} />
			{/* fallback for situation where url is not known or callback from auth system */}
			<Route component={Callback} />
		</Switch>
		{/* data cache checking runs for all pages */}
		<Route path='/' component={Cache} />
	</Layout>
);

export default App;