import React, { useState } from 'react';
import * as AvailabilityStore from '../../store/availability';
import WrapperWidget from './fnWrapperWidget';
import { IWrapperWidgetBadge } from './fnWrapperWidgetBadge';
import ScheduleItem, { IScheduleItem } from '../profile/fnScheduleItem';
import { IPlayer } from '../../store/player';
import { Form, Label } from 'reactstrap';

interface IScheduleWidgetProps {
	title: string;
	controlName: string;
	playerId: number;
	player: IPlayer;
	items: IScheduleItem[];
	callback: (av: AvailabilityStore.IAvailability) => void;
	singleCount?: boolean;
	panelClass?: string;
	glyph?: string;
	openByDefault?: boolean;
	callbackMatchDetails: (matchId: number) => void;
}

const ScheduleWidget = (props: IScheduleWidgetProps) => {
	const [includePractice, setIncludePractice] = useState(true);

	const onIncludePracticeChange = (e: any) => {
		setIncludePractice(e.currentTarget.checked);
	};

	let available = 0;
	let unavailable = 0;
	let pending = 0;

	props.items.forEach((item: IScheduleItem) => {
		if (item.availability) {
			switch (item.availability.status) {
				case AvailabilityStore.EAvailabilityStatus.Available:
					available += 1;
					break;
				case AvailabilityStore.EAvailabilityStatus.Unavailable:
					unavailable += 1;
					break;
				default:
					pending += 1;
					break;
			}
		}
	});

	let panelClass = "primary";
	if (props.panelClass) {
		panelClass = props.panelClass;
	} else if (pending > 0) {
		panelClass = "danger";
	}

	var badges: IWrapperWidgetBadge[] = [];
	if (props.singleCount) {
		badges.push({ name: "", value: (available + unavailable + pending).toString() });
	} else {
		badges.push({ name: "Yes", value: available.toString() });
		badges.push({ name: " No", value: unavailable.toString() });
		badges.push({ name: " ?", value: pending.toString() });
	}

	var scheduleItems: any[] = [];
	let itemNo: number = 0;
	props.items.forEach((item) => {
		let isPractice: boolean = item.activity !== undefined && item.activity.type !== "Match";

		if (!isPractice || includePractice) {
			itemNo += 1;
			scheduleItems.push(
				<ScheduleItem key={itemNo} item={item} playerId={props.playerId} player={props.player} callback={props.callback} callbackMatchDetails={props.callbackMatchDetails}/>
			);
		}
	});

	let noSchedule;
	if (scheduleItems.length === 0) {
		noSchedule = (
			<div>
				<p>
					{"You currently have no matches in your schedule. If the season is not yet finished please contact your team captain who can add you to the availability lists"}
				</p>
			</div>
		);
	}
	
	return (
		<WrapperWidget title={props.title} controlName={props.controlName} badges={badges} panelClass={panelClass} glyph={props.glyph} openByDefault={props.openByDefault} >
			<div style={{ float: "right" }} className="mt-1">
				<a
					href={"https://competitions.lta.org.uk/player-profile/" + props.player.profileRef}
					target="_blank"
					rel="noopener noreferrer"
					className="mr-2"
				>
					<i className="fa fa-id-badge fa-lg" style={{ marginRight: "5px" }} />
					{"Profile"}
				</a>
			</div>
			<Form inline className="mb-3 ml-2">
				<Label for="includePractice" className="mr-2">Include practice sessions</Label>
				<label className="switch" key="1">
					<input type="checkbox" id="includePractice" checked={includePractice} onChange={onIncludePracticeChange} />
					<span className="slider round" />
				</label>
	    </Form>
			{scheduleItems}
			{noSchedule}
		</WrapperWidget >
	);
};

export default ScheduleWidget;