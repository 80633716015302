import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import { IUser } from '../store/auth';
import Delay from '../components/controls/delay/fnDelay';

interface IOfflineProps {
	onLine: boolean;
	isLoggedIn: boolean;
	user?: IUser;
	cachedDataLoaded: boolean;
	playerId: number;
}

const Offline = (props: IOfflineProps) => {
	let offline: string = "";

	if (props.onLine) {
		if (!props.isLoggedIn) {
			offline = "You are not logged in, use the main menu to login or continue to work offline";
		}
	} else {
		offline = "You are offline and in read only mode. Your data will be refreshed when you go back online";
	}

	if (offline !== "" && props.playerId > 0) {
		return (
			<Delay delayInMs={1500}>
				<div className="bottom-status-bar">
					{offline}
				</div>
			</Delay>
		);
	} else {
		return null;
	}
};

const mapStateToProps = (state: IApplicationState) => ({
	onLine: state.auth.deviceOnLine,
	isLoggedIn: state.auth.isLoggedIn,
	user: state.auth.user,
	cachedDataLoaded: state.storage.loadedFromLocalStorage,
	playerId: state.players.myPlayerId
});

export default connect(
	mapStateToProps // Selects which state properties are merged into the component's props
)(Offline) as any;
