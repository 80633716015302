import React, { useState } from "react";
import {
	Button,
	Input,
	InputGroup,
	InputGroupAddon,
	Popover
} from 'reactstrap';
import DayPicker from "react-day-picker";
import { DayModifiers } from "react-day-picker/types";
import "react-day-picker/lib/style.css";
import { getDateParts, IDateParts, parseDateString, EDateFormat, dateToString, getNewDate, EDateParseFormat } from '../../../utils/dates';

interface IDatePickerProps {
	date: Date;
	onDateChange?: (date: Date) => void;
	maxWidth?: number;
}

export default React.memo(
(props: IDatePickerProps) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [date, setDate] = useState(props.date);
	const [dateStr, setDateStr] = useState(dateToString(props.date, EDateFormat.dateShort));

	React.useEffect(
		() => {
			setDate(props.date);
			setDateStr(dateToString(props.date, EDateFormat.dateShort));
		},
		[props.date]
	);

	const formatDate = (inDate: Date): string => {
		return dateToString(inDate, EDateFormat.dateShort);
	};

	const onDateStrChange = (e: any) => {
		let value = e.currentTarget.value;
		let autoDate: Date = keepTime(parseDateString(value, EDateParseFormat.DMY));
		setDate(autoDate);
		setDateStr(value);
	};

	const onCalendarDayClick = (day: Date, modifiers: DayModifiers, e: any) => {
		let newDate = keepTime(day);
		if (props.onDateChange) {
			props.onDateChange(newDate);
		}
		setShowCalendar(false);
		setDate(newDate);
		setDateStr(formatDate(newDate));
	};

	const onLoseFocus = () => {
		let newDateStr = formatDate(date);
		if (newDateStr !== dateStr) {
			if (props.onDateChange) {
				props.onDateChange(date);
			}
			setDateStr(newDateStr);
		}
	};

	const toggleCalendar = () => {
		setShowCalendar(!showCalendar);
	};

	const keepTime = (inDate: Date): Date => {
		let parts: IDateParts = getDateParts(props.date);
		return getNewDate(inDate.getFullYear(), inDate.getMonth() + 1, inDate.getDate(), parts.hour, parts.minute, 0);	
	};

	return (
		<span style={{ position: "relative" }}>
			<InputGroup>
				<Input
					type="text"
					value={dateStr}
					// ref={(input) => { overlayTarget = input; }}
					onChange={onDateStrChange}
					onBlur={onLoseFocus}
					id="poppy"
				/>
				<InputGroupAddon addonType="append">
					<Button color="secondary" onClick={toggleCalendar}><i className="fa fa-calendar" /></Button>
				</InputGroupAddon>
			</InputGroup>
			<Popover
				placement="bottom-start"
				isOpen={showCalendar}
				target={"poppy"}
				toggle={toggleCalendar}
				hideArrow={true}
				trigger=""
			>
				<div className="default" style={{ position: "absolute", zIndex: 100, background: "aliceblue", marginTop: -5, marginLeft: -2, borderRadius: "5px" }}>
					<DayPicker
						firstDayOfWeek={1}
						onDayClick={onCalendarDayClick}
						month={date}
						selectedDays={date}
					/>
				</div>
			</Popover>
		</span>
	);
},
(prevProps: IDatePickerProps, nextProps: IDatePickerProps) => {
	let same: boolean = false;
	same = prevProps.date.getTime() === nextProps.date.getTime();
	return same;
});
