/*global Offline*/
/*eslint no-undef: "error"*/
import "react-app-polyfill/ie11";
import 'react-app-polyfill/stable';
import './custom.scss';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from './store/configureStore';
import App from './App';
import 'offline-js';
import { UserControl } from './userControl';
import { AppConfig, IAppConfig } from './config/appConfig';
let config: IAppConfig = AppConfig();

// set up application Redux store
const store = configureStore();

// configure offline checking - force the 3rd party s/w to use the relatiave path to our favicon
let offlineChecks: any = {
	image: {
		url:function() {
			if (config.offlineImageOverride && config.offlineImageOverride !== "")
			{
				return config.offlineImageOverride + "?_=" + new Date().getTime();
			}
			else
			{
				return config.relativePath + "/favicon.ico?_=" + new Date().getTime();
			}
		}
	},	
	active: 'image'
}
Offline.options.checks = offlineChecks;

// capture event for connectivity coming back online
const deviceGoneOnLine = () => {
	store.dispatch( {
		type: 'DEVICE_CONNECTIVITY_STATUS',
		onLine: true
	});
};
Offline.on("up", deviceGoneOnLine);

// capture event for connectivity going offline
const deviceGoneOffLine = () => {
	store.dispatch( {
		type: 'DEVICE_CONNECTIVITY_STATUS',
		onLine: false
	});
};
Offline.on("down", deviceGoneOffLine);

// perform initial offline.js connectivity check
Offline.check();
if (Offline.state === "up") {
	deviceGoneOnLine();
}

// create user control
let userControl = new UserControl(store);

// check url to determine if callback from identity server
let urlEnd = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
if (urlEnd.startsWith("callback")) {
	// callback after user has logged in via identity server
	userControl.handleCallback();
} else if (urlEnd.startsWith("renew")) {
	// callback after silent renew
	userControl.handleRenew();
} else {
	// not a callback so load existng user from local storage
	userControl.loadUser();
}

// locate root dom element for this spa
const rootElement = document.getElementById('root');

// render the app
ReactDOM.render(
	(
		<Provider store={store}>
			<Router basename={config.relativePath}>
				<App />
			</Router>
		</Provider>
	),
	rootElement
);

//registerServiceWorker();
//register();