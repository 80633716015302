import { Action, Reducer } from 'redux';
import * as PlayerStore from './player';

export interface IAuthState {
	deviceOnLine: boolean;
	isLoggedIn: boolean;
	user?: IUser;
}

export interface IUser {
	idToken: string;
	accessToken: string;
	accessTokenType: string;
	accessTokenExpiry: number;
	refreshToken?: string;
	emailVerified: boolean;
}

export function fnUserExpired(user: IUser) {
	let expires: Date = new Date(user.accessTokenExpiry * 1000);
	let now: Date = new Date();
	return (expires < now);
}

export interface IUserPermission {
	name: string;
	allowed: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface IDeviceConnectvityStatus { type: 'DEVICE_CONNECTIVITY_STATUS'; onLine: boolean; }
interface IUserLoaded { type: 'USER_LOADED'; user: IUser; }
interface IUserUnloaded { type: 'USER_UNLOADED'; }
interface IUserSignedOut { type: 'USER_SIGNED_OUT'; }
interface IAccessTokenExpiring { type: 'ACCESS_TOKEN_EXPIRING'; }
interface IAccessTokenExpired { type: 'ACCESS_TOKEN_EXPIRED'; }
interface ISilentRenewError { type: 'SILENT_RENEW_ERROR'; error: string; }
interface IRequestPlayerDetails { type: 'REQUEST_PLAYER_DETAILS'; }
interface IPlayerDetailsLoaded { type: 'PLAYER_DETAILS_LOADED'; player: PlayerStore.IPlayerRecord; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IDeviceConnectvityStatus | IUserLoaded | IUserUnloaded | IUserSignedOut
	| IAccessTokenExpiring | IAccessTokenExpired | ISilentRenewError | IRequestPlayerDetails | IPlayerDetailsLoaded;

export const actions = {
	deviceConnectivityChange: (onLine: boolean) => { return { type: 'DEVICE_CONNECTIVITY_STATUS', onLine: onLine }; },
	userLoaded: (user: IUser) => { return { type: 'USER_LOADED', user: user }; },
	userUnloaded: () => { return { type: 'USER_UNLOADED' }; },
	userSignedOut: () => { return { type: 'USER_SIGNED_OUT' }; },
	accessTokenExpiring: () => { return { type: 'ACCESS_TOKEN_EXPIRING' }; },
	accessTokenExpired: () => { return { type: 'ACCESS_TOKEN_EXPIRED' }; },
	silentRenewError: (error: string) => { return { type: 'SILENT_RENEW_ERROR', error: error }; },
	requestPlayerDetails: () => { return { type: 'REQUEST_PLAYER_DETAILS' }; },
	playerDetailsLoaded: (player: PlayerStore.IPlayerRecord) => { return { type: 'PLAYER_DETAILS_LOADED', player: player}; }
};

// inital state
const unloadedState: IAuthState = { deviceOnLine: false, isLoggedIn: false };

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IAuthState> = (state: IAuthState | undefined, incomingAction: Action) => {
	if (!state) {
		state = unloadedState;
	}
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'DEVICE_CONNECTIVITY_STATUS':
			return Object.assign({}, { ...state }, { deviceOnLine: action.onLine });
		case 'USER_LOADED':
			return Object.assign({}, { ...state }, { user: action.user, isLoggingIn: false, isLoggedIn: !fnUserExpired(action.user) });
		case 'USER_UNLOADED':
			return Object.assign({}, { ...state }, { user: null, isLoggingIn: false, isLoggedIn: false });
		case 'USER_SIGNED_OUT':
			return state;
		case 'ACCESS_TOKEN_EXPIRING':
			return state;
		case 'ACCESS_TOKEN_EXPIRED':
			if (state.user && fnUserExpired(state.user)) {
				return Object.assign({}, { ...state }, { isLoggingIn: false, isLoggedIn: false });
			} else {
				return state;
			}
		case 'SILENT_RENEW_ERROR':
			return state;
		case 'REQUEST_PLAYER_DETAILS':
			return Object.assign({}, { ...state }, { playerLoading: true });
		case 'PLAYER_DETAILS_LOADED':
			return Object.assign({}, { ...state }, { playerLoading: false, player: action.player, isActivePlayer: true });
		default:
			return state;
	}
};
