import React from 'react';
import { formatDateString, EDateFormat } from '../../utils/dates';

export interface IActivityMatch {
	id: number;
	when: string;
	venue: string;
	league: string;
	competition: string;
	division: string;
	teamNo: number;
	team: string;
	opposition: string;
}

interface IActivityMatchRowProps {
	match: IActivityMatch;
}

const ActivityMatchRow = (props: IActivityMatchRowProps) => {
	let color: string = "black";
	let iconName = "fa fa-home";
	if (props.match.venue === "A") {
		iconName = "fa fa-plane";
		color = "red";
	}
	let timeStr: string = formatDateString(props.match.when, EDateFormat.tim24H);

	return (
		<div
			style={{
				color: color
			}}
		>
			<span>{timeStr}</span>
			<span
				style={{
					marginLeft: 5,
					marginRight: 5
				}}
			>
				{props.match.team}
			</span>
			<span className={iconName} />				
			<span
				style={{
					marginLeft: 5
				}}
			>
				{props.match.opposition}
			</span>
		</div>
	);
};

export default ActivityMatchRow;
