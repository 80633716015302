import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IApplicationState } from '../../store';
import { IAuthState } from '../../store/auth';
import { actionCreators as matchActionCreators } from '../../store/match';
import { actionCreators as playerActionCreators } from '../../store/player';
import { actionCreators as availabilityActionCreators } from '../../store/availability';
import { actionCreators as selectionActionCreators } from '../../store/selection';
import { actionCreators as resultsActionCreators } from '../../store/result';
import { actionCreators as libraryActionCreators } from '../../store/library';
import { IApiResult, EApiResultType } from '.';
import { AppConfig, IAppConfig } from '../../config/appConfig';
let config: IAppConfig = AppConfig();

interface ICacheProps {
	auth: IAuthState;
	playerId: number;
	clubId: number;
	matches: number;
	players: number;
	availability: number;
	selection: number;
	results: number;
	dispatch: any;
	actions: {
		match: typeof matchActionCreators,
		player: typeof playerActionCreators,
		availability: typeof availabilityActionCreators,
		selection: typeof selectionActionCreators,
		results: typeof resultsActionCreators,
		libraries: typeof libraryActionCreators
	};
}

interface ICacheFetchResults {
	result: IApiResult;
	data: {
		matchDataVersion: number,
		playerDataVersion: number,
		availabilityDataVersion: number,
		selectionDataVersion: number,
		resultsDataVersion: number
	};
}

const Cache = (props: ICacheProps) => {
	const [checking, setChecking] = React.useState(false);
	const [lastCheck, setLastCheck] = React.useState(new Date(2000));
	const [librariesLoaded, setLibrariesLoaded] = React.useState(false);

	const shouldCheckCache = (): boolean => {
		let shouldCheck: boolean = false;
		let now: Date = new Date();
		if (props.auth.deviceOnLine && !checking) {
			if (props.auth.user && props.clubId > 0 && props.playerId > 0) {
				let intervalSeconds: number = ((now.getTime() - new Date(lastCheck).getTime()) / 1000);
				if (intervalSeconds >= 30) {
					shouldCheck = true;
				}
			}
		}
		return shouldCheck;
	};

	const checkCache = () => {
		if (props.auth.user) {
			let accessToken: string = props.auth.user.accessToken;
			let checkNow: Date = new Date();
			setChecking(true);
			setLastCheck(checkNow);
			fetch(`${config.apiUrl}/cache/versions`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + accessToken,
					"Pragma": "no-cache"
				}
			})
			.then(response => response.json() as Promise<ICacheFetchResults>)
			.then(response => {
				onDataVersionsFetched(response, accessToken); 
			});
		}
	};

	const cacheChecked = () => {
		setChecking(false);
	};

	const onDataVersionsFetched = (result: ICacheFetchResults, accessToken: string) => {
		switch (result.result.resultType) {
			case EApiResultType.OK:
				// load libraries one time only (until we've built in data versioning)
				if (!librariesLoaded) {
					props.actions.libraries.requestLibraryData(accessToken);
					setLibrariesLoaded(true);
				}
				// compare match data versions
				if (result.data.matchDataVersion > props.matches) {
					if (props.clubId > 0) {
						props.actions.match.requestMatchData(accessToken, props.clubId);
					}
				}
				// compare player data versions
				if (result.data.playerDataVersion > props.players) {
					if (props.clubId > 0) {
						props.actions.player.requestPlayerData(accessToken, props.clubId);
					}
				}
				// compare availability data versions
				if (result.data.availabilityDataVersion > props.availability) {
					if (props.clubId > 0) {
						props.actions.availability.requestAvailabilityData(accessToken, props.clubId);
					}
				}
				// compare selection data versions
				if (result.data.selectionDataVersion > props.selection) {
					if (props.clubId > 0) {
						props.actions.selection.requestSelectionData(accessToken, props.clubId);
					}
				}
				// compare results data versions
				if (result.data.resultsDataVersion > props.results) {
					if (props.clubId > 0) {
						props.actions.results.requestResultData(accessToken, props.clubId);
					}
				}
				break;
			case EApiResultType.Empty:
				console.log("empty cache result");
				break;
			case EApiResultType.Error:
				console.log(result.result.errorMessage);
				break;
			default:
				break;
		}
		cacheChecked();
	};

	if (shouldCheckCache()) {
		checkCache();
	// } else if (!prevProps.auth.deviceOnLine && props.auth.deviceOnLine) {
	// 	checkCache();
	}

	return null;
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	playerId: state.players.myPlayerId,
	clubId: state.players.myClubId,
	matches: state.matches.data.version,
	players: state.players.data.version,
	availability: state.availability.data.version,
	selection: state.selection.data.version,
	results: state.result.data.version
});

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: dispatch,
		actions: {
			match: bindActionCreators(matchActionCreators, dispatch),
			player: bindActionCreators(playerActionCreators, dispatch),
			availability: bindActionCreators(availabilityActionCreators, dispatch),
			selection: bindActionCreators(selectionActionCreators, dispatch),
			results: bindActionCreators(resultsActionCreators, dispatch),
			libraries: bindActionCreators(libraryActionCreators, dispatch)
		}
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps,
)(Cache) as any;
