import React from 'react';
import { IMatch, ITeam, ICompetition, ILeague } from '../../store/match';
import { formatDateString, EDateFormat } from '../../utils/dates';
import { Button } from 'reactstrap';

export interface IMatchListItem {
	match: IMatch;
	team: ITeam;
	competition: ICompetition;
	league: ILeague;
}

interface IMatchListRowProps {
	item: IMatchListItem;
	onDrillDown: (focusId: number) => void;
}

const MatchListRow = (props: IMatchListRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.item.match.id);
	};

	let dateStr = formatDateString(props.item.match.date, EDateFormat.dayMonthWithTime24H);
	let teamName: string = props.item.team.name;
	if (props.item.league.prefix !== "") {
		teamName = props.item.league.prefix + " " + teamName;
	}
	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{dateStr}</Button></td>
			<td>{teamName}</td>
			<td className="text-center">{props.item.match.venue}</td>
			<td>{props.item.match.opposition}</td>
		</tr>
	);
};

export default MatchListRow;
