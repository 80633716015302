import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { IMatchData, actionCreators as matchActionCreators, ITeam, emptyTeam, ICompetition, emptyCompetition,
	ILeague, emptyLeague } from '../../store/match';
import { IPlayer, emptyPlayer } from '../../store/player';
import { ISelection } from '../../store/selection';
import { IResult } from '../../store/result';
import { IAuthState } from '../../store/auth';
import { bindActionCreators } from "redux";
import TeamEdit from '../team/fnTeamEdit';
import WrapperWidget from '../widgets/fnWrapperWidget';
import { IWrapperWidgetBadge } from '../widgets/fnWrapperWidgetBadge';
import RecordBrowserSimple from '../controls/navigation/fnRecordBrowserSimple';
import Captain from '../player/fnCaptain';
import { ITeamPlayer } from './fnTemPlayerRow';
import { ITeamMatch } from './fnTeamMatchRow';
import TeamPlayerTable from './fnTeamPlayerTable';
import TeamMatchTable from './fnTeamMatchTable';
import { IMatch } from '../../store/match';
import { Alert } from 'reactstrap';

interface ITeamDetailsProps {
	live: boolean;
	auth: IAuthState;
	isTeamCaptain: boolean;
	matches: IMatchData;
	selection: ISelection[];
	players: IPlayer[];
	captainIds: number[];
	results: IResult[];
	actions: typeof matchActionCreators;
}

type TTeamDetailsProps = ITeamDetailsProps & RouteComponentProps<{ teamId: any }>; // ... plus incoming routing parameters

const TeamDetails = (props: TTeamDetailsProps) => {
	// initialise internal state
	let teamIdParam: number = isNaN(Number(props.match.params.teamId)) ? 0 : Number(props.match.params.teamId);
	let team: ITeam = emptyTeam;
	let captain: IPlayer = emptyPlayer;
	let competition: ICompetition = emptyCompetition;
	let league: ILeague = emptyLeague;
	let matches: ITeamMatch[] = [];
	let players: ITeamPlayer[] = [];
	let incomingState: any = props.location.state;
	let canBrowse: boolean = typeof incomingState !== "undefined" && typeof incomingState.ids !== "undefined";

	let teamIndex = props.matches.teams.findIndex((tm: ITeam) => { return tm.id === teamIdParam; });
	if (teamIndex >= 0) {
		team = props.matches.teams[teamIndex];
		let competitionIndex = props.matches.competitions.findIndex((cp: ICompetition) => { return cp.id === team.competitionId; });
		if (competitionIndex >= 0) {
			competition = props.matches.competitions[competitionIndex];
			let leagueIndex = props.matches.leagues.findIndex((lg: ILeague) => { return lg.id === competition.leagueId; });
			if (leagueIndex >= 0) {
				league = props.matches.leagues[leagueIndex];
			}
		}
		if (team.captainId > 0) {
			let captains: IPlayer[] = props.players.filter((pl) => { return pl.id === team.captainId; });
			if (captains.length === 1) {
				captain = captains[0];
			}
		}
	}

	props.matches.matches.filter((match: IMatch) => {
		return match.teamId === team.id && match.completed;
	}).forEach((match: IMatch) => {
		props.selection.filter((selection) => {
			return selection.matchId === match.id;
		}).forEach((selection) => {
			let rubbersPlayed: number = 0;
			let rubbersFor: number = 0;
			props.results.filter((result) => { return result.matchId === match.id && result.playerId === selection.playerId; }).forEach((result) => {
				rubbersFor = rubbersFor + result.rubbersFor;
				rubbersPlayed = rubbersPlayed + result.rubbersFor + result.rubbersAgainst;
			});
			let playerIndex = players.findIndex((player) => { return player.id === selection.playerId; });
			if (playerIndex >= 0) {
				players[playerIndex].matches += 1;
				players[playerIndex].rubbersFor += rubbersFor;
				players[playerIndex].rubbersPlayed += rubbersPlayed;
			} else {
				let player = props.players.find((pl) => { return pl.id === selection.playerId; });
				if (player) {
					players.push({ id: player.id, name: player.firstName + " " + player.surname, matches: 1, rubbersFor: rubbersFor, rubbersPlayed: rubbersPlayed });
				}
			}
		});
		});
	players = players.sort((a: ITeamPlayer, b: ITeamPlayer) => {
		if (a.matches > b.matches) {
			return -1;
		} else if (a.matches < b.matches) {
			return 1;
		} else if (a.rubbersFor > b.rubbersFor) {
			return -1;
		} else if (a.rubbersFor < b.rubbersFor) {
			return 1;
		} else {
			return 0;
		}
	});

	props.matches.matches.filter((match: IMatch) => {
		return match.teamId === team.id;
	}).forEach((match: IMatch) => {
		matches.push({ id: match.id, date: match.date, opposition: match.opposition, venue: match.venue, result: match.result, points: match.pointsFor });
		});
	matches = matches.sort((a: ITeamMatch, b: ITeamMatch) => {
		if (a.date < b.date) {
			return -1;
		} else if (a.date > b.date) {
			return 1;
		} else {
			return 0;
		}
	});

	const onRecordChange = (id: number) => {
		// replace history entry - will trigger a change of props and refresh the page for the new match id
		props.history.replace("/team/details/" + id.toString(), props.location.state);
	};

	const onPlayerDrillDown = (focusId: number) => {
		let playerIds: number[] = players.map((pl) => { return pl.id; });
		props.history.push("/player/details/" + focusId.toString(), { "ids": playerIds });
	};

	const onMatchDrillDown = (focusId: number) => {
		let matchIds: number[] = matches.map((ma) => { return ma.id; });
		props.history.push("/match/details/" + focusId.toString(), { "ids": matchIds });
	};

	const onTeamSaved = (tm: ITeam) => {
		props.actions.teamSet(tm);
	};

	let badges: IWrapperWidgetBadge[] = [
		{ name: "", value: players.length.toString() }
	];

	let division;
	if (league.websiteRef !== "" && team.divisionWebsiteRef !== 0) {
		let divisionUrl = "https://lta.tournamentsoftware.com/sport/draw.aspx?id=" + league.websiteRef + "&draw=" + team.divisionWebsiteRef;
		division = <a href={divisionUrl} target="_blank" rel="noopener noreferrer">{team.division}</a>;
	} else {
		division = team.division;
	}

	let browser;
	if (canBrowse) {
		browser = <RecordBrowserSimple id={team.id} ids={incomingState.ids} onRecordChange={onRecordChange} />;
	}

	let teamEditButton;
	if (props.isTeamCaptain) {
		let captains: IPlayer[] = props.players.filter(pl => { return props.captainIds.indexOf(pl.id) >= 0; });
		teamEditButton = (
			<TeamEdit
				title="Edit Team"
				teamId={teamIdParam}
				auth={props.auth}
				onTeamSaved={onTeamSaved}
				captains={captains}
				online={props.auth.deviceOnLine}
			/>
		);
	}

	let teamNotes;
	if (captain.surname !== "") {
		teamNotes = (
			<Alert color="warning">
				<Captain captain={captain} marginTop={0} marginBottom={5} />
			</Alert>
		);
	}

	return (
		<div>
			<div className="container body-content">
				<div className="mb-3">
					{browser}
					<div className="text-center">
						<h4>{team.name}</h4>
						<h5>
							{competition.name}<br />
							{division}<br />
						</h5>
						{teamEditButton}
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6">
						<TeamMatchTable matches={matches} onDrillDown={onMatchDrillDown} />
						{teamNotes}
					</div>
					<div className="col-sm-6">
						<WrapperWidget title={"Players Used"} controlName={"WgtPlayersUsed"} badges={badges} panelClass={"primary"} glyph="fa fa-user" >
							<TeamPlayerTable players={players} onDrillDown={onPlayerDrillDown} />
						</WrapperWidget>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	auth: state.auth,
	isTeamCaptain: state.players.isTeamCaptain,
	matches: state.matches.data,
	selection: state.selection.data.selection,
	results: state.result.data.results,
	players: state.players.data.players,
	captainIds: state.players.data.roles.filter((pr) => { return pr.roleId === 20; }).map((cpr) => { return cpr.playerId; })
});

function mapDispatchToProps(dispatch: any) {
	return {
		actions: bindActionCreators(matchActionCreators, dispatch)
	};
}

export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	mapDispatchToProps
)(TeamDetails) as any;