import React from 'react';

export interface IMatchRubberResult {
	rubber: number;
	singles: boolean;
	home1: string;
	home2: string;
	away1: string;
	away2: string;
	score: string;
}

interface IMatchResultRowProps {
	result: IMatchRubberResult;
}

const MatchResultRow = (props: IMatchResultRowProps) => {
	if (props.result.singles) {
		return (
			<tr>
				<td className="text-center">{props.result.rubber}</td>
				<td>{props.result.home1}</td>
				<td>{props.result.away1}</td>
				<td>{props.result.score}</td>
			</tr>
		);
	} else {
		return (
			<tr>
				<td className="text-center">{props.result.rubber}</td>
				<td>{props.result.home1}<br />{props.result.home2}</td>
				<td>{props.result.away1}<br />{props.result.away2}</td>
				<td>{props.result.score}</td>
			</tr>
		);
	}
};

export default MatchResultRow;
