import React from 'react';
import { useState, useEffect } from "react";

interface IDelayProps {
	delayInMs: number;
	children?: any;
}

const Delay = (props: IDelayProps) => {
	const [showing, setShowing] = useState(props.delayInMs === 0);
	let mounted = React.useRef(true);

	const onTimerExpired = () => {
		if (mounted.current) {
			setShowing(true);
		}
	};

	useEffect(
		() => {
			let timer: NodeJS.Timeout;

			const startTimer = async () => {
				if (timer) {
					clearTimeout(timer);
				}
				if (props.delayInMs > 0) {
					timer = setTimeout(onTimerExpired, props.delayInMs);
				}
			};
			
			startTimer();

			// cleanup function
			return () => {
				mounted.current = false;
				if (timer) {
					clearTimeout(timer);
				}
			};
		},
		[props.delayInMs],
	);

	if (showing) {
		return props.children;
	} else {
		return false;
	}
};

export default Delay;
