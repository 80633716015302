import React from 'react';
import TeamPlayerRow, { ITeamPlayer } from './fnTemPlayerRow';

interface ITeamPlayerTableProps {
	players: ITeamPlayer[];
	onDrillDown: (focusId: number) => void;
}

const TeamPlayerTable = (props: ITeamPlayerTableProps) => {
	var rows: any[] = [];
	props.players.forEach((player) => {
		rows.push(<TeamPlayerRow player={player} key={player.id} onDrillDown={props.onDrillDown} />);
	});
	
	return (
		<table className="table table-striped">
			<thead>
				<tr>
					<th>Player</th>
					<th className="text-center">Matches</th>
					<th className="text-center">Rubbers</th>
				</tr>
			</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	);
};

export default TeamPlayerTable;
