import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as AvailabilityStore from '../../store/availability';
import * as SelectionStore from '../../store/selection';
import * as MatchStore from '../../store/match';
import CalendarDate from '../controls/dates/fnCalendarDate';
import RequestStatuses from '../activity/fnRequestStatuses';
import ActivityMatches from '../activity/fnActivityMatches';
import { EDateFormat, formatDateString } from '../../utils/dates';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { IPlayer } from '../../store/player';

export interface IScheduleItem {
	key: string;
	date: Date;
	activity?: MatchStore.IActivity;
	fm?: MatchStore.IFilteredMatch;
	availability?: AvailabilityStore.IAvailability;
	selection?: SelectionStore.ISelection;
	readOnly?: boolean;
	availabilitySummary?: AvailabilityStore.IAvailabilitySummary;
	sex: string;
}

interface IScheduleRowProps {
	item: IScheduleItem;
	playerId: number;
	player: IPlayer;
	callback: (av: AvailabilityStore.IAvailability) => void;
	callbackMatchDetails: (matchId: number) => void;
}

type TScheduleRowProps = IScheduleRowProps & RouteComponentProps<{ history?: any }>; // ... plus incoming routing parameters

const ScheduleRow = (props: TScheduleRowProps) => {
	const [matchesPopupShowing, setMatchesPopupShowing] = React.useState(false);
	const [availability, setAvailability] = React.useState(AvailabilityStore.EAvailabilityStatus.Pending);
	React.useEffect(
		() => {
			let calcStatus: AvailabilityStore.EAvailabilityStatus = AvailabilityStore.EAvailabilityStatus.Pending;
			if (props.item.availability) {
				calcStatus = props.item.availability.status;
			} else if (props.item.selection) {
				calcStatus = AvailabilityStore.EAvailabilityStatus.Available;
			}
			if (calcStatus !== availability) {
				setAvailability(calcStatus);
			}
		},
		[availability, props.item.availability, props.item.selection]
	);

	const onAvailabilityChange = (e: any) => {
		if (props.item.activity) {
			let selectedStatus: AvailabilityStore.EAvailabilityStatus = parseInt(e.currentTarget.value, 10);
			let reqAvailability: AvailabilityStore.IAvailability = {
				activityId: props.item.activity.id,
				playerId: props.playerId,
				status: selectedStatus,
				notes: ""
			};
			props.callback(reqAvailability);
			setAvailability(selectedStatus);
		}
	};

	const showMatchesPopup = () => {
		if (props.item.fm && props.item.selection && props.item.selection.locked) {
			props.callbackMatchDetails(props.item.fm.match.id);
		} else {
			setMatchesPopupShowing(true);
		}
	};

	const matchesPopupClosed = () => {
		setMatchesPopupShowing(false);
	};

	let detail = "";
	let titleColour: string = "dimgray";
	let calendarColour: string = "gold";
	let acceptedStatusColour: string = "green";
	let activityTitle;
	let detailsLinkTitle: string = "Matches";
	let activityId: number = 0;
	let isMatch: boolean = true;
	if (props.item.activity) {
		activityId = props.item.activity.id;
		activityTitle = props.item.activity.title;
		isMatch = props.item.activity.type === "Match";
		if (!isMatch) {
			activityTitle = activityTitle + " " + formatDateString(props.item.activity.date, EDateFormat.tim24H);
		}
	}
	let matchId: number = 0;
	if (props.item.fm) {
		activityId = props.item.fm.match.activityId;
		activityTitle = props.item.fm.activityTitle;
		if (!activityTitle) {
			activityTitle = props.item.fm.competition.name;
		}
		if (props.item.selection && props.item.selection.locked) {
			matchId = props.item.fm.match.id;
			detailsLinkTitle = "Details";
			activityTitle = props.item.fm.team.name;
			if (props.item.fm.match.venue === "A") {
				activityTitle = activityTitle + " AWAY to ";
			} else {
				activityTitle = activityTitle + " HOME vs ";
			}
			activityTitle = activityTitle + props.item.fm.match.opposition;
		}
	}
	let isLocked: boolean = (props.item.selection !== undefined) && props.item.selection.locked;
	let isProvisional: boolean = (props.item.selection !== undefined) && !props.item.selection.locked && props.item.selection.index > 0;
	let confirmationRequired: boolean = (props.item.selection !== undefined && props.item.selection.locked && !props.item.selection.confirmed);
	if (props.item.availability && props.item.availability.status === AvailabilityStore.EAvailabilityStatus.Unavailable) {
		titleColour = "dimgray";
		calendarColour = "dimgray";
		if (isMatch) {
			detail = "You declared yourself unavailble to play on this date. "
			+ "If your circumstances change, please update your status accordingly.";
		}
	} else if (props.item.availability && props.item.availability.status === AvailabilityStore.EAvailabilityStatus.Pending) {
		titleColour = "red";
		calendarColour = "red";
		if (isMatch) {
			detail = "You have not yet declared your availability for this date. "
			+ "Please use the drop down to set your status.";
		}	
	} else if (isLocked) {
		titleColour = "green";
		calendarColour = "green";
		if (isMatch) {
			if (confirmationRequired) {
				detail = "You have been selected to play in this match. Go to the match details page to confirm your selection. "
				+ "If you can no longer make this match please phone your captain ASAP.";
			} else {
				detail = "You have been selected to play in this match. Go to the match details page to see any notes from your captain. "
				+ "If you can no longer make this match please phone your captain ASAP.";
			}
		}
	} else if (props.item.availability && props.item.availability.status === AvailabilityStore.EAvailabilityStatus.Maybe) {
		titleColour = "purple";
		calendarColour = "purple";
		if (isMatch) {
			detail = "You have indicated that you might be available but have not yet committed. "
			+ "If your circumstances change, please update your status accordingly.";
		}
	} else if (isProvisional) {
		titleColour = "green";
		calendarColour = "green";
		if (isMatch) {
			detail = "You have been selected to play on this match day but the individual team positions are not yet finalised. "
			+ "If you can no longer make this match day please phone your "
			+ "captain ASAP.";
		}
	} else {
		titleColour = "green";
		calendarColour = "green";
		if (isMatch) {
			detail = "Thank you! You have made yourself availble to play, please keep this day clear in case you're selected. "
			+ "You can change your availability any time until you are selected. If you need to pull out after selection "
			+ "you will need to phone the team captain.";
		} else {
			titleColour = "green";
			calendarColour = "green";
		}
	}

	// override colours and detail for non match events
	if (!isMatch) {
		if (props.item.activity) {
			detail = props.item.activity.notes;
		}
	}

	let availableText: string = "I'm available";
	let unavailbleText: string = "I'm not available";
	let maybeText: string = "I might be available";
	if (!isMatch) {
		availableText = "I'll be there";
		unavailbleText = "I won't be there";
		maybeText = "I might be there";
	}
	//let allowMaybe: boolean = (props.player.sex === "F") || props.item.sex === "X";
	// only allow captains to set maybe from availabity page
	let allowMaybe = props.item.availability && props.item.availability.status === AvailabilityStore.EAvailabilityStatus.Maybe;
	let availabilityDropDown;
	if (!isLocked && !isProvisional) {
		let options: any[] = [];
		options.push(<option value={AvailabilityStore.EAvailabilityStatus.Available.toString()} key={1}>{availableText}</option>);
		options.push(<option value={AvailabilityStore.EAvailabilityStatus.Unavailable.toString()} key={2}>{unavailbleText}</option>);
		//if (props.player.sex === "F") {
		if (allowMaybe) {
			options.push(<option value={AvailabilityStore.EAvailabilityStatus.Maybe.toString()} key={3}>{maybeText}</option>);
		}
		options.push(<option value={AvailabilityStore.EAvailabilityStatus.Pending.toString()} key={4}>*** Set your status ***</option>);
		availabilityDropDown = (
			<FormGroup className="mb-0">
				<Label for="avdd">Status:</Label>
				<CustomInput
					id="avdd"
					type="select"
					value={availability}
					onChange={onAvailabilityChange}
					disabled={props.item.readOnly || props.item.selection !== undefined}
					style={{
						maxWidth: 210,
						fontSize: 16,
						color: titleColour,
						marginBottom: 5,
						marginLeft: 10
					}}
				>
					{options}
				</CustomInput>
			</FormGroup>
		);
	} else {
		availabilityDropDown = (
			<div
				style={{
					fontSize: 16,
					color: "green"
				}}
			>
				{isLocked ? "You're in the Team!" : "You're in the Squad!"}
			</div>
		);
	}

	let requestStatuses;
	if (props.item.availabilitySummary) {
		requestStatuses = (
			<RequestStatuses
				requestsAccepted={props.item.availabilitySummary.accepted}
				requestsRejected={props.item.availabilitySummary.rejected}
				requestsMaybe={props.item.availabilitySummary.maybe}
				requestsPending={props.item.availabilitySummary.pending}
				activityId={activityId}
				matchId={0}
				positiveColour={acceptedStatusColour}
			/>
		);
	}

	let matchesPopup;
	if (isMatch) {
		matchesPopup = (
			<div
				style={{
					float: "right",
					display: "inline"
				}}
			>	
				<span
					onClick={() => showMatchesPopup()}
					style={{
						cursor: "pointer",
						fontSize: 16
					}}
				>
					<span style={{color: "red", fontWeight: "bold"}} hidden={!confirmationRequired}>{"Confirm > "}</span>
					<i className="fa fa-calendar" /> {detailsLinkTitle}
				</span>
			</div>
		); 
	}

	return (
		<div
			style={{
				flex: 1,
				marginTop: 10,
				marginLeft: 10,
				marginRight: 10,
				borderWidth: 0,
				borderBottomWidth: 1,
				borderBottomColor: 'gray',
				borderStyle: "solid",
				paddingBottom: 5,
			}}
		>
			<div
				style={{
					float: "left",
					marginRight: 10
				}}
			>
				<CalendarDate date={props.item.date} backgroundColor={calendarColour} dayFontColor="dimgray" includeDayName={true} />
			</div>
			<div
				style={{
					marginLeft: 0
				}}
			>
				{availabilityDropDown}
				<div style={{fontWeight: "bold"}}>
					{activityTitle}
				</div>
				{detail}
			</div>
			<div
				style={{
					clear: "both",
					marginTop: 10
				}}
			>
				{matchesPopup}
				{requestStatuses}
				<div
					style={{
						clear: "both"
					}}
				/>
			</div>
			<ActivityMatches
				activityId={activityId}
				matchId={matchId}
				showing={matchesPopupShowing}
				onClose={() => matchesPopupClosed()}
			/>
		</div>
	);
};

export default connect(
)(ScheduleRow) as any;