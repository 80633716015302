import React from 'react';
import { parseDateStringToParts, padNumeric } from '../../utils/dates';
import { Button } from 'reactstrap';

export interface IMatchWidgetMatch {
	id: number;
	date: string;
	team: string;
	isMixed: boolean;
	venue: string;
	opposition: string;
	rubbersFor?: number;
	rubbersAgainst?: number;
}

interface IMatchRowProps {
	match: IMatchWidgetMatch;
	hideRubbers?: boolean;
	onDrillDown: (focusId: number) => void;
}

const MatchRow = (props: IMatchRowProps) => {

	const onLinkClick = (e: any) => {
		e.preventDefault();
		props.onDrillDown(props.match.id);
	};

	let dateParts = parseDateStringToParts(props.match.date);
	let dateStr = padNumeric(dateParts.day, 2) + "/" + padNumeric(dateParts.month, 2) + " " + padNumeric(dateParts.hour, 2) + ":" + padNumeric(dateParts.minute, 2);
	let result: string = "-";
	let rubbersPlayed: number = 0;

	if (props.match.rubbersFor) {
		rubbersPlayed = rubbersPlayed + props.match.rubbersFor;
	}
	if (props.match.rubbersAgainst) {
		rubbersPlayed = rubbersPlayed + props.match.rubbersAgainst;
	}
	if (rubbersPlayed) {
		result = props.match.rubbersFor + " / " + rubbersPlayed;
	}
	let rubbersRow;
	if (!props.hideRubbers) {
		rubbersRow = <td className="text-center">{result}</td>;
	}
	let iconName: string = "fa fa-home";
	if (props.match.venue === "A") {
		iconName = "fa fa-plane";
	}
	return (
		<tr>
			{/* <td><a href="#" onClick={onLinkClick}>{dateStr}</a></td> */}
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{dateStr}</Button></td>
			<td><span className={iconName} /></td>
			<td>{props.match.team}</td>
			<td>{props.match.opposition}</td>
			{rubbersRow}
		</tr>
	);
};

export default MatchRow;
