export interface IAppConfig {
	apiUrl: string;
	identityServerUrl: string;
	redirectUrl: string;
	silentRedirectUrl: string;
	logoutRedirectUrl: string;
	relativePath: string;
	manageAccountUrl: string;
	clientId: string;
	offlineImageOverride: string;
	version: string;
}

export function AppConfig(): IAppConfig {
	let ac: IAppConfig;
	if (process.env.NODE_ENV === 'production') {
		ac = require('./appConfig.prod.json');
	} else {
		ac = require('./appConfig.dev.json');
	}
	return ac;
}