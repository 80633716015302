import * as React from 'react';
import { IPlayer } from '../../store/player';

interface ICaptainProps {
	captain: IPlayer;
	marginTop: number;
	marginBottom: number;
}

const Captain = (props: ICaptainProps) => {
	let call;
	let text;
	let email;
	if (props.captain.mobile !== "") {
		call = <a href={"tel:" + props.captain.mobile} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-phone" style={{ fontSize: "30px", color: "navy" }} /></a>;
		text = <a href={"sms:" + props.captain.mobile} style={{ marginLeft: 10, marginRight: 10 }}><i className="fa fa-comment" style={{ fontSize: "30px", color: "green" }} /></a>;
	}
	if (props.captain.email !== "") {
		email = <a href={"mailto:" + props.captain.email} style={{ marginLeft: 15, marginRight: 15 }}><i className="fa fa-envelope" style={{ fontSize: "30px", color: "gold" }} /></a>;
	}
	let name;
	if (props.captain.surname !== "") {
		name = <div style={{ verticalAlign: "center", fontSize: 22 }}>{props.captain.firstName + " " + props.captain.surname + " (c)"}</div>;
	}
	if (name) {
		return (
			<div style={{ marginTop: props.marginTop, marginBottom: props.marginBottom }}>
				{name}
				{call}
				{text}
				{email}
			</div>
		);
	} else {
		return null;
	}
};

export default Captain;
