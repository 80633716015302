import React, { FC, useEffect } from 'react';
import { Alert, Button } from 'reactstrap';
import { register } from './serviceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  if (showReload) {
    return (
      <React.Fragment>
        <Alert color="primary" className="mx-2">
          A new version is available
          <Button onClick={reloadPage} color="primary" className="ml-3">Reload</Button>
        </Alert>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default ServiceWorkerWrapper;