import React from 'react';
import { Button } from 'reactstrap';

export interface IPlayerStatsResults {
	id: number;
	firstName: string;
	surname: string;
	rubbersFor: number;
	rubbersPlayed: number;
}

interface IPlayerStatsRowProps {
	results: IPlayerStatsResults;
	onDrillDown: (focusId: number) => void;
}

const PlayerStatsRow = (props: IPlayerStatsRowProps) => {
	const onLinkClick = (e: any) => {
		e.preventDefault();
		let playerId: number = props.results.id;
		props.onDrillDown(playerId);
	};

	return (
		<tr>
			<td><Button color="link" className="p-0" onClick={onLinkClick}>{props.results.firstName + " " + props.results.surname}</Button></td>
			<td className="text-center">{props.results.rubbersFor.toString() + " / " + props.results.rubbersPlayed.toString()}</td>
		</tr>
	);
};

export default PlayerStatsRow;
